export default {
  "activity.category.DEIdiversityInclusionAccessibility": "IDEA",
  "activity.category.DEIdiversityInclusionAccessibility.subtitle": "Inclusion, diversité, équité et accessibilité",
  "activity.category.accounting": "Comptabilité",
  "activity.category.accounting.subtitle": "Finances, vérification et gestion du risque",
  "activity.category.advancedManufacturing": "Production avancée",
  "activity.category.agricultureTechnology": "Technologie agricole",
  "activity.category.appliedLearning": "Apprentissage appliqué",
  "activity.category.appliedLearning.subtitle": "Techniciens en pharmacie, opticiens, etc.",
  "activity.category.biomanufacturing": "Bioproduction",
  "activity.category.businessOperations": "Activités commerciales",
  "activity.category.businessOperations.subtitle": "Amélioration de processus, Agile et Scrum",
  "activity.category.businessStrategyInnovationAdministration": "Affaires",
  "activity.category.businessStrategyInnovationAdministration.subtitle": "Stratégie, innovation et administration des affaires",
  "activity.category.cleanTechnology": "Technologie verte",
  "activity.category.cloudComputingProgramming": "Infonuagique",
  "activity.category.cloudComputingProgramming.subtitle": "Essentiels, opérations et architecture infonuagiques",
  "activity.category.communicationConfidenceInfluenceResolution": "Communication", // ignore-untranslated
  "activity.category.communicationConfidenceInfluenceResolution.subtitle": "Confiance professionnelle, influence et résolution de conflits",
  "activity.category.constructionManagement": "Gestion de la construction",
  "activity.category.constructionManagement.subtitle": "Contrats de construction, estimation et stratégie",
  "activity.category.cybersecurity": "Cybersécurité",
  "activity.category.dataAnalytics": "Analyses des données",
  "activity.category.dataAnalytics.subtitle": "PowerBI, Excel, analyse de données et visualisation",
  "activity.category.dataScience": "Science des données",
  "activity.category.dataScience.subtitle": "Modélisation des données, ingénierie des données et programmation des données",
  "activity.category.design": "Conception",
  "activity.category.design.subtitle": "Conception créative, graphique et conception UX/UI",
  "activity.category.digitalSkills": "Compétences numériques",
  "activity.category.digitalSkills.subtitle": "Microsoft Office, PowerPoint et MS365",
  "activity.category.digitalTechnology": "Technologie numérique",
  "activity.category.emergentTechnologies": "Technologies émergentes",
  "activity.category.emergentTechnologies.subtitle": "Chaîne de blocs, apprentissage automatique, IA et métavers",
  "activity.category.engineeringContinuingEducation": "Ingénierie",
  "activity.category.engineeringContinuingEducation.subtitle": "Formation continue pour les ingénieurs professionnels",
  "activity.category.growthAccelerators": "Accélérateurs de croissance professionnelle",
  "activity.category.growthAccelerators.subtitle": "ALS/FLS, Communications écrites et état de préparation pour l’entrée au collège",
  "activity.category.inProgress": "En cours",
  "activity.category.integratedTechnology": "Informatique",
  "activity.category.integratedTechnology.subtitle": "Cybersécurité, sécurité de l’information et confidentialité des renseignements",
  "activity.category.leadership": "Leadership", // ignore-untranslated
  "activity.category.leadership.subtitle": "Essentiels du leader, encadrement et intelligence émotionnelle",
  "activity.category.lifestyle": "Mode de vie",
  "activity.category.lifestyle.subtitle": "Nutrition, enrichissement personnel et loisirs",
  "activity.category.management101": "Gestion 101",
  "activity.category.management101.subtitle": "Perfectionnement d’équipe, gestion du rendement et gestion du changement",
  "activity.category.marketing": "Marketing", // ignore-untranslated
  "activity.category.marketing.subtitle": "Marketing numérique, service à la clientèle et gestion des produits",
  "activity.category.myList": "Ma liste",
  "activity.category.peopleCulture": "Personnes et culture",
  "activity.category.peopleCulture.subtitle": "Acquisition de talents, rémunération et relations avec les employés",
  "activity.category.preConstruction": "Préconstruction",
  "activity.category.preConstruction.subtitle": "B.I.M, habitat passif et conception environnementale",
  "activity.category.projectManagement": "Gestion de projet",
  "activity.category.projectManagement.subtitle": "Préparation aux examens, formation continue et outils de gestion de projet",
  "activity.category.safetyFirst": "Sécurité avant tout",
  "activity.category.safetyFirst.subtitle": "Santé et sécurité au travail, SIMDUT et sécurité en milieu de travail",
  "activity.category.softwareDevelopment": "Développement de logiciels",
  "activity.category.softwareDevelopment.subtitle": "Bases de données, développement Web et général",
  "activity.category.supplyChainManagement": "Gestion de la chaîne logistique",
  "activity.category.supplyChainManagement.subtitle": "Logistique, commerce mondial et pratiques Lean Six Sigma",
  "activity.category.sustainability": "Durabilité",
  "activity.category.sustainability.subtitle": "Gestion durable, pratiques et responsabilité sociale d’entreprise",
  "activity.category.wellness": "Bien-être",
  "activity.category.wellness.subtitle": "Bien-être numérique, bien-être émotionnel et conciliation travail-vie personnelle",
  "activity.category.writtenCommunication": "Communication écrite",
  "activity.category.writtenCommunication.subtitle": "Communication d’entreprise, rédaction technique et apprentissage de langues",
  "activity.certificateType.certificate": "Certificat",
  "activity.certificateType.course": "Cours",
  "activity.certificateType.degree": "Diplôme de premier cycle",
  "activity.certificateType.diploma": "Diplôme",
  "activity.certificateType.microcredential": "Microcertification",
  "activity.certificateType.online": "En ligne",
  "activity.contentFormats.readings": "Lectures",
  "activity.contentFormats.slides": "Diapositives",
  "activity.contentFormats.videos": "Vidéos",
  "activity.delivery.blended": "Mixte",
  "activity.delivery.inPerson": "En personne",
  "activity.delivery.inPersonLocationRestricted": "En personne (rayon de proximité)",
  "activity.delivery.online": "En ligne",
  "activity.duration.lessThanThreeMonths": "< 3 mois",
  "activity.duration.oneToTwoYears": "1 à 2 années",
  "activity.duration.sixToTwelveMonths": "6 à 12 mois",
  "activity.duration.threeToSixMonths": "3 à 6 mois",
  "activity.duration.twoPlusYears": "Plus de deux années",
  "activity.feedbackTypes.assignments": "Travaux",
  "activity.feedbackTypes.discussions": "Discussions", // ignore-untranslated
  "activity.feedbackTypes.instructorReviews": "Évaluation par le formateur",
  "activity.feedbackTypes.peerReviews": "Évaluation par les pairs",
  "activity.feedbackTypes.quizzes": "Questionnaires",
  "activity.format.instructorLed": "Donné par un enseignant",
  "activity.format.selfPaced": "À rythme libre",
  "activity.instructionLang.en": "Anglais",
  "activity.instructionLang.es": "Espagnol",
  "activity.instructionLang.fr": "Français (Canada)",
  "activity.project.false": "Non",
  "activity.project.true": "Oui",
  "activity.skills.ariaLabel.exactlyOne": "Compétence : {skillName}", // List of skills read by screen readers when there is exactly one skills associated with an activity
  "activity.skills.ariaLabel.exactlyTwo": "Compétences : {skillName} et 1 compétence de plus", // List of skills read by screen readers when there are exactly two skills associated with an activity
  "activity.skills.ariaLabel.threePlus": "Compétences : {skillName} et {numMoreSkills} compétences de plus", // List of skills read by screen readers when there are three or more skilsl associated with an activity
  "activity.startDate.anytime": "En tout temps",
  "activity.startDate.comingSoon": "Disponible bientôt",
  "activity.startDate.notApplicable": "S.O.",
  "activity.startDate.notScheduled": "Non planifié(e)",
  "activity.startDateStatus.startsNextMonth": "Commence le mois prochain", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsNextWeek": "Commence la semaine prochaine", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisMonth": "Commence ce mois-ci", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisWeek": "Commence cette semaine", // Used to indicate when the next session of an activity starts
  "activity.status.newArrival": "Nouvelle arrivée", // Used to indicate that an activity is a new arrival
  "activity.status.trending": "Tendance", // Used to indicate that an activity is trending
  "activity.type.course": "Cours",
  "activity.type.program": "Programme",
  "activity.type.program.completionCount": "cours terminés", // Text for describing number of courses completed (beside a fraction) ex: 5/8 courses completed
  "activity.weeklyTimeCommitment.fiveToTen": "5 à 10 h/semaine",
  "activity.weeklyTimeCommitment.lessThanFive": "< 5 h/semaine",
  "activity.weeklyTimeCommitment.tenToTwenty": "10 à 20 h/semaine",
  "activity.weeklyTimeCommitment.twentyPlus": "+20 h/semaine",

  "activity-features.certificateType": "Type de certificat", // Alt-text describing the value for the program/course type of certificate earned
  "activity-features.coursesRequired": "Cours requis", // Alt-text describing the value for the program/course number of required courses
  "activity-features.delivery": "Livraison", // Alt-text describing the value for the program/course delivery type (i.e online or in-person)
  "activity-features.duration": "Durée", // Alt-text describing the value for the program/course duration
  "activity-features.format": "Format", // Alt-text describing the value for the program/course format, ignore-untranslated
  "activity-features.numberOfCourses": "{numberOfCourses} cours", // The number of courses reqruied for this program
  "activity-features.timeCommitment": "Engagement en temps", // Alt-text describing the value for the program/course time commitment

  "activity-fees.activityCost": "Prix {type}", // label for the cost of the program/course
  "activity-fees.approvedAmount": "Montant approuvé", // The label for the amount that has been approved for this program/course
  "activity-fees.balanceBeforeApproval": "Solde avant approbation", // The label for the benefit balance before approval
  "activity-fees.balanceWithApproval": "Solde avec approbation", // label for the balance of your benefit with the approval
  "activity-fees.billedDirectly": "Si la demande est approuvée, les frais seront facturés directement à {companyName}. Vous ne paierez que pour tout montant supérieur à votre couverture.", // Supplementary note to explain when a user has to pay in a direct billing scenario
  "activity-fees.caseByCaseReimbursementMessage": "Si vous avez déjà porté le coût au compte de votre entreprise, veuillez contacter {programSponsor} pour les prochaines étapes.", // The reimbursement message shown when a company has case-by-case budget setting enabled
  "activity-fees.coursePrices": "Prix du cours", // Related number field displays price range of courses associated with program being displayed (pluralization option retained if possible)
  "activity-fees.coverage.employee": "Couverture de l’employeur", // Label for line item showing how much financial coverage the employer will provide
  "activity-fees.coverage.jobseeker": "Couverture accessible", // Label for line item showing how much financial coverage the organization will provide
  "activity-fees.coverage.member": "Couverture accessible", // Label for line item showing how much financial coverage the association will provide
  "activity-fees.coverage.tooltip.description.employee": "Cet établissement facture en {providerCurrency}. La couverture offerte par votre employeur sera appliquée au moment du paiement.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.jobseeker": "Cet établissement facture en {providerCurrency}. La couverture offerte par votre organisation sera appliquée au moment du paiement.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.member": "Cet établissement facture en {providerCurrency}. La couverture offerte par votre association sera appliquée au moment du paiement.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.target": "Converti vers {coverage}", // Title for currency conversion tooltip in activity-fees
  "activity-fees.discountCodeAmount": "Montant du code de réduction", // The label for the amount that the discount code is good for.
  "activity-fees.employee.cost": "Coût pour l’employé", // Label for the amount the employee will pay for a program/course
  "activity-fees.header": "Sommaire financier", // The header for the activity fees card
  "activity-fees.howToPay.header": "Comment puis-je payer?", // Header for supplementary section where additional instructions on how to pay is provided
  "activity-fees.jobseeker.cost": "Coût pour le chercheur d’emploi", // Label for the amount the jobseeker will pay for a program/course
  "activity-fees.member.cost": "Coût pour le membre", // Label for the amount the member will pay for a program/course (association tenants)
  "activity-fees.outOfPocket": "Si votre demande est approuvée, vous devrez payer le coût total au moment de votre inscription, puis soumettre la dépense à {companyName}. Pour demander votre subvention, payez les frais comme indiqué dans la politique de votre employeur.", // Supplementary information to explain how to pay in a "out of pocket" scenario
  "activity-fees.overallCost": "Prix global", // label for the overall cost of the program/course
  "activity-fees.programOrCourseCost": "Prix du programme ou du cours", // label for the cost of the program/course
  "activity-fees.refundAmount": "{refundPercent} % de ce que {employerName} a payé sera restitué à la couverture de l’employeur.", // Refund amount message
  "activity-fees.reimbursmentMessage": "Si vous avez déjà été remboursé par votre entreprise, prenez les mesures nécessaires conformément aux politiques de votre entreprise ou communiquez avec {programSponsor}.", // The reimbursment message shown when a company required reimbursment
  "activity-fees.stripeRefundAmount": "Remboursement émis pour {refundPercent} %.", // Stripe refund amount message
  "activity-fees.submitOwnCost": "Prix", // label for cost for submit own requests
  "activity-fees.taxCalculatedNext": "La taxe de vente est calculée à l’étape suivante", // Message informing user the tax will be calcuated on the next page
  "activity-fees.taxIncluded": "Taxe de vente comprise", // Message informing user the tax is already included in the amount displayed
  "activity-fees.taxWillApply": "La taxe de vente s’appliquera", // Message informing user they will have to pay taxes
  "activity-fees.yourProgramSponsor": "votre commanditaire du programme", // Displayed if the name of your program sponsnor is unkonwn

  "activity-filter.clearFilters": "Effacer les filtres", // Button label for clear filters button.
  "activity-filter.dialog.close": "Fermer", // The filter by skill button label
  "activity-filter.filterBy": "Filtrer par", // Label to proceed filter bar interactions
  "activity-filter.filterType.activeState.ariaLabel": "État actif", // Aria text for active state filter dropdown
  "activity-filter.filterType.activeState.displayText": "Provincial", // Label for active state filter dropdown
  "activity-filter.filterType.careerType.ariaLabel": "Carrières", // Career type value for careers
  "activity-filter.filterType.careerType.displayText": "Carrières", // Career type value for careers
  "activity-filter.filterType.careerType.introText": "Choisissez l’option qui décrit le mieux la compétence que vous souhaitez développer", // Text displayed at the top of a career selector, introducing what it contains
  "activity-filter.filterType.certificationType.ariaLabel": "Type de certification", // Aria text for certification type filter dropdown
  "activity-filter.filterType.certificationType.certificate": "Certificat", // Certification type value for certificates
  "activity-filter.filterType.certificationType.course": "Cours", // Certification type value for courses
  "activity-filter.filterType.certificationType.degree": "Diplôme de premier cycle", // Certification type value for degrees
  "activity-filter.filterType.certificationType.diploma": "Diplôme", // Certification type value for diplomas
  "activity-filter.filterType.certificationType.displayText": "Type", // Label for certification type filter dropdown, ignore-untranslated
  "activity-filter.filterType.certificationType.microcredential": "Microcertification", // Certification type value for microcredentials
  "activity-filter.filterType.delivery.ariaLabel": "En ligne ou en personne", // Aria text for delivery filter dropdown
  "activity-filter.filterType.delivery.blended": "Mixte", // Delivery type value for blended
  "activity-filter.filterType.delivery.displayText": "Livraison", // Label for delivery filter dropdown
  "activity-filter.filterType.delivery.inPerson": "En personne", // Delivery type value for in person
  "activity-filter.filterType.delivery.online": "En ligne", // Delivery type value for online
  "activity-filter.filterType.duration.ariaLabel": "Durée du programme", // Aria text for program duration filter dropdown
  "activity-filter.filterType.duration.displayText": "Durée", // Label for program duration filter dropdown
  "activity-filter.filterType.duration.lessThanThreeMonths": "< 3 mois", // Duration value for less than three months
  "activity-filter.filterType.duration.oneToTwoYears": "1 à 2 années", // Duration value for 1 to 2 years
  "activity-filter.filterType.duration.sixToTwelveMonths": "6 à 12 mois", // Duration value for 6 to 12 months
  "activity-filter.filterType.duration.threeToSixMonths": "3 à 6 mois", // Duration value for three to six months
  "activity-filter.filterType.duration.twoPlusYears": "Plus de deux années", // Duration value for more than 2 years
  "activity-filter.filterType.format.ariaLabel": "Format du rythme de livraison du contenu", // Aria for format dropdown label
  "activity-filter.filterType.format.displayText": "Format", // Display text for content delivery format (is either instructor led or self-paced), ignore-untranslated
  "activity-filter.filterType.format.instructorLed": "Donné par un enseignant", // Content pacing description when activity is led by an academic provider employee
  "activity-filter.filterType.format.selfPaced": "À rythme libre", // Content pacing description when activity is led by the individual taking the course
  "activity-filter.filterType.instructionLang.ariaLabel": "Langue de base utilisée par l’enseignant et durant le cours", // Aria for instruction language dropdown label
  "activity-filter.filterType.instructionLang.displayText": "Langue", // Display text for instruction language
  "activity-filter.filterType.instructionLang.en": "Anglais", // Filter label to display courses provided in english
  "activity-filter.filterType.instructionLang.fr": "Français (Canada)", // Filter label to display french canadian courses
  "activity-filter.filterType.skillDialog.ariaLabel": "Compétence", // The header for the skills filter dialog
  "activity-filter.filterType.skillDialog.displayText": "Compétence", // The header for the skills filter dialog
  "activity-filter.filterType.weeklyTimeCommitment.ariaLabel": "Engagement en temps chaque semaine", // Aria text for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.displayText": "Heures/semaine", // Label for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.fiveToTen": "5 à 10 h/semaine", // Weekly time commitment value of five to ten hours a week
  "activity-filter.filterType.weeklyTimeCommitment.lessThanFive": "< 5 h/semaine", // Weekly time commitment value of less than five hours a week
  "activity-filter.filterType.weeklyTimeCommitment.tenToTwenty": "10 à 20 h/semaine", // Weekly time commitment value of ten to twenty a week
  "activity-filter.filterType.weeklyTimeCommitment.twentyPlus": "+20 h/semaine", // Weekly time commitment value of more than twenty hours a week
  "activity-filter.mobile.filterBySkill": "Filtrer par compétence", // The filter by skill button label
  "activity-filter.mobile.filters": "Filtres", // The filters mobile dialog header
  "activity-filter.mobile.otherFilters": "Autres filtres", // The other filters button label

  "activity-import.documentTitle.stream": "Importation d’une diffusion",

  "activity-list.nextPage.ariaLabel": "Prochains programmes", // The aria label for the next page button
  "activity-list.previousPage.ariaLabel": "Programmes précédents", // The aria label for the previous page button
  "activity-list.viewAll": "Afficher tous ({numberOfActivities})", // The text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.). Note that the "category" text is not shown, and only announced by screenreaders in the associated "activity-list.viewAll.ariaLabel".
  "activity-list.viewAll.ariaLabel": "Afficher tous les {category} ({numberOfActivities})", // The accessibility text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.).

  "activity-relationships.completeElectives": "Suivre {numberOfElectives} cours optionnel(s)", // The text specifying how many electives need to be completed.
  "activity-relationships.relatedProgramsHeader": "Fait partie du ou des programmes suivants", // The text for related relationships header
  "activity-relationships.requiredCourses": "Cours obligatoires", // The text for required courses header

  "activity-skill-tagger.mainLabel": "Recherche de compétence", // Label displayed above search input
  "activity-skill-tagger.noResults": "Aucun résultat", // Displayed after searching if there are no results found
  "activity-skill-tagger.resultsPlaceholder": "Les résultats apparaîtront ici.", // Displayed before uses searches for anything
  "activity-skill-tagger.searching": "Recherche en cours...", // Displayed when searching is in progress

  "activity-small.notScheduledDescription": "Ce cours sera disponible lorsque la prochaine séance sera planifiée.", // Alert and tooltip informing about an unscheduled course

  "activity-table.confirmation.cancelText": "Cela annulera également {applicationsLength} application(s).", // Text for confirmation dialogue box when actitivity is disabled to show how many applications will be cancelled
  "activity-table.confirmation.disabledText": "La désactivation de cette activité la rendra invisible à tous les locataires. {cancelText}", // Text for confirmation dialogue box when actitivity is disabled
  "activity-table.confirmation.enableText": "L’activation de cette activité la rendra visible à tous les locataires", // Text for confirmation dialogue box when actitivity is enabled
  "activity-table.confirmation.header": "Êtes-vous certain?", // Header for confirmation dialogue box

  "activity-table.exportDialog.exporting": "Exportation en cours...", // Text for exporting animation
  "activity-table.exportDialog.text": "Ceci exportera les activités avec le filtre appliqué dans un fichier CSV. Veuillez sélectionner ci-dessous les attributs que vous souhaitez exporter.", // Text for export dialog template
  "activity-table.exportDialog.title": "Exporter les activités au format CSV", // Header for export dialog template

  "activity-table.header.actions": "Actions", // ignore-untranslated
  "activity-table.header.active": "Actif", // Label for table header for active toggle
  "activity-table.header.active.tooltip": "Si cette activité est affichée dans le catalogue", // Tooltip for table header for active toggle
  "activity-table.header.export": "Exporter", // Label for stream export button on activities page and in activity page dialogue, and export dialog in the admin section
  "activity-table.header.import": "Importer", // Label for stream import button on activities page in the admin section
  "activity-table.header.provider": "Fournisseur", // Label for table header for provider
  "activity-table.header.req": "Exigé", // Label for table header for whether the activity is requestable
  "activity-table.header.req.tooltip": "Si l’activité peut être demandée", // Tooltip for req table header
  "activity-table.header.tax": "Taxe", // Label for table header for whether the activity is taxable
  "activity-table.header.tax.tooltip": "Si l’activité est imposable", // Tooltip for tax table header
  "activity-table.header.title": "Titre", // Label for table header for title
  "activity-table.header.type": "Type", // Label for table header for type, ignore-untranslated

  "add-role.cancel": "Annuler",
  "add-role.description": "Utilisez cette page pour configurer un nouveau rôle en définissant des permissions générales et détaillées.",
  "add-role.submit": "Créer le rôle",
  "add-role.title": "Créer un nouveau rôle",

  "admin.custom-terms-of-use.alert.disabled": "Case à cocher personnalisée désactivée", // Alert text which appears when the custom term of use checkbox is disabled
  "admin.custom-terms-of-use.alert.enabled": "Case à cocher personnalisée activée", // Alert text which appears when the custom term of use checkbox is enabled
  "admin.custom-terms-of-use.explanation": "Lorsque les employés soumettent des demandes, ils doivent accepter les conditions d’utilisation en cochant un certain nombre de cases. Ici, vous pouvez créer une <bold>case à cocher</bold> propre à {employerName} qui se présentera aux employés <bold>à chaque fois qu’ils soumettront une demande</bold>.", // Introductory text explaining to admins how to use the page
  "admin.custom-terms-of-use.heading": "Ajouter des conditions d’utilisation personnalisées pour les employés", // Heading for a page that allows admins to create custom terms of use for when people create requests to take courses or programs
  "admin.custom-terms-of-use.input.enable-label": "Activer la case à cocher personnalisée. Les modifications s’afficheront une fois enregistrées.", // Label for a checkbox which enables custom terms of use
  "admin.custom-terms-of-use.input.instructions": "Saisir le texte de la case à cocher personnalisée (limite de {charLimit} caractères)", // Label for an input box that allows users to input some custom language to display on the create request page
  "admin.custom-terms-of-use.input.instructions.placeholder": "Saisissez votre texte", // Placeholder text that appears in the custom term of use before the user has input anything
  "admin.custom-terms-of-use.language.intro": "Sélectionner une langue pour localiser le texte de la case à cocher personnalisée.", // Introductory text explaining the purpose of the language selector
  "admin.custom-terms-of-use.optional-statement": "Cette option est facultative et ne vise pas à remplacer la politique de votre entreprise.", // Explanatory text stating that custom terms of use are optional
  "admin.custom-terms-of-use.preview.heading": "Aperçu pour les employés :", // Heading for the preview of what the user-entered custom text will look like
  "admin.custom-terms-of-use.preview.text": "Le texte de la case à cocher personnalisée apparaîtra ici", // Placeholder text for the custom checkbox preview that appears if no custom text has been input

  "admin.tax-details.rates.copied": "Taux d’imposition copiés dans votre presse-papiers", // The label for copying tax rates for toast
  "admin.tax-nexus.add-registration.button": "Ajouter une inscription", // The label for the add registration button
  "admin.tax-nexus.other-registrations": "Sélectionner s’il y a d’autres inscriptions", // Input label for province/state registration selection
  "admin.tax-nexus.registration-country.label": "Pays d’inscription", // The label for the registration country dropdown
  "admin.tax-nexus.tax-registration.label": "Inscriptions fiscales", // The label for the tax registration input,
  "admin.transaction-details.strip-session-id.copied": "ID de connexion Stripe copié dans votre presse-papiers", // The Label for copied toast on stripe session id

  "admission.requirements.contactEmailHelpCard": "Vous avez des questions sur les admissions ? Envoyez un courriel à ", // alert user that they may reach out to the education facility's admission contact person for assistance
  "admission.requirements.hasCostAndDelay": "Des frais supplémentaires d’environ {cost} peuvent s’appliquer et le traitement de la demande peut prendre jusqu’à {delay, plural, one {# semaine} other {# semaines}}.", // lets the learner know a cost and delay is associated with this admission requirement
  "admission.requirements.hasCostOnly": "Des frais supplémentaires d’environ {cost} pourraient s’jappliquer.", // lets the learner know that a cost is associated with this admission requirement
  "admission.requirements.hasDelayOnly": "Le traitement de la demande peut prendre jusqu’à {delay, plural, one {# semaine} other {# semaines}}.", // lets the learner know that a delay (weeks) is associate with this admission requirement
  "admission.requirements.internationalAnnouncement.applicant": "Vous devez préparer des documents supplémentaires si vous avez un relevé de notes d’une école secondaire internationale.", // alerts the applicant that there are additional requirements if they have an international degree
  "admission.requirements.internationalAnnouncement.not.applicant": "Le candidat devra préparer des documents supplémentaires s’il avez un relevé de notes d’une école secondaire internationale.", // alerts reader that the applicant will have additional requirements if they have an international degree

  "admission-requirements.review.documentTitle": "Examiner les exigences - SkillsWave", // Browser-document title for view where program requirements are viewed by user
  "admission-requirements.review.introduction.applicant": "Juste un rappel, le programme {activity} a des exigences d’admission. Si vous êtes approuvé(e) pour suivre ce programme, voici ce que vous devrez préparer.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.introduction.not.applicant": "À titre de rappel, {activity} comporte des exigences d’admission. Si le candidat est autorisé à suivre ce programme, voici ce qu’il devra préparer.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.navigation.cancel": "Revenir", // Text on navigation button that user will press if they'd like to navigate back one page in the workflow
  "admission-requirements.review.navigation.submit": "Je comprends, veuillez examiner ma demande", // Button text that user will select if they'd like to proceed to the next step in the workflow where they review their request for final submission
  "admission-requirements.review.title.applicant": "Si vous êtes approuvé, voici ce dont vous aurez besoin", // Title for "program requirements" page, letting applicants know they'll need to satisfy the below requirements
  "admission-requirements.review.title.not.applicant": "Si la demande est approuvée, voici ce dont elle aura besoin", // Lets approver/admin/etc. know that the applicant will need to satisfy the below requirements

  "app-footer.help.link": "Communiquer avec le service de soutien", // Part 2 of sentence for 'contact-us' page - This portion is the actual hyperlink [internal link]
  "app-footer.help.text": "Vous avez besoin d’aide?", // Part 1 of sentence for link to 'contact-us' page - User will be taken to support/contact-us page [internal link]
  "app-footer.policy.link": "politique et les conditions d’inscription au programme", // Part 2 of sentence for program policy link  - This portion is the actual hyperlink [external link]
  "app-footer.policy.text": "Consulter la", // Part 1 of sentence for program policy link - User will be taken to their company's program policy page [external link]
  "app-footer.trademark.text": "© 2025 SkillsWave Corporation. Tous droits réservés", // Do not translate "SkillsWave Corporation".

  "app-header.about": "À propos", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.analytics": "Analytique", // Navigation bar link, user will be taken to analytics page (privileged users only)
  "app-header.bookmarks": "Signets", // Navigation bar link, employee user will be able to view bookmarked programs/courses
  "app-header.changeLanguage": "Changer la langue", // Navigation bar link, user will be able to choose language the app will be in
  "app-header.editRole": "Modifier le rôle", // Navigation bar link, user will be able relaunch onboarding
  "app-header.home": "Page d'accueil", // Navigation bar link, user will be taken to the home page
  "app-header.howItWorks": "Fonctionnement", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.logo.altText": "Logo de {tenantName}", // Alt text for image of tenant's logo (tenant being an employer or provider)
  "app-header.logo.ariaLabel": "Retourner à la page d’accueil", // ariaLabel for tenant logo link (brings user back to hompage of Wave)
  "app-header.logout": "Fermer la session", // Navigation bar link, user will be logged out
  "app-header.navigation.exit": "Quitter le menu de navigation", // Exit navigation popout dialog on narrow width viewports
  "app-header.navigation.open": "Quitter le menu de navigation", // Opens navigation popout dialog on narrow width viewports
  "app-header.programs": "Programmes", // Navigation bar link, user will be taken to activity catalogue
  "app-header.recommended": "Recommandé", // Navigation bar link, user will be taken to the home page
  "app-header.requests": "Demandes", // Navigation bar link, user will be taken to requests-table view
  "app-header.settings": "Paramètres", // Navigation bar link, user will be taken to settings page

  "app-nova.catchAllErrorMessage": "Veuillez essayer à nouveau. Si le problème persiste, communiquez avec l’équipe de soutien.", // The catch all error message for when an unknown error occurs
  "app-nova.documentTitle": "SkillsWave", // Do not translate "SkillsWave", ignore-untranslated
  "app-nova.marketing.callToAction.button": "Commencez dès aujourd’hui!", // The label that goes on the marketing call to action button
  "app-nova.marketing.callToAction.header": "Découvrez une meilleure façon de libérer <nobreak>le potentiel de vos employés</nobreak>", // The message that goes at the top of all pages when marketing is turned on.
  "app-nova.marketing.dialog.step.intro.getStartedButton": "Commencez dès aujourd’hui!", // The button that will trigger an email to marketing.
  "app-nova.marketing.dialog.step.intro.header": "Développez les compétences dont vous avez besoin avec le talent que vous possédez déjà.", // The header is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.intro.message": "Communiquez avec nous pour planifier une démonstration ou pour commencer.", // The message is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.submit.header": "Nous serons avec vous!", // The header is shown in the marketing popup when a message has been sent ot the marketing team
  "app-nova.marketing.dialog.step.submit.message": "Nous vous remercions pour votre demande. Un représentant de SkillsWave pour entreprises communiquera avec vous d’ici un à deux jours ouvrables pour vous aider à commencer votre parcours.", // The message is shown in the marketing popup when a message has been sent to the marketing team. Do not translate "SkillsWave".
  "app-nova.skipToMain": "Passer au contenu principal", // label for the skip to main content button used for accessibility

  "application.cancelReason.ACTIVITY_NOT_OFFERED": "Ce cours/programme n’est plus disponible.", // Informs the user that this application is canceled because the activity is no longer offered

  "application.status.approved": "Approuvées",
  "application.status.cancelled": "Annulé",
  "application.status.completed": "Terminé",
  "application.status.declined": "Refusées",
  "application.status.failed": "Échec",
  "application.status.oneOfTwoApproved": "1 de 2 approuvé",
  "application.status.paid": "Payé",
  "application.status.pending": "En attente",
  "application.status.registered": "Inscrits",
  "application.status.registrationSubmitted": "Processus d’inscription commencé",
  "application.status.withdrew": "Abandon",

  "application-call-to-action.dialog.cancelled.reason.label": "Raison", // Label for reason input field
  "application-call-to-action.dialog.cancelled.reasonInfo.1": "Raison :", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.cancelled.reasonInfo.2": "Cette information sera fournie à l’apprenant.", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.refundBreakdown": "Détail du remboursement:", // Text title for refund breakdown
  "application-call-to-action.dialog.refundMessageForProvider.1": "{refundPercentage}% ({refundAmountFormatted}) sera remboursé à l'apprenant", // Refund message for provider line 1
  "application-call-to-action.dialog.refundMessageForProvider.2": "{retainPercentage}% ({retainAmountFormatted}) sera conservé par votre établissement", // Refund message provider line 2
  "application-call-to-action.discountCode.save.success": "Vos modifications ont été enregistrées", // alert message for successfully saving discount code
  "application-call-to-action.input.completionDate.label": "Date d’achèvement", // Label for completion date input
  "application-call-to-action.input.completionStatus.label": "État d’achèvement", // Label for completion status input
  "application-call-to-action.input.discountCode.label": "Code de réduction", // Label for discount code input
  "application-call-to-action.input.enrollmentDate.label": "Date d’inscription", // Label for enrollment date input
  "application-call-to-action.input.refund.label": "Remboursement", // Label for refund input
  "application-call-to-action.input.refund.tooltip": "Remboursement à l'apprenant", // Tooltip for refund input
  "application-call-to-action.input.refund.validLabel": "un remboursement valide", // Label for refund input
  "application-call-to-action.providerForm.error.completionBeforeEnrollment": "La date d'achèvement doit être la même que celle de l'inscription ou une date ultérieure", // Error msg to display if completion date is before the enrollment date
  "application-call-to-action.providerForm.error.empty.completionDate": "Vous avez besoin d’une date de complétion", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.discountCode": "Vous avez besoin d’un code de réduction",
  "application-call-to-action.providerForm.error.empty.enrollmentDate": "Vous avez besoin d’une date d’inscription", // Error msg for missing enrollment date
  "application-call-to-action.providerForm.error.empty.invalid_refund": "Vous avez besoin d'un montant de remboursement valide si le cours est annulé", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.refund": "Vous avez besoin d’un montant de remboursement si le cours est annulé", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.template": "Vous avez besoin", // Error msg template"
  "application-call-to-action.providerForm.error.template.and": "et", // Error msg separator
  "application-call-to-action.status.cancelled": "Annulé", // Cancelled application status
  "application-call-to-action.status.fail": "Échec", // Failed application status
  "application-call-to-action.status.pass": "Réussite", // Passed application status
  "application-call-to-action.status.pending": "En attente", // Pending application status
  "application-call-to-action.status.withdrawn": "A abandonné", // Withdrawn application status
  "application-call-to-action.template.approver.firstApprover": "{role, select, sponsor {Le commanditaire} manager {Le gestionnaire} other {L’approbateur}} n’a pas encore examiné cette demande. Nous vous tiendrons au courant lorsque nous aurons plus de renseignements.", // Status bar message to show first approver after first request approval.
  "application-call-to-action.template.approver.firstApprover.heading": "Vous avez approuvé cette demande", // heading for first approver
  "application-call-to-action.template.approver.secondApprover": "{role, select, sponsor {Le commanditaire} manager {Le gestionnaire} other {L’approbateur}} a approuvé cette demande", // Message to show second approver after first request approval, stating that the manager has already approved the request.
  "application-call-to-action.template.provider.buttonSave": "Enregistrer", // Button text for saving provider call-to-action input
  "application-call-to-action.template.requester.accountConnection.button": "Commencer", // Button will navigate learner to workflow where they will create a profile
  "application-call-to-action.template.requester.accountConnection.heading": "Votre demande a été approuvée! Soumettons votre inscription.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile
  "application-call-to-action.template.requester.accountConnection.heading.admissionBased": "Vous être approuvé! Commencer les démarches d’admission et d’inscription.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile on an admission based activity
  "application-call-to-action.template.requester.accountConnection.heading.isPaymentHandledByProvider": "Votre demande a été approuvée! Suivez les étapes suivantes pour vous inscrire auprès de {providerName}.", // Approval celebration text displayed when application is approved - but learners activity is paid for by provider
  "application-call-to-action.template.requester.accountConnection.text": "Votre participation devrait prendre 10 minutes. Ensuite, {providerName} prendra environ {registrationTime} jours ouvrables pour vous inscrire.", // Supplimentary information regarding registration of learner account with provider
  "application-call-to-action.template.requester.accountConnection.text.admissionBased": "Une fois ces étapes complétées, {providerName} nécessitera environ {registrationTime} jours ouvrables pour vous contacter et entamer la procédure d’admission et d’inscription.",
  "application-call-to-action.template.requester.approved.registrationClosed.bodyText": "Lorsque la prochaine session sera planifiée, vous pourrez vous y inscrire.", // Supplementary info displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approved.registrationClosed.heading": "Votre demande est approuvée, mais la période d’inscription est terminée.", // Heading of a call to action that is displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.copy": "Copier le code", // Aria-label for copying discount code button
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.label": "Code de réduction :", // Label beside discount code
  "application-call-to-action.template.requester.approvedOrRegistered.heading": "Votre demande est approuvée! Commencez les prochaines étapes pour vous inscrire.", // Heading to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.heading.admissionBased": "Vous être approuvé! Commencer les démarches d’admission et d’inscription.", // Heading to show requesters when application is approved or registered on an admission based activity
  "application-call-to-action.template.requester.approvedOrRegistered.heading.discountCode": "Félicitations, votre demande a été approuvée! Mais n’oubliez pas votre code de réduction.", // Heading to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.pendingDiscountCode": "En attente du code de réduction pour l’inscription", // Message to show requesters when application is pending discount code (approved or registered status)
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label": "S’inscrire", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label.admissionBased": "Commencer les prochaines étapes", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.text": "Rendez-vous sur la page d’inscription de {providerName} pour vous inscrire!", // Text to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.text.discountCode": "Utilisez ce code de réduction à la caisse lorsque vous vous inscrivez à votre cours avec {providerName}.", // Text to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe": "Une fois vos étapes terminées, {providerName} prendra environ {registrationTime} jours ouvrables pour vous inscrire.", // Text to show requesters when application is approved or registered (stripe)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe.admissionBased": "Une fois ces étapes complétées, {providerName} nécessitera environ {registrationTime} jours ouvrables pour vous contacter et entamer la procédure d’admission et d’inscription.", // Text to show requesters when application is approved or registered for admission based activity(stripe)
  "application-call-to-action.template.requester.cancelled": "Ce programme a été annulé par {providerName}, en vigueur le {formattedCompletionDate}", // Message to show requesters when application has been cancelled
  "application-call-to-action.template.requester.failed": "N’a pas satisfait aux exigences requises pour réussir ce programme", // Message to show requesters when they failed the program/course they applied for
  "application-call-to-action.template.requester.oneOfTwoApproved.role.manager": "Le gestionnaire doit quand même examiner cette demande. Nous vous tiendrons au courant lorsque nous aurons plus de renseignements.", // Message to show requester when request is approved by the manager in hybrid approval model
  "application-call-to-action.template.requester.oneOfTwoApproved.role.sponsor": "Le commanditaire doit quand même examiner cette demande. Nous vous tiendrons au courant lorsque nous aurons plus de renseignements.", // Message to show requester when request is approved by the sponsor in hybrid approval model
  "application-call-to-action.template.requester.paid.heading": "{providerName} procède à votre inscription", // Heading to show requesters when application is paid
  "application-call-to-action.template.requester.paid.heading.isPaymentHandledByProvider": "Soumettez votre demande et inscrivez-vous à ce programme sur le <redirectLink>site Web de {providerName}</redirectLink>", // Heading to show requesters when application is paid for by provider
  "application-call-to-action.template.requester.paid.text": "Une fois la fenêtre d’inscription ouverte, vous serez inscrit dans environ {registrationTime} jours ouvrables. {institution} communiquera avec vous au {email} pour confirmer votre inscription. Pour toute question, communiquez avec le service de soutien de <link>SkillsWave</link>.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.paid.text.isPaymentHandledByProvider": "Si vous avez des questions à propos de votre demande ou du processus d’inscription, communiquez avec <link>{studentSupportEmail}</link>.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.pending": "Cette demande est actuellement en attente d’approbation. Nous vous tiendrons au courant lorsque nous aurons plus de renseignements.", // Message to show requesters when application is pending
  "application-call-to-action.template.requester.withdrawn": "Abandon de {activityTitle}, en vigueur le {formattedCompletionDate}", // Message to show requesters when the application has been withdrawn

  "application-overview.approvalAmount.checkbox.label": "Je gère le budget de formation pour {employeeName}", // Label for a confirmation checkbox when authorizing the inputted approval amount
  "application-overview.approvalComment": "Commentaire", // Label for text field where approver can leave a comment when approving a request
  "application-overview.approvalInfo.amount": "Montant approuvé", // Key for the approved amount
  "application-overview.approvalInfo.amount.edit": "Modifier", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.edit.cancel": "Annuler", // Button text for cancel editing approval amount
  "application-overview.approvalInfo.amount.edit.label": "Modification du montant approuvé", // Key for editing approval amount
  "application-overview.approvalInfo.amount.pending": "En attente de l’approbation du détenteur du budget", // Key for pending approval amount
  "application-overview.approvalInfo.amount.save": "Enregistrer", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.updated": "Le montant approuvé a été mis à jour", // toast message for updating approval amount
  "application-overview.approvalInfo.description.hybridBudgetHolder": "Cette demande doit être approuvée par le gestionnaire de {employeeName} et le commanditaire. Le {budgetHolder} déterminera le budget de formation pour cette demande.", // approval information description for Hybrid Case by Case
  "application-overview.approvalInfo.description.hybridNonBudgetHolder": "Cette demande doit être approuvée par le gestionnaire de {employeeName} et le commanditaire", // approval information description
  "application-overview.approvalInfo.heading": "Renseignements sur l’approbation", // Title for approval information
  "application-overview.approvalInfo.hybridCBC.manager": "Gestionnaire (détenteur du budget)", // Key for manager name
  "application-overview.approvalInfo.hybridCBC.sponsor": "Commanditaire (détenteur du budget)", // Key for sponsor name
  "application-overview.approvalInfo.manager": "Gestionnaire chargé de l’approbation", // Key for the name of the approving manager
  "application-overview.approvalInfo.managerComments": "Commentaires du gestionnaire", // Key for the comments of the approving manager
  "application-overview.approvalInfo.maxAllowedCoverage.tooltip": "Vous ne pouvez pas approuver plus que le coût total", // Tooltip text indicating to the user when the inserted amount is greater than the total cost
  "application-overview.approvalInfo.minAllowedCoverage.tooltip": "Vous ne pouvez pas approuver pour moins de 0 $",
  "application-overview.approvalInfo.percentCoverage.tooltip": "Par défaut, votre organisation couvre {percentCoverage} % du coût", // Tooltip text indicating to the user what the default number in the 'approved amount' input box is.
  "application-overview.approvalInfo.sponsor": "Commanditaire responsable de l’approbation", // Key for the name of the approving sponsor
  "application-overview.approvalInfo.sponsorComments": "Commentaires du commanditaire", // Key for the comments of the approving sponsor
  "application-overview.approveInfo.confirmBudgetHolder": "Confirmez que vous gérez le budget de {employeeName}",
  "application-overview.cancelReason": "Raison de l’annulation", // Label for text field where requester can give a reason for cancelling a request
  "application-overview.declineReason": "Raison", // Label for text field where approver can give a reason for declining a request
  "application-overview.enrollmentInfo.cancellationReason": "Raison de l’annulation", // Key for the program or course's cancellation reason
  "application-overview.enrollmentInfo.completedOn": "Terminé le", // Key for the date the user completed the program or course
  "application-overview.enrollmentInfo.completionStatus": "État d’achèvement", // Key for the user's completion status
  "application-overview.enrollmentInfo.enrolledOn": "Date d’inscription", // Key for the date the user enrolled for the program or course
  "application-overview.enrollmentInfo.heading": "Renseignements sur l’inscription", // Title for enrollment information
  "application-overview.enrollmentInfo.status.admin.revoke": "Annulé par l'administrateur", // Cancelled application status for admin initiated cancel
  "application-overview.enrollmentInfo.status.cancelled": "Annulé par le fournisseur", // Cancelled application status
  "application-overview.enrollmentInfo.status.employee.revoke": "Annulé par l’employé", // Cancelled application status for employee initiated cancel
  "application-overview.enrollmentInfo.status.fail": "Échec", // Failed application status
  "application-overview.enrollmentInfo.status.jobseeker.revoke": "Annulé par le chercheur d’emploi", // Cancelled application status for jobseeker initiated cancel
  "application-overview.enrollmentInfo.status.member.revoke": "Annulé par le membre", // Cancelled application status for member initiated cancel
  "application-overview.enrollmentInfo.status.pass": "Réussite", // Passed application status
  "application-overview.enrollmentInfo.status.pending": "En attente", // Pending application status
  "application-overview.enrollmentInfo.status.withdrawn": "A abandonné", // Withdrawn application status
  "application-overview.existingApplication.header": "{displayName} a déjà soumis cette même demande.", // Title for the re-request alert banner
  "application-overview.existingApplication.status.cancelled": "La demande précédente a été annulée par {displayName}.", // subtext for when the previous application for a user was cancelled
  "application-overview.existingApplication.status.declined": "{displayName} a déjà fait la même demande, et elle a été refusée.", // subtext for when the previous application for a user was declined
  "application-overview.existingApplication.status.declined.noDecliner": "La demande a été refusée.", // subtext for when the previous application for a user was declined and the decliner can't be determined
  "application-overview.existingApplication.status.failed": "{displayName} a déjà échoué ce {type}.", // subtext for when the previous application for a user was failed
  "application-overview.existingApplication.status.withdrawn": "{displayName} s’était déjà retiré de ce {type}.", // subtext for when the previous application for a user was withdrawn
  "application-overview.existingApplication.subtext.link": "Afficher la demande précédente",
  "application-overview.general.cost": "Coût", // Key for the cost of the program or course requested
  "application-overview.general.country": "Pays", // Key for the country of the program or course requested
  "application-overview.general.department": "Département", // key for the department of the user that is requesting the program or course
  "application-overview.general.email": "Courriel", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.goal": "Programme prévu", // Key for activity request goal
  "application-overview.general.nextSession": "Prochaine séance", // Key for the next session of the program or course
  "application-overview.general.requestId": "ID de la demande", // Key for the request's ID
  "application-overview.general.requestReason": "Motif de la demande", // Key for the reason given by the user for requesting the program or course
  "application-overview.general.role": "Rôle", // Key for the user's role within their company
  "application-overview.general.stateOrProvince": "Province ou état", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.submitted": "Soumis", // Key for the user who submitted the request and when they submitted it
  "application-overview.general.submittedOn": "{displayName} le {applicationDate}", // A connector used within the value for 'Submitted' to join the user who submitted the request and the submission date
  "application-overview.heading": "Aperçu de la demande", // Top-level heading for the application overview details
  "application-overview.requestConfirmation.approve": "Approuver", // Label for approve button
  "application-overview.requestConfirmation.decline": "Refuser", // Label for decline button
  "application-overview.requesterAction.revoke": "Annuler la demande", // Label for decline button
  "application-overview.revokeInfo.cancelled": "Votre demande pour {activity} a été annulée",
  "application-overview.revokeInfo.tooltip": "Votre demande d’inscription a déjà été envoyée à {provider}. Communiquez avce le service de soutien de SkillsWave pour obtenir de l’aide.", // do not translate "SkillsWave"
  "application-overview.status": "État", // The key used in off-screen text to indicate the request's progress to screen reader users
  "application-overview.wizardLabels.course.step.4": "Cours terminé", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.employee.step.3": "Employé inscrit", // Step 3 of 4 in the wizard/progress tracker for an employee request
  "application-overview.wizardLabels.jobseeker.step.3": "Chercheur d’emploi inscrit", // Step 3 of 4 in the wizard/progress tracker for a jobseeker request
  "application-overview.wizardLabels.member.step.3": "Membre inscrit", // Step 3 of 4 in the wizard/progress tracker for a member request
  "application-overview.wizardLabels.program.step.4": "Programme terminé", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.1": "Demande reçue", // Step 1 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.2": "Demande approuvée", // Step 2 of 4 in the wizard/progress tracker for an individual request

  "application-table.animationText": "Exportation en cours", // Label for export button when it is animating
  "application-table.csv.title": "Liste_des_demandes_", // Title of csv export; today's date is appended to this title along with the '.csv' file extension
  "application-table.employee.search.label": "Trouvez un code d’utilisateur, un employé, un programme ou un cours", // The label for the application table search bar
  "application-table.export": "Demandes d’exportation", // Label for export button
  "application-table.export.complete": "Exportation terminée", // toast message for export complete
  "application-table.export.error": "Un problème est survenu. Veuillez essayer de nouveau.", // toast message for export error
  "application-table.export.processing": "Exportation en cours", // toast message for export processing
  "application-table.filter.course.ariaLabel": "Filtrer par cours", // Aria label for course filter
  "application-table.filter.course.label": "Cours", // Text for course filter
  "application-table.filter.course.tooltipText": "Filtrer les demandes par cours ou nom de programme", // Tooltip for course filter
  "application-table.filter.employer.ariaLabel": "Filtrer par employeur", // Aria label for employer filter
  "application-table.filter.employer.tooltipText": "Filtrer les demandes par nom d’employeur", // Tooltip for employer filter
  "application-table.filter.filterBy": "Filtrer à l’aide de :", // Label for requests filter
  "application-table.filter.provider.ariaLabel": "Filtrer par fournisseur", // Aria label for provider filter
  "application-table.filter.provider.smo": "Demande externe", // Filter name for applications that come from outside the system
  "application-table.filter.provider.tooltipText": "Filtrer les demandes par nom de fournisseur", // Tooltip for provider filter
  "application-table.filter.status.ariaLabel": "Filtrer par état", // Aria label for status filter
  "application-table.filter.status.label": "État", // Text for status filter
  "application-table.filter.status.tooltipText": "Filtrer par statut de demande (p. ex., Approuvée, Terminée)", // Tooltip for status filter
  "application-table.jobseeker.search.label": "Trouvez un code d’utilisateur, un chercheur d’emploi, un programme ou un cours", // The label for the application table search bar
  "application-table.member.search.label": "Trouvez un code d’utilisateur, un membre, un programme ou un cours", // The label for the application table search bar
  "application-table.search.placeholder": "Demandes de recherche...", // The plaveholder for the application table search bar
  "application-table.submitOwn": "Soumettre ma propre demande", // Label for submit own request button
  "application-table.table.activity": "Programme/cours", // Key for the program or course the user requested
  "application-table.table.employee": "Employé(e)", // Key for the employee's name
  "application-table.table.empty": "Il n’y a pas de demandes présentement", // Text displayed when there are no requests in the table
  "application-table.table.id": "Identifiant", // Key for the request ID
  "application-table.table.jobseeker": "Chercheur d’emploi", // Key for the jobseeker's name
  "application-table.table.member": "Membre", // Key for the member's name
  "application-table.table.noResults": "Veuillez vérifier l’orthographe ou faire une nouvelle recherche", // No results message when filtered to no results
  "application-table.table.payment": "Paiement", // Key for user payment in provider view
  "application-table.table.price": "Prix", // Key for program/course price
  "application-table.table.session": "Session", // Key for program or course's next session, ignore-untranslated
  "application-table.table.sortingButtonTitle": "Trier par {header}", // Sorting button by header items
  "application-table.table.status": "État", // Key for request status
  "application-table.table.submitted": "Soumis", // Key for the date the user submitted the request

  "application-table-row.approvedAmount": "Montant approuvé", // The key used in off-screen text to indicate the approved amount to screen reader users
  "application-table-row.aria-label": "Demande de {employeeName} pour {activityTitle}. Soumis le {applicationDate}, prochaine session : {nextSessionDate}, prix : {activityCost}. État de la demande : {status}. ID de demande : {slug} ", // The aria-label for the application table row
  "application-table-row.days.past.plural": "Il y a {numDays} jours", // Describes how many days ago an application was submitted if more than 1 day
  "application-table-row.days.past.singular": "Il y a 1 jour", // Describes how many days ago an application was submitted if it was 1 day ago
  "application-table-row.days.today": "Aujourd’hui", // Displays when submitted on same day as viewer
  "application-table-row.paid": "Payé", // Label text to indicate the amount the user paid for the program or course

  "application-transaction-overview.header": "Aperçu de la demande de transaction", // header for the transaction overview card

  "apply-activity.OR": "OU",
  "apply-activity.agree.title": "Conditions d'utilisation", // Section where user has to agree to certain policies
  "apply-activity.breadcrumb": "Retour au programme", // Link text to navigate to the program view page
  "apply-activity.buttonClose": "Fermer",
  "apply-activity.course.addProgram": "Sélectionnez un programme", // Dropdown option to add a program
  "apply-activity.course.addProgram-tagList-description": "Programmes parents sélectionnés comme objectif pour le cours", // Description for the tag list of parent programs
  "apply-activity.course.programsRelated": "{activityTitle} fait partie du/des programme(s) ci-dessous. Si vous travaillez à l'achèvement d'un programme, informez votre employeur pour qu'il puisse soutenir votre progression", // Section where user is informed of related programs
  "apply-activity.course.prompt": "Votre demande concerne le cours suivant :", // Course request prompt
  "apply-activity.course.request.title": "Cours", // Course request section title
  "apply-activity.courses.prompt": "Pour quel cours voulez-vous demander une approbation?", // Course selection prompt when multiple courses are available
  "apply-activity.customCondition": "Vous trouverez ci-dessous une condition supplémentaire conformément à la politique du programme :", // If additional condition is enabled by employer, prompt user that they need to accept it
  "apply-activity.documentTitle": "Ma demande – SkillsWave", // Document title when viewing the apply-activity page. Do not translate "SkillsWave".
  "apply-activity.employer": "mon employeur", // Placeholder text for the user's employer
  "apply-activity.error.blank.departmentName": "Le nom du département ne peut pas être vide", // Error prompt shown to magic link users when applying for program in the event thery entered a department name equivalent to blank spaces.
  "apply-activity.error.blank.name": "Le prénom et le nom de famille ne peuvent pas être vides", // Error prompt shown to magic link users when applying for program in the event they entered a first and/or last name equivalent to blank spaces.
  "apply-activity.error.cantApply": "Vous ne pouvez pas faire de demande de programme ou de cours à moins que vous ne soyez un employé", // Error prompt shown to SkillsWave admins or academic partners when attempting to request a program/course
  "apply-activity.error.missing.approver": "Approbateur demandé manquant", // Error prompt that the requested approver is missing
  "apply-activity.error.missing.required": "Champ obligatoire manquant", // Error prompt that some required field is missing
  "apply-activity.error.noselection": "Vous devez sélectionner un programme ou un cours pour demander une approbation", // Error prompt that a program or course must be selected
  "apply-activity.existingApp.header": "Vous avez déjà fait la même demande.",
  "apply-activity.existingApp.subtext": "Ne vous inquiétez pas, vous pouvez quand même transmettre cette demande.",
  "apply-activity.existingApp.subtext.link": "Vous pouvez également consulter votre demande précédente",
  "apply-activity.introduction.double": "Cette demande sera approuvée par : <b>{approverOne}</b> et <b>{approverTwo}</b>", // Text to inform user of both approvers for their application
  "apply-activity.introduction.single": "Cette demande sera approuvée par : <b>{approver}</b>", // Text to inform user of approver for their application
  "apply-activity.jobseeker.employer": "mon organisation", // Placeholder text for the user's organization
  "apply-activity.loading": "Chargement du programme/du cours", // Placeholder loading text
  "apply-activity.manager.label": "Mon gestionnaire est", // Label to show manager
  "apply-activity.manager.name.label": "Nom du gestionnaire", // Label to show manager's name
  "apply-activity.manager.toggle.link": "Cette personne n’est pas mon gestionnaire", // Link to remove manager
  "apply-activity.member.employer": "mon association", // Placeholder text for the user's association
  "apply-activity.motivation.title": "Ma motivation", // Title for section where user explains their reason for taking a course
  "apply-activity.name.double": "{approverOne} et {approverTwo}", // Name formatter for condition where there's two approvers
  "apply-activity.noManager.label": "Votre demande sera acheminée à votre gestionnaire du programme d’apprentissage", // Label to show that no manager is selected
  "apply-activity.noManager.toast": "Vous n’avez pas de gestionnaire ou de commanditaire de programme. Veuillez communiquer avec le service de soutien.", // Error prompt that user's account has no manager or sponsor associated with it
  "apply-activity.noManager.toggle.link": "Acheminer ma demande à", // Link to add manager
  "apply-activity.personalInfo.country": "Pays de résidence", // Prompt for user to enter their country of residence
  "apply-activity.personalInfo.country.notSupported": "Les autres pays ne sont pas pris en charge", // Error when someone is applying to a location-restricted from a non-supported country
  "apply-activity.personalInfo.customFields.costCode": "Code de coûts", // Prompt to enter Job code
  "apply-activity.personalInfo.customFields.employeeNumber": "Numéro d’employé", // Prompt to enter employee Number
  "apply-activity.personalInfo.customFields.jobCode": "Code d’emploi", // Prompt to enter cost code
  "apply-activity.personalInfo.department": "Département", // Prompt to enter Department name
  "apply-activity.personalInfo.description": "Si votre demande est approuvée, votre nom et le titre de votre poste seront communiqués à l’établissement aux fins d’inscription.", // Notify to the user that their name and job title (name being manually entered in a subsequent field) will be shared with the educational institute.
  "apply-activity.personalInfo.email": "Adresse courriel", // Prompt to enter Department name
  "apply-activity.personalInfo.firstName": "Prénom", // In event where the user logged in with the magic link, we do not know the user's first name, and hence it must be requested here.
  "apply-activity.personalInfo.jobTitle": "Titre de poste", //  Prompt for user to enter job title
  "apply-activity.personalInfo.lastName": "Nom de famille", // In event where the user logged in with the magic link, we do not know the user's last name, and hence it must be requested here.
  "apply-activity.personalInfo.phone": "Numéro de téléphone", // Prompt for user to enter their phone number
  "apply-activity.personalInfo.phone.invalid": "Le numéro de téléphone n’est pas valide", // Error message for when phone number is invalid
  "apply-activity.personalInfo.postalCode": "Code postal", // Prompt for user to enter their postal code
  "apply-activity.personalInfo.postalCode.inPersonLocationRestricted": "{postalCode} (à moins de {range} km de l’institut)",
  "apply-activity.personalInfo.postalCode.invalid": "Le code postal n’est pas valide", // Message that appears to inform the user that the postal code they entered is invalid
  "apply-activity.personalInfo.postalCode.invalidOptions": "Doit fournir à la fois le rayon et le code postal d’origine pour valider la distance", // Message that appears to inform the user that there is a configuration error with the activity postal code validation
  "apply-activity.personalInfo.postalCode.tooFar": "Ce programme nécessite votre présence en personne. Votre emplacement est trop éloigné de l’endroit où le programme aura lieu.", // Message that appears to inform the user that the postal code they entered is too far from the learning centre
  "apply-activity.personalInfo.postalCode.tooltip": "Votre code postal est utilisé pour calculer la taxe de vente", // Tooltip to explain why postal code is collected
  "apply-activity.personalInfo.preferredEmail.tooltip": "Le courriel sera utilisé pour les communications. Saisissez une adresse courriel que vous vérifiez régulièrement.",
  "apply-activity.personalInfo.province": "Province", // User location province (if user in Canada), ignore-untranslated
  "apply-activity.personalInfo.state": "État", // User location state
  "apply-activity.personalInfo.stateOrProvince": "Province/État", // User location state/province
  "apply-activity.personalInfo.title": "Quelques informations sur moi", // Title for section. This section prompts the user to enter additional personal information because their login method (magic link) does not provide certain required information (e.g. first & last name) for proper registration.
  "apply-activity.personalInfo.transcript": "Origine du relevé de notes", // User transcript
  "apply-activity.personalInfo.transcriptHeader": "Où avez-vous reçu votre relevé de notes?",
  "apply-activity.personalInfo.zipCode": "Code postal", // Prompt for user to enter their ZIP code
  "apply-activity.personalInfo.zipCode.invalid": "Le code postal n’est pas valide", // Message that appears to inform the user that the ZIP code they entered is invalid
  "apply-activity.personalInfo.zipCode.tooltip": "Votre code postal est utilisé pour calculer la taxe de vente", // Tooltip to explain why ZIP code is collected
  "apply-activity.program.prompt": "Votre demande concerne le programme suivant :", // Program request prompt
  "apply-activity.program.request.title": "Programme", // Program request section title
  "apply-activity.programcourses.prompt": "Voulez-vous vous inscrire à l’ensemble du programme ou seulement à un cours en particulier?", // Activity selection prompt when user can choose between a whole program or a specific course
  "apply-activity.provider": "le fournisseur d’enseignement", // Placeholder text for the education provider that created this activity
  "apply-activity.reason.prompt": "Cela contribuera à mon perfectionnement professionnel en...", // Prompt for user to explain reason for taking the program
  "apply-activity.registration.title": "Inscription", // Request registration section title
  "apply-activity.review.button": "Passer en revue ma demande", // Form button to review draft application to activity
  "apply-activity.review.introduction": "Je demande : <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.review-submit-own.introduction": "Vous demandez : <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.reviewAdmissionRequirements.button": "Vérifier les conditions d’admission",
  "apply-activity.select.title": "Ce que je veux apprendre", // Section title to select activity to apply to
  "apply-activity.submit.button": "Soumettre la demande", // Submit request button text
  "apply-activity.terms.agree": "Je suis d'accord avec les", // Prompt text for Terms of Use for the user to agree to. (Part 1)
  "apply-activity.terms.link": "Conditions d'utilisation", // Link text for Terms of Use for the user to agree to. (Part 2)
  "apply-activity.termsOfUse": "Je comprends que les dossiers scolaires et les renseignements liés à mon inscription au programme sélectionné, y compris mon statut d'achèvement, mes notes d'appréciation et mon inscription (« données d'achèvement ») peuvent être protégés par la loi sur la protection des renseignements personnels applicable. Par la présente, j'autorise {provider} à divulguer les données d'achèvement à SkillsWave ou à {employer} aux fins de la prestation des services. J'autorise également SkillsWave à fournir les renseignements à mon sujet à {provider} qui sont nécessaires à mon inscription au cours ou au programme sélectionné.",
  "apply-activity.title": "Ma demande", // Request submission page title
  "apply-activity.tos": "Conditions d’utilisation",

  "audit.action.activity_activeStateChange": "État actif de l’activité modifié", // Display name for audit type activeStateChange
  "audit.action.activity_costUpdated": "Coût de l’activité mis à jour", // Display name for audit type activeStateChange
  "audit.action.activity_created": "Activités créées", // Display name for audit type activityCreated
  "audit.action.activity_deleted": "Activité supprimée", // Display name for audit type activityDeleted
  "audit.action.application_approved": "Demande approuvée", // Display name for audit type requestApproved
  "audit.action.application_approved_manager": "Demande approuvée par le gestionnaire", // Display name for audit type managerApproved
  "audit.action.application_approved_sponsor": "Demande approuvée par le commanditaire", // Display name for audit type sponsorApproved
  "audit.action.application_cancelReasonSubmitted": "Raison de l’annulation soumise", // Display name for audit type cancelReasonSubmitted
  "audit.action.application_completedDateSubmitted": "Date d’achèvement soumise", // Display name for audit type completedDateSubmitted
  "audit.action.application_completionStatusSubmitted": "État d’achèvement soumis", // Display name for audit type completionDateSubmitted
  "audit.action.application_enrollDateSubmitted": "Date d’inscription soumise", // Display name for audit type enrollmentDateSubmitted
  "audit.action.application_refundPctSubmitted": "Pourcentage de remboursement soumis", // Display name for audit type refundPercentageSubmitted
  "audit.action.application_rejected": "Demande rejetée", // Display name for audit type requestRejected
  "audit.action.application_rejected_manager": "Demande rejetée par le gestionnaire", // Display name for audit type managerRejected
  "audit.action.application_rejected_sponsor": "Demande rejetée par le commanditaire", // Display name for audit type sponsorRejected
  "audit.action.application_started": "Demande commencée", // Display name for audit type requestStarted
  "audit.action.audit_record_change": "Les dossiers d'audit ont été modifiés",
  "audit.action.collect_details_page_submitted": "Recueillir les renseignements sur la page soumis", // Display name for audit record showing user submitted the form on the Collect Email splash page
  "audit.action.collect_details_page_viewed": "Recueillir les renseignements sur la page visualisés", // Display name for audit record showing user viewed the Collect Email splash page

  "audit.action.failed_payment_pre_authorization": "Échec de la préautorisation de paiement",
  "audit.action.login": "Ouvrir une session", // Display name for audit type login
  "audit.action.login_linkRequested": "Lien magique demandé", // Display name for audit type magicLinkRequested
  "audit.action.login_linkUsed": "Lien magique utilisé", // Display name for audit type magicLinkUsed
  "audit.action.logout": "Fermer la session", // Display name for audit type logout
  "audit.action.requestPaid": "Demande payée", // Display name for audit type requestPaid
  "audit.action.role_added": "Rôle ajouté au catalogue des rôles", // Display name for audit type role_added
  "audit.action.role_assign_user_to_role": "Rôle assigné à l'utilisateur", // Display name for audit type assign_user_to_role
  "audit.action.role_deleted": "Rôle supprimé du catalogue des rôles", // Display name for audit type role_deleted
  "audit.action.role_permission_assigned_to_role": "Permission assignée au rôle", // Display name for audit type permission_assigned_to_role
  "audit.action.role_permission_unassigned_from_role": "Permission désassignée du rôle", // Display name for audit type permission_unassigned_from_role
  "audit.action.role_unassign_user_from_role": "Rôle retiré de l'utilisateur", // Display name for audit type unassign_user_from_role
  "audit.action.tenant_logo_email_uploaded": "Logo du courriel du locataire téléversé", // Display name for audit type tenantEmailAssetsUploaded
  "audit.action.tenant_logo_image_url_changed": "URL du logo du locataire modifiée", // Display name for audit type tenantLogoImageUrlChanged
  "audit.action.tenant_logo_image_url_email_changed": "URL du logo du courriel du locataire modifiée", // Display name for audit type tenantLogoImageUrlEmailChanged
  "audit.action.tenant_logo_uploaded": "Logo du locataire téléversé", // Display name for audit type tenantLogoAssetsUploaded
  "audit.action.user_created": "Créations par l’utilisateur", // Display name for audit type userCreated
  "audit.action.user_failed_freshdesk": "La création du contact Freshdesk a échoué", // Display name for audit type user_failed_freshdesk
  "audit.action.user_updated": "Mises à jour par l’utilisateur", // Display name for audit type userUpdated
  "audit.action.visibility_record_change": "Les enregistrements de visibilité ont changé", // Display name for audit type visibility_record_change

  "auth-error.body": "Vous avez tenté d’ouvrir une session avec une méthode d'authentification différente de celle que vous avez utilisée lors de votre inscription. Veuillez essayer de nouveau en utilisant votre méthode d'authentification initiale.", // Error text when public portal users try to create another account with existing email
  "auth-error.button.label": "Veuillez réessayer", // Label for the button on the auth error page
  "auth-error.title": "Oups!", // Error title when public portal users try to create another account with existing email

  "career-explorer.enable": "Activet l’Explorateur de carrières",
  "career-explorer.toggleSuccess": "Explorateur de carrières activé",
  "career-stream.headingIntro": "Recommandé pour {careerName}",
  "career-stream.topSkills": "Meilleures compétences correspondantes : {listOfSkills}", // Text introducing top skills associated with a stream followed by a comma-separated list of the top skills

  "common.startDate": "Date de début", // The course/programs start date label - reused in multiple contexts
  "common.startDateType": "DateType", // The course/programs start date type label - reused in multiple contexts

  "components.form-element.emailInvalid": "L'email est invalide", // Error message for invalid email address
  "components.form-element.permissions.valueMissing": "Au moins une permission doit être sélectionnée pour ce rôle.",
  "components.form-element.valueMissing": "{label} est requis.",

  "confirmation-dialog.actionMessage.cantBeUndone": "Action irréversible.", // Action message displayed in dialog before confirming
  "confirmation-dialog.activateUser.confirmButton": "Activer l'utilisateur", // button label for confirmation dialog on the activate users confirmation
  "confirmation-dialog.activateUser.description": "Êtes-vous sûr de vouloir réactiver l'accès de <bold>{fullName}</bold> au Système Administratif de Skillswave ? L'utilisateur se verra attribuer les rôles qui lui avaient été assignés auparavant.", // Description for dialog to activate user
  "confirmation-dialog.activateUser.title": "Confirmation de l'activation de l'utilisateur", // Description for title to activate user confirmation dialog
  "confirmation-dialog.approveRequest.button": "Approuver", // Primary button label to approve request
  "confirmation-dialog.approveRequest.description": "Veuillez confirmer que vous souhaitez approuver la demande :", // Description for approve request dialog
  "confirmation-dialog.approveRequest.title": "Approuver la demande", // Title for approve request dialog
  "confirmation-dialog.cancel": "Annuler", // Abort confirmation dialog
  "confirmation-dialog.cancelledOrWithdrawn.employee.footerDescription": "Une fois la confirmation reçue, l’employé et les parties prenantes de l’entreprise seront immédiatement avisés. Le montant du remboursement sera porté au solde d’aide financière à l’éducation de l’employé.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.jobseeker.footerDescription": "Une fois la confirmation reçue, le chercheur d’emploi et les parties prenantes de l’entreprise seront immédiatement avisés. Le montant du remboursement sera porté au solde d’aide financière à l’éducation du chercheur d’emploi.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.member.footerDescription": "Une fois la confirmation reçue, le membre et les parties prenantes de l’entreprise seront immédiatement avisés. Le montant du remboursement sera porté au solde d’aide financière à l’éducation du membre.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledStatus.description.1": "Veuillez confirmer que", // Part 1: Description for cancelled status dialog. Ends with 'confirm that {activityTitle}'
  "confirmation-dialog.cancelledStatus.description.2": "a été annulé le {formattedCompletionDate}, et que {username} recevra un remboursement de {refundPct} %.", // Part 2: Description for cancelled status dialog
  "confirmation-dialog.completionStatus.title": "Confirmer l’état d’achèvement :", // Title of dialog for completion status changes
  "confirmation-dialog.confirm": "Confirmer", // Confirm contents in confirmation dialog
  "confirmation-dialog.content.approvedAmount": "Montant approuvé", // Key for the approved Amount
  "confirmation-dialog.content.cost": "Coût", // Key for program/course cost
  "confirmation-dialog.content.date": "Date", // Key for the date the request status changed, ignore-untranslated
  "confirmation-dialog.content.goal": "Programme prévu", // Key for activity request goal
  "confirmation-dialog.content.pending": "En attente", // Approved amount pending
  "confirmation-dialog.content.refundPct": "% de remboursement", // Key for refund percentage for cancelled or withdrawn courses/programs
  "confirmation-dialog.content.status": "État", // Key for request status
  "confirmation-dialog.deactivateUser.confirmButton": "Désactiver l'utilisateur", // text for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.deactivateUser.description": "Voulez-vous supprimer l'accès de <bold>{fullName}</bold> aux Paramètres Administratifs de Skillswave et rendre son utilisateur inactif ? Vous pouvez changer l'état de son utilisateur à tout moment en accédant aux dossiers des utilisateurs inactifs.", // text for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.deactivateUser.noUserNameLabel": "cet utilisateur", // text for confirmation dialog users with no name.
  "confirmation-dialog.deactivateUser.title": "Confirmation de désactivation de l'utilisateur", // header for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.declineRequest.button": "Refuser", // Primary button label to decline request
  "confirmation-dialog.declineRequest.description": "Veuillez confirmer que vous souhaitez rejeter la demande :", // Description for decline request dialog
  "confirmation-dialog.declineRequest.title": "Rejeter la demande", // Title for decline request dialog
  "confirmation-dialog.doNotShowAgain": "N’afficher cette information que lors de ma prochaine ouverture de session", // Label for checkbox to not show confirmation dialog again
  "confirmation-dialog.enrollmentDate.description": "Veuillez confirmer que {username} s’est inscrit à <bold>{activityTitle}</bold> le {formattedEnrollDate}.", // Description for dialog to confirm enrollment date registered for {activityTitle}
  "confirmation-dialog.enrollmentDate.title": "Confirmer la date d’inscription :", // Title for dialog to confirm enrollment date
  "confirmation-dialog.failedStatus.description": "Veuillez confirmer que {username} a échoué le <bold>{activityTitle}</bold> le {formattedCompletionDate}.", // Description for dialog to confirm failed status failed {activityTitle}
  "confirmation-dialog.failedStatus.employee.footerDescription": "Une fois la confirmation reçue, l’employé sera immédiatement avisé.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.jobseeker.footerDescription": "Une fois la confirmation reçue, le chercheur d’emploi sera immédiatement avisé.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.member.footerDescription": "Une fois la confirmation reçue, le membre sera immédiatement avisé.", // Footer for dialog to confirm failed status
  "confirmation-dialog.passedStatus.description.1": "Veuillez confirmer que {username} a réussi", // Part 1: Description for passed status dialog. Ends with 'passed {activityTitle},'
  "confirmation-dialog.passedStatus.description.2": "le {formattedCompletionDate}.", // Part 2: Description for passed status dialog
  "confirmation-dialog.passedStatus.employee.footerDescription": "Une fois la confirmation reçue, l’employé et les parties prenantes de l’entreprise seront immédiatement avisés.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.jobseeker.footerDescription": "Une fois la confirmation reçue, le chercheur d’emploi et les parties prenantes de l’entreprise seront immédiatement avisés.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.member.footerDescription": "Une fois la confirmation reçue, le membre et les parties prenantes de l’entreprise seront immédiatement avisés.", // Footer for dialog to confirm passed status
  "confirmation-dialog.removeRole.button-text.confirm": "Supprimer le rôle",
  "confirmation-dialog.removeRole.description.assigned": "Êtes-vous sûr de vouloir supprimer le rôle <bold>{roleName}</bold> ? Cela supprimera le rôle pour <bold>{usersWithRole} utilisateurs</bold>.",
  "confirmation-dialog.removeRole.description.assigned-and-orphaned": "Êtes-vous sûr de vouloir supprimer le rôle <bold>{roleName}</bold> ? Cela supprimera le rôle pour <bold>{usersWithRole} utilisateurs</bold> et laissera <bold>{usersWithNoRoleAfterDelete} utilisateurs</bold> sans aucun rôle attribué. Veuillez consulter l'onglet Permissions des utilisateurs pour leur attribuer les rôles nécessaires.",
  "confirmation-dialog.removeRole.description.unassigned": "Êtes-vous sûr de vouloir supprimer le rôle <bold>{roleName}</bold> ? Ce rôle n'est actuellement attribué à aucun utilisateur.",
  "confirmation-dialog.removeRole.title": "Confirmer la Suppression du Rôle",
  "confirmation-dialog.revoke.approver-joining": "et",
  "confirmation-dialog.revoke.approvers": "Approbateur(s)",
  "confirmation-dialog.revoke.cancelButton": "Annuler",
  "confirmation-dialog.revoke.confirmButton": "Oui, annuler",
  "confirmation-dialog.revoke.postContentMessage": "Si vous annulez votre demande, le ou les approbateurs en seront avertis. Ne vous inquiétez pas, vous pouvez envoyer une nouvelle demande à l’avenir.",
  "confirmation-dialog.revoke.title": "Annuler la demande",
  "confirmation-dialog.status.cancelled": "Annulé", // Cancelled application status
  "confirmation-dialog.status.fail": "Échec", // Failed application status
  "confirmation-dialog.status.pass": "Réussi", // Passed application status
  "confirmation-dialog.status.pending": "En attente", // Pending application status
  "confirmation-dialog.status.withdrawn": "A abandonné", // Withdrawn application status
  "confirmation-dialog.withdrawnStatus.description.1": "Veuillez confirmer que {username} a abandonné le cours", // Part 1: Description for withdrawn status dialog. Ends with 'withdrew from {activityTitle},'
  "confirmation-dialog.withdrawnStatus.description.2": "le {formattedCompletionDate}, et qu’il ou qu’elle recevra un remboursement de {refundPct} %.", // Part 2: Description for withdrawn status dialog

  "connect-account.backlink": "Retour à l’application", // Describing placeholder
  "connect-account.dialog.button.proceed": "Passer à l’étape 2", // placeholder
  "connect-account.dialog.description": "Une fois que vous avez créé un nouveau compte, revenez à SkillsWave et passez à l’étape 2.", // placeholder. Do not translate "SkillsWave".
  "connect-account.dialog.title": "Créer un nouveau compte", // Placeholder
  "connect-account.introduction": "Créez un compte avec {providerName} et liez-le à SkillsWave. Ne vous inquiétez pas, vous n’aurez qu’à le faire une seule fois avec {providerName}. Cela devrait prendre 10 minutes. Il faudra alors jusqu’à {registrationTime} jours ouvrables avant que {providerName} ne vous contact. ", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.button.create": "Créer un nouveau compte", // placeholder
  "connect-account.stepOne.button.skip": "J’ai déjà un compte", // placeholder
  "connect-account.stepOne.instructions.admissionsBased": "Des frais d’admission peuvent s’appliquer et seront facturés par l’établissement. Veuillez payer ces frais sur le site Web de l’établissement.",
  "connect-account.stepOne.instructions.header": "Instructions", // Describing placeholder, ignore-untranslated
  "connect-account.stepOne.instructions.one": "Créez un compte à l’aide de {userEmail}", // Describing placeholder
  "connect-account.stepOne.instructions.three": "Une fois que vous avez créé votre compte, revenez à SkillsWave et passez à l’étape 2.", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.instructions.two.employee": "Ne payez pas sur le site Web de l’établissement, sinon la couverture de votre employeur ne sera pas appliquée.", // Describing placeholder
  "connect-account.stepOne.instructions.two.jobseeker": "Ne payez pas sur le site Web de l’établissement, sinon la couverture de votre organisation ne sera pas appliquée.", // Describing placeholder
  "connect-account.stepOne.instructions.two.member": "Ne payez pas sur le site Web de l’établissement, sinon la couverture de votre association ne sera pas appliquée.", // Describing placeholder
  "connect-account.stepOne.summary": "Vous n’aurez à le faire que la première fois que vous vous inscrirez avec {providerName}", // Placeholder
  "connect-account.stepOne.support.link": "Communiquer avec le soutien", // placeholder
  "connect-account.stepOne.support.text": "Vous avez besoin d’aide?", // placeholder
  "connect-account.stepOne.title": "Étape 1 : Créer un compte avec {providerName}", // Describing placeholder

  "connect-account.stepThree.completion.done": "Terminé", // placeholder
  "connect-account.stepThree.completion.instructions.employee": "Votre employeur couvre les frais de formation. Cliquez sur « Terminé » pour finaliser la connexion de votre compte.{providerName} vous contactera sous peu.", // placeholder
  "connect-account.stepThree.completion.instructions.jobseeker": "Votre organisation couvre les frais de formation. Cliquez sur « Terminé » pour finaliser la connexion de votre compte.{providerName} vous contactera sous peu.", // placeholder
  "connect-account.stepThree.completion.instructions.member": "Votre association couvre les frais de formation. Cliquez sur « Terminé » pour finaliser la connexion de votre compte.{providerName} vous contactera sous peu.", // placeholder
  "connect-account.stepThree.completion.summary.employee": "Votre employeur couvre les frais de formation dans le cadre de l’activité : {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.jobseeker": "Votre organisation couvre les frais de formation dans le cadre de l’activité : {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.member": "Votre association couvre les frais de formation dans le cadre de l’activité : {activityType}.", // placeholder
  "connect-account.stepThree.completion.title": "Étape 3 : finalisez la connexion de votre compte.{providerName} vous contactera sous peu", // placeholder
  "connect-account.stepThree.payment.instructions": "Cliquez sur « Suivant » pour payer le montant impayé.", // placeholder
  "connect-account.stepThree.payment.next": "Suivant", // placeholder
  "connect-account.stepThree.payment.summary.employee": "Vous paierez toujours sur SkillsWave afin que votre couverture d’employeur puisse être appliquée (le cas échéant).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.jobseeker": "Vous paierez toujours sur SkillsWave afin que la couverture de votre organisation puisse être appliquée (le cas échéant).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.member": "Vous paierez toujours sur SkillsWave afin que la couverture de votre association puisse être appliquée (le cas échéant).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.title": "Étape 3 : payer sur SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepTwo.disableTooltip": "Veuillez entrer une adresse de courriel valide", // placeholder
  "connect-account.stepTwo.goNext": "Passer à l’étape 3", // placeholder
  "connect-account.stepTwo.other": "Autre", // placeholder
  "connect-account.stepTwo.question": "Quel courriel avez-vous utilisé pour créer votre compte avec {providerName}?", // placeholder
  "connect-account.stepTwo.studentID": "Quel est votre identifiant d'étudiant {providerName}? Votre identifiant ressemble à ceci : <bold>{idFormat}</bold>",
  "connect-account.stepTwo.summary": "Vous n’aurez à le faire que la première fois que vous vous inscrirez avec {providerName}.", // placeholder
  "connect-account.stepTwo.textInput.label": "Courriel", // placeholder
  "connect-account.stepTwo.textInput.studentID": "Identifiant d'étudiant",
  "connect-account.stepTwo.title": "Étape 2 : Lier votre compte {providerName} à SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.title": "Connectez votre compte", // Describing placeholder

  "contact-support.button": "Communiquer avec le service de soutien", // Label for form submission button
  "contact-support.description": "Notre équipe est là pour vous aider et répondre à vos questions.", // Description displayed under page title
  "contact-support.documentTitle": "Communiquer avec le service de soutien – SkillsWave", // Text which appears in the browser's page title for the Contact Support page. Do not translate "SkillsWave".
  "contact-support.error": "Champ(s) obligatoire(s) manquant(s)", // Error message if form field is missing
  "contact-support.form.email": "Votre adresse de courriel :", // Label for email address input field
  "contact-support.form.name": "Votre nom :", // Label for name input field
  "contact-support.form.questionType": "Type de question", // Label for question type dropdown
  "contact-support.form.questionType.academicContent": "J’ai une question sur le contenu d’un programme de formation",
  "contact-support.form.questionType.employerPolicy": "J’ai une question sur la politique de mon employeur",
  "contact-support.form.questionType.productFeedback": "Rétroaction sur le produit",
  "contact-support.form.questionType.technicalSupport": "J’éprouve un problème technique",
  "contact-support.form.textField": "Comment pouvons-nous vous aider?", // Label for text input field for user to enter support description
  "contact-support.thanksForMessage": "Merci pour votre message. Nous vous répondrons d’ici 2 à 3 jours ouvrables.", // Message displayed after support form is submitted
  "contact-support.title": "Communiquer avec le service de soutien", // Title of contact support page

  "countries.australia": "Australie", // Australia as option for employee location selection
  "countries.canada": "Canada", // Canada as option for employee location selection, ignore-untranslated
  "countries.other": "Autre", // Other as option for employee location selection
  "countries.united-states": "États-Unis", // United States as option for employee location selection

  "course.error.imagesMustBeHTTPS": "Les images du cours doivent être en format HTTPS", // error image isn't https
  "course.error.missingContentFormat": "Le cours doit avoir au moins un format de contenu", // error when course has 0 content formats
  "course.error.missingFeedbackType": "Le cours doit avoir au moins un type de rétroaction", // error when course has 0 feedback types
  "course.error.requiredFieldMissing": "Champ(s) obligatoire(s) manquant(s) pour le cours", // error when missing required fields

  "defaultFAQs.a1": "C’est simple : trouvez un cours ou un programme dans le catalogue et cliquez sur « Commencer à remplir une demande ». Remplissez le formulaire de demande et soumettez-le aux fins d’approbation. Une fois votre demande approuvée, vous recevrez un courriel vous demandant de terminer le processus d’inscription.", // Answer to Frequently Asked Question 1
  "defaultFAQs.a2.employee": "Tous les cours et les programmes sont admissibles à la facturation directe à votre employeur; cela signifie que vous ne paierez que les coûts qui excèdent la couverture de votre employeur. Une fois votre demande approuvée, vous serez en mesure de payer par carte de crédit tous les frais qui excèdent votre couverture pendant le processus d’inscription.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.jobseeker": "Tous les cours et les programmes sont admissibles à la facturation directe à votre organisation; cela signifie que vous ne paierez que les coûts qui excèdent la couverture de votre organisation. Une fois votre demande approuvée, vous serez en mesure de payer par carte de crédit tous les frais qui excèdent votre couverture pendant le processus d’inscription.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.member": "Tous les cours et les programmes sont admissibles à la facturation directe à votre association; cela signifie que vous ne paierez que les coûts qui excèdent la couverture de votre association. Une fois votre demande approuvée, vous serez en mesure de payer par carte de crédit tous les frais qui excèdent votre couverture pendant le processus d’inscription.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a3": "La plupart des cours peuvent être suivis individuellement, sans que vous deviez vous inscrire à un programme complet. Toutefois, il y a certaines exceptions. Vous ne serez pas en mesure de vous inscrire à un cours qui ne peut pas être suivi séparément du programme auquel il fait partie.", // Answer to Frequently Asked Question 3
  "defaultFAQs.a4.employee": "Si vous souhaitez suivre un cours auprès d’un fournisseur de formation qui ne figure pas dans le catalogue, communiquez avec votre gestionnaire pour savoir comment il peut être financé par votre employeur. De plus, nous serions ravis d’en être informé afin de pouvoir l’ajouter à notre catalogue! Soumettez le nom du cours et du prestataire de la formation par l’intermédiaire de l’agent conversationnel ou en remplissant le formulaire « Demande de soutien ».", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.jobseeker": "Si vous souhaitez suivre un cours auprès d’un fournisseur de formation qui ne figure pas dans le catalogue, communiquez avec votre gestionnaire pour savoir comment il peut être financé par votre organisation. De plus, nous serions ravis d’en être informé afin de pouvoir l’ajouter à notre catalogue! Soumettez le nom du cours et du prestataire de la formation par l’intermédiaire du chatbot ou en remplissant le formulaire « Demande de soutien ».", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.member": "Si vous souhaitez suivre un cours auprès d’un fournisseur de formation qui ne figure pas dans le catalogue, communiquez avec votre gestionnaire pour savoir comment il peut être financé par votre association. De plus, nous serions ravis d’en être informé afin de pouvoir l’ajouter à notre catalogue! Soumettez le nom du cours et du prestataire de la formation par l’intermédiaire du chatbot ou en remplissant le formulaire « Demande de soutien ».", // Answer to Frequently Asked Question 4
  "defaultFAQs.a5.employee": "Les demandes peuvent être annulées à la page Demandes. Lorsque vous cliquez sur « Annuler la demande », vous devez saisir une raison. Une fois la demande soumise, elle sera marquée comme annulée. Votre employeur ou le fournisseur de formation peut communiquer avec vous pour obtenir de plus amples renseignements.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.jobseeker": "Les demandes peuvent être annulées à la page Demandes. Lorsque vous cliquez sur « Annuler la demande », vous devez saisir une raison. Une fois la demande soumise, elle sera marquée comme annulée. Votre organisation ou le fournisseur de formation peut communiquer avec vous pour obtenir de plus amples renseignements.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.member": "Les demandes peuvent être annulées à la page Demandes. Lorsque vous cliquez sur « Annuler la demande », vous devez saisir une raison. Une fois la demande soumise, elle sera marquée comme annulée. Votre association ou le fournisseur de formation peut communiquer avec vous pour obtenir de plus amples renseignements.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a6": "Ne vous inquiétez pas! Votre demande de formation sera automatiquement annulée dans le système, et vous pourrez ensuite soumettre une demande pour un cours comparable. Sinon, si vous souhaitez savoir quand ce cours sera offert de nouveau, veuillez communiquer avec le service de soutien.", // Answer to Frequently Asked Question 6
  "defaultFAQs.a7": "Communiquez avec notre équipe de soutien en utilisant l’agent conversationnel dans le coin inférieur droit. Avec l’agent conversationnel, vous pouvez nous aviser de tout problème ou nous poser des questions en utilisant une série d’invites. Vous pouvez également utiliser le formulaire « Demande de soutien », qui est accessible à l’aide du lien figurant dans la partie inférieure de chaque page.", // Answer to Frequently Asked Question 7
  "defaultFAQs.a8": "Veuillez consulter la politique de confidentialité de SkillsWave disponible à l'adresse <privacy-link>https://skillswave.com/privacy-policy/</privacy-link>.", // Answer to Frequently Asked Question 8
  "defaultFAQs.q1": "Comment puis-je demander du financement pour suivre un cours ou un programme?", // Default Frequently Asked Question 1
  "defaultFAQs.q2": "Comment faire pour payer mon cours ou programme?", // Default Frequently Asked Question 2
  "defaultFAQs.q3": "Puis-je suivre un cours faisant partie d’un programme sans m’inscrire au programme complet?", // Default Frequently Asked Question 3
  "defaultFAQs.q4": "Je suis intéressé par un fournisseur de formation ou un cours qui ne figure pas dans le catalogue. Comment puis-je demander qu’on l’ajoute?", // Default Frequently Asked Question 4
  "defaultFAQs.q5": "J’ai fait une demande d’inscription pour un programme, mais j’ai changé d’idée. Comment puis-je annuler ma demande d’inscription à un cours ou à un programme?", // Default Frequently Asked Question 5
  "defaultFAQs.q6": "J’ai été approuvé pour un cours, mais le partenaire en éducation l’a annulé. Quelles sont mes options?", // Default Frequently Asked Question 6
  "defaultFAQs.q7": "Quelle est la marche à suivre pour obtenir du soutien technique ou pour signaler un problème?", // Default Frequently Asked Question 7
  "defaultFAQs.q8": "Comment SkillsWave traite-t-elle les données ?", // Default Frequently Asked Question 8

  "edit-activity.active.checkbox": "Actif", // Label for activity active switch
  "edit-activity.addCourse.label": "Ajouter un cours", // Text for button to add course to program
  "edit-activity.addCourses.prompt": "Quel cours aimeriez-vous ajouter au programme?", // Prompt for dropdown for selecting which course to add to the program
  "edit-activity.admissionBased.addInternationalRequirement": "Ajouter une exigence internationale",
  "edit-activity.admissionBased.addRequirement": "Ajouter une exigence",
  "edit-activity.admissionBased.checkbox": "Basé sur l’admission",
  "edit-activity.admissionBased.checkboxFlagUnchecked": "Toutes les exigences d'admission seront supprimées de cette activité après l'enregistrement.",
  "edit-activity.admissionBased.contactEmail": "Email de contact des admissions",
  "edit-activity.admissionBased.delay": "Délai (en semaines)",
  "edit-activity.admissionBased.emptyRequirementsSaveError": "Vous devez avoir au moins 1 condition d’admission",
  "edit-activity.admissionBased.internationalRequirementsCount": "exigence(s) internationale(s)",
  "edit-activity.admissionBased.internationalSummary": "Ces exigences s'appliquent uniquement aux apprenants qui n’ont pas un relevé de notes du secondaire d’une institution canadienne.",
  "edit-activity.admissionBased.internationalTitle": "Exigences pour le relevé de notes international",
  "edit-activity.admissionBased.label": "Paramètres basés sur l’admission",
  "edit-activity.admissionBased.potentialFee": "Frais potentiels",
  "edit-activity.admissionBased.requirementDeletionWarning": "Vous devez avoir au moins 1 exigence d'admission",
  "edit-activity.admissionBased.requirements": "Exigences",
  "edit-activity.admissionBased.requirementsAfterSave": "Attention - dès que vous enregistrez ces conditions, elles seront publiées sur la page de l’activité",
  "edit-activity.admissionBased.requirementsCount": "exigence(s)",
  "edit-activity.admissionBased.standardSummary": "Ces exigences s’appliquent à tous les apprenants, quel que soit leur lieu ou le type de relevé de notes.",
  "edit-activity.admissionBased.standardTitle": "Exigences d’admission",
  "edit-activity.admissionBased.title": "Titre",
  "edit-activity.allowRequesting.checkbox": "Autoriser l’envoi de demandes", // Label for activity requesting ability check box
  "edit-activity.bannerImageLink.label": "URL de l’image de la bannière", // Label for activity banner image URL input field
  "edit-activity.cancel.button": "Annuler", // Text for button to cancel adding a course to the activity
  "edit-activity.cardImageLink.label": "URL de l’image de la fiche", // Label for activity card image URL input field
  "edit-activity.category.label": "Piste de compétences", // Label for activity category dropdown
  "edit-activity.certificateType.label": "Type de certificat", // Label for activity certificate type dropdown
  "edit-activity.code.label": "Code", // Label for activity code input field
  "edit-activity.contentFormat.checkbox": "Format de contenu", // Label for course content format checkboxes section
  "edit-activity.core": "Obligatoire", // Text label for core course radio button
  "edit-activity.coreOrElective.label": "Obligatoire ou facultatif", // Label for radio buttons to select whether the course being added is required or an elective
  "edit-activity.courses": "Cours", // Activities of the type course
  "edit-activity.createActivity.button": "Créer une activité", // Text for button that creates an activity when clicked
  "edit-activity.credits.label": "Crédits", // Label for activity credits input field
  "edit-activity.delivery.label": "Livraison", // Label for activity delivery dropdown
  "edit-activity.description.label": "Description", // Label for activity description input field, ignore-untranslated
  "edit-activity.documentTitle": "Modifier l’activité – Admin – SkillsWave", // Document title when editing an activity. Do not translate "SkillsWave".
  "edit-activity.documentTitle.addActivity": "Ajouter une activité – Admin – SkillsWave", // Document title when creating a new activity. Do not translate "SkillsWave".
  "edit-activity.duration.label": "Durée", // Label for activity duration dropdown
  "edit-activity.elective": "Facultatif", // Text label for elective course radio button
  "edit-activity.error.selectAtLeastOneSkillStream": "Veuillez sélectionner au moins un domaine de compétence", // Error message for when no skill stream is selected
  "edit-activity.extractSkills.button": "Extraire les compétences de la description", // Text for extract skills button
  "edit-activity.extractSkills.pleaseWait": "Veuillez patienter pendant que les compétences sont extraites, cela peut prendre jusqu'à une minute.", // Text displayed while skills are being extracted
  "edit-activity.fastToComplete.checkbox": "Rapide à remplir", // Label to indicate if the associated course is fast to complete (think, less than a week, etc.)
  "edit-activity.feedbackTypes.checkbox": "Types de rétroaction", // Label for course feedback types checkboxes section
  "edit-activity.format.label": "Format", // Label for activity format dropdown, ignore-untranslated
  "edit-activity.inPerson.maxLearnerDistance": "Rayon de distance (en kilomètres)",
  "edit-activity.instructionLang.label": "Langue des instructions", // Label for activity instruction language dropdown
  "edit-activity.keyDifferentiator.label": "Principal élément de différenciation", // Label for activity key differentiator input field
  "edit-activity.numCoursesRequired.label": "Nombre de cours requis", // Label for activity required courses count input field
  "edit-activity.numberElectives.label": "Nombre de cours facultatifs", // Label for activity electives count input field
  "edit-activity.order.label": "Ordre", // Label for add course order input field
  "edit-activity.order.placeholder": "Aucun ordre", // Placeholder status indicator text for a course without an order
  "edit-activity.overallCost.label": "Coût global", // Label for activity overall cost input field
  "edit-activity.prerequisites.label": "Préalables", // Label for activity prerequisites input field
  "edit-activity.program.incorrectCoursesCount": "Le nombre de cours obligatoires est incorrect.", // Error message for incorrect number of required courses entered in activity edit/create
  "edit-activity.program.incorrectElectiveCoursesCount": "Le nombre de cours optionnels doit être inférieur ou égal au nombre d'options saisies.", // Error message for incorrect number of elective courses entered in activity edit/create
  "edit-activity.registrationLink.label": "Lien d’inscription", // Label for activity registration link input field
  "edit-activity.removeCourse": "Retirer le cours", // ariaLabel for button to remove course from program
  "edit-activity.removedSkills.header": "Compétences omises de l’extraction", // Header for removed skills section
  "edit-activity.save.button": "Enregistrer l’activité", // Text for button to save activity
  "edit-activity.saveAndClose.button": "Activité Enregistrer et fermer", // Text for button to save and close activity
  "edit-activity.select": "Sélectionner l’option", // Dropdown menu prompt
  "edit-activity.selectCourse": "Sélectionner un cours", // Dropdown menu prompt for adding a new course to the program
  "edit-activity.skillExtractionError": "Erreur lors de la tentative d’extraction des compétences à partir des données d’activité. L’activité n’a pas été mise à jour.", // The failure  message for when failing to extract skills
  "edit-activity.skills.extractedSkillsEmpty": "Soit aucune compétence n’a été extraite de la description, soit elle a été omise de l’extraction", // Displayed when there are no extracted skills or all are omitted
  "edit-activity.skills.header.extractedSkills": "Compétences extraites", // Header for extracted skills sub-section
  "edit-activity.skills.header.manageExtracted": "Gérer les compétences extraites", // Header for extracted skills management sub-section
  "edit-activity.skills.header.manageOtherSkills": "Gérer d’autres compétences", // Header for sub-section that lets people manage non-extracted skills
  "edit-activity.skills.header.omittedFromExtraction": "Compétences omises de l’extraction", // Header for the omitted extracted skills sub-section
  "edit-activity.skills.header.preview": "Aperçu des compétences liées à l’activité", // Header for sub-section that lets people preview skills as they would appear on a view-activity page
  "edit-activity.skills.omittedExtractedSkillsEmpty": "Aucune compétence n’a été omise de l’extraction", // Displayed when there are no extracted skills omitted
  "edit-activity.skills.previewEmpty": "Aucune compétence n’est visible pour cette activité", // Displayed when there are no visible skills associated with the activity

  "edit-activity.specialInstructions.label": "Instructions spéciales", // Label for activity special instructions input field
  "edit-activity.startDateType.anytime": "En tout temps", // The course/program starts anytime
  "edit-activity.startDateType.comingSoon": "Disponible bientôt", // The course/program start date is coming soon
  "edit-activity.startDateType.date": "Date", // The course/program starts on a specific date, ignore-untranslated
  "edit-activity.startDateType.unknown": "Inconnu", // The course/program start date is unknown
  "edit-activity.successfulSkillExtractionMessage": "Extraction réussie des compétences", // The success message for when the skills are successfully extracted
  "edit-activity.switch.tooltip.category": "Désactiver jusqu’à ce qu’un flux de compétences soit sélectionné", // Tooltip message for disabled switch - category issue
  "edit-activity.switch.tooltip.disabled": "Désactivez cette option, ouvrez la page de modification du cours pour résoudre le problème", // Tooltip message for disabled switch - general
  "edit-activity.switch.tooltip.inactive": "Ce cours n’a aucun programme actif", // Tooltip message for disabled switch - inactive parents
  "edit-activity.switch.tooltip.viewOnly": "Interrupteur désactivé. Une autorisation d'activation de l'activité est requise pour modifier le statut de l'activité", // Tooltip message for disabled switch - view only
  "edit-activity.tags.label": "Balises",
  "edit-activity.taxable.checkbox": "Imposable", // Label for whether an activity is taxable check box
  "edit-activity.timeCommitment.label": "Engagement en temps", // Label for activity time commitment dropdown
  "edit-activity.title": "Modifier l’activité", // Edit Activity page title
  "edit-activity.title.label": "Titre", // Label for activity title input field
  "edit-activity.type.label": "Type", // Label for activity type dropdown, ignore-untranslated
  "edit-activity.updateCourse.label": "Mettre à jour le cours", // Text for button to edit course added to program
  "edit-activity.weeklyTimeCommitment": "Engagement en temps chaque semaine", // Dropdown menu prompt for weekly time commitment
  "edit-activity.zeroDollarProgramActivity.checkbox": "Programme de déclaration à 0 $", // Label for the zero dollar program checkbox
  "edit-activity.zeroDollarProgramActivity.priceAndTagMismatch": "Ce cours est défini comme un programme de déclaration à 0 $, mais le coût n'est pas de 0.", // error message when $0 program flag is set but the activity has a non zero cost
  "edit-activity.zeroDollarProgramActivity.selected": "Programme à 0 $ sélectionné. Programme mis à jour pour permettre les demandes et coût changé de ${oldCost} à 0 $.", // Info message when $0 program flag is set and fields are auto updated

  "edit-permissions.permissions-table.activateUserRolesConfirmation.toast": "Utilisateur réactivé avec succès", // text for confirmation dialog on the reactivate dialog
  "edit-permissions.permissions-table.activateUserRolesConfirmationFail.toast": "La réactivation de l'utilisateur a échoué, veuillez réessayer", // text for confirmation dialog fail on the reactivate dialog
  "edit-permissions.permissions-table.deactivateUserRolesConfirmation.toast": "Utilisateur désactivé avec succès", // text for confirmation dialog on the deactivate dialog
  "edit-permissions.permissions-table.deactivateUserRolesConfirmationFail.toast": "La désactivation de l'utilisateur a échoué, veuillez réessayer", // text for confirmation dialog fail on the deactivate dialog
  "edit-permissions.permissions-table.filter.active": "Actif", // label for active filter on the User Permissions table.
  "edit-permissions.permissions-table.filter.inactive": "Inactif", // label for inactive filter on the User Permissions table.
  "edit-permissions.permissions-table.filter.introductoryText": "Filtrer par", // label for introductory text on the User Permissions table.
  "edit-permissions.permissions-table.filter.status": "Statut", // label for status filter on the User Permissions table.
  "edit-permissions.permissions-table.header.action": "Action", // ignore-untranslated
  "edit-permissions.permissions-table.header.editAdmin": "Modifier l'administrateur", // label for edit admin button on the User Permissions table.
  "edit-permissions.permissions-table.header.email": "Adresse e-mail", // label for email header on the User Permissions table.
  "edit-permissions.permissions-table.header.jobTitle": "Titre du poste", // label for job title header on the User Permissions table.
  "edit-permissions.permissions-table.header.name": "Nom", // label for name header on the User Permissions table.
  "edit-permissions.permissions-table.header.noRoles": "Aucun rôle associé à cet utilisateur", // no Roles text on the User Permissions table.
  "edit-permissions.permissions-table.header.permissions": "Attributions de Rôles", // label for header on the User Permissions table.
  "edit-permissions.permissions-table.header.status": "Statut", // label for status header on the User Permissions table,
  "edit-permissions.permissions-table.searchLabel": "Rechercher le nom d'utilisateur", // label for search input on the User Permissions table.
  "edit-permissions.roles": "Rôles", // Label for the Roles tab on the Permissions page.
  "edit-permissions.userPermissions": "Permisos de usuario", // Label for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.activeTab": "Utilisateurs Actifs", // Label for Active Users tab on the Permissions page.
  "edit-permissions.userPermissions.addUser": "Ajouter un utilisateur", // Label for the Add User button on the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.description": "Attribuez des rôles aux utilisateurs ici. Chaque utilisateur peut avoir plusieurs rôles associés, et ses autorisations seront cumulatives. Si aucun rôle n'est attribué à un utilisateur lorsqu'il se connecte à SkillsWave admin, il ne pourra ni voir ni modifier quoi que ce soit.", // Description for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.error.description": "Il n'y a aucun utilisateur à afficher", // Label for add users action on empty state illustration
  "edit-permissions.userPermissions.error.noUsers": "Aucun utilisateur", // Message displayed when there are no users in the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.inactiveTab": "Utilisateurs Inactifs", // Label for Active Users tab on the Permissions page.

  "edit-role.cancel": "Annuler",
  "edit-role.description": "Modifiez un rôle existant en ajustant les permissions et la description.",
  "edit-role.submit": "Mettre à jour le rôle",
  "edit-role.title": "Mettre à jour le rôle",

  "edit-roles.button.deleteRole": "Supprimer le Rôle",
  "edit-roles.button.editRole": "Modifier le Rôle",
  "edit-roles.create.success": "Rôle créé avec succès",
  "edit-roles.createRole": "Créer un rôle",
  "edit-roles.delete.success": "Rôle supprimé avec succès",
  "edit-roles.description": "Utilisez cette page pour créer et modifier des rôles en spécifiant les permissions selon la fonction de travail. Fournissez une description claire pour chaque rôle afin de clarifier les responsabilités. Vous pouvez attribuer plusieurs rôles à un seul utilisateur.",
  "edit-roles.table.action.delete": "Supprimer le Rôle",
  "edit-roles.table.action.edit": "Modifier le Rôle",
  "edit-roles.table.empty.description": "Créez un nouveau rôle pour commencer à configurer les permissions",
  "edit-roles.table.empty.title": "Aucun Rôle",
  "edit-roles.table.header.actions": "Actions", // ignore-untranslated
  "edit-roles.table.header.allocatedUsers": "Utilisateurs Attribués",
  "edit-roles.table.header.description": "Description", // ignore-untranslated
  "edit-roles.table.header.roleName": "Nom du Rôle",
  "edit-roles.title": "Rôles",
  "edit-roles.update.success": "Rôle mis à jour avec succès",

  "edit-tenant.documentTitle": "Modifier le locataire – Admin – SkillsWave", // Web page title when editing a tenant. Do not translate "SkillsWave".
  "edit-tenant.documentTitle.addTenant": "Ajouter un locataire – Admin – SkillsWave", // Web page title when creating a new tenant. Do not translate "SkillsWave".
  "edit-tenant.hybrid.disabled.tooltipText": "Le modèle d’approbation hybride ne peut pas être activé lorsque le budget cas par cas est sélectionné", // A tooltip description explaining why the radio button is disabled
  "edit-tenant.visibility.display.error": "Erreur : actuellement visible", // Error displaying an activity that should not be visible
  "edit-tenant.visibility.display.note": "Remarque : actuellement visible dans un programme différent", // Note activity is still visible for good reason
  "edit-tenant.visibility.display.warning": "Pour rendre ce cours visible, éteignez et rallumez le programme", // A course in program is not visible
  "edit-tenant.visibility.search.error": "Une erreur est survenue lors de la recherche. Veuillez réessayer après un moment", // Error occurred with search
  "edit-tenant.visibility.search.noMatch": "Aucun résultat trouvé. Veuillez sélectionner dans le menu déroulant ou taper le nom exact de l'activité", // No activity matches found for search criteria

  "emails.approval-requested.calloutText.1": "Veuillez noter que {name} n’a pas sélectionné son gestionnaire au moment de soumettre cette demande. Il est possible qu’il y ait un problème avec les données du gestionnaire dans le système.", // First sentence of a notice when the applicant selects "Not my manager"
  "emails.approval-requested.calloutText.link": "Si c’est le cas, veuillez <link>communiquer avec nous</link> pour obtenir de l’aide.", // Last sentence in the notice, reminding the reader to contact support if there is a problem. Please do NOT translate <link> tags
  "emails.approval-requested.customContent.1": "{name} aimerait s’inscrire à {title} offert par {provider}.", // First sentence of the request application approval email
  "emails.approval-requested.customContent.2": "Veuillez passer rapidement en revue la demande afin que {name} ait le temps de s’inscrire.", // Second sentence in the email
  "emails.approval-requested.external.customContent.1": "{learnerName} souhaite obtenir une approbation pour {activityTitle}.", // A learner would like approval from their boss to expense or participate in an external training opportunity
  "emails.approval-requested.external.customContent.2": "Veuillez examiner la demande rapidement.", // Prompting the approving manager to review the request quickly
  "emails.approval-requested.headerOptions.text": "Votre approbation a été demandée", // Email main title header
  "emails.approval-requested.subject": "Approbation requise : veuillez passer en revue la demande de formation de {name}", // Subject line of approval requested email

  "emails.buttons.view-request.text": "Afficher la demande", // Text for the main button in the email

  "emails.cancelled-withdrawn.customContent.apology.employee": "Nous sommes vraiment désolés pour ces inconvénients. <link>Découvrez d’autres offres prises en charge par votre employeur</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.jobseeker": "Nous sommes vraiment désolés pour ces inconvénients. <link>Découvrez d’autres offres prises en charge par votre organisation</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.member": "Nous sommes vraiment désolés pour ces inconvénients. <link>Découvrez d’autres offres prises en charge par votre association</link>.", // Apology for the cancellation and link to other offerings/activities, if tenant is an association
  "emails.cancelled-withdrawn.customContent.expenseContact": "Si vous avez déjà porté le coût de ce cours ou de ce programme au compte de de votre entreprise, veuillez communiquer avec <link>{programSponsorName}</link> pour les prochaines étapes.", // Telling the learner to contact their sponsor/manager if they've already expensed the cost of the activity with their company. Do not translate the <link> tags
  "emails.cancelled-withdrawn.customContent.refund.integrated": "Si vous avez déjà procédé au paiement, un remboursement de {refundPercent, number, percent} sera appliqué à votre mode de paiement initial.", // Notifying the learner the percentage that will be refunded to them
  "emails.cancelled-withdrawn.customContent.reimbursement": "Si un remboursement a déjà été accordé pour ce cours ou ce programme, veuillez prendre les mesures nécessaires selon les politiques de votre entreprise, ou communiquer avec <link>{programSponsorName}</link>.", // Reminding the learner to take any necessary next steps after being reimbursed

  "emails.failed-payment.button.text": "Mise à jour ici",
  "emails.failed-payment.customContent.action": "Veuillez mettre à jour votre mode de paiement pour permettre à l'employé de s'inscrire au cours.",
  "emails.failed-payment.customContent.failedPayment": "Un employé n'a pas pu démarrer le processus d'inscription car nous n'avons pas pu facturer le mode de paiement enregistré.",
  "emails.failed-payment.customContent.greeting": "Bonjour,",
  "emails.failed-payment.header.text": "Votre mode de paiement enregistré n'a pas pu être débité",
  "emails.failed-payment.subject": "Votre mode de paiement enregistré n'a pas pu être débité",

  "emails.invite-requested.button.text": "Se connecter maintenant",
  "emails.invite-requested.customContent.greeting": "Salut,",
  "emails.invite-requested.customContent.loginRequest.1": "Bonne nouvelle ! {inviterName} chez {tenantName} vous a donné accès à SkillsWave. 🎉",
  "emails.invite-requested.customContent.loginRequest.2": "SkillsWave vous donne accès à des milliers de cours et de programmes pour vous aider à développer vos compétences à votre propre rythme.",
  "emails.invite-requested.customContent.loginRequest.3": "Prêt à vous lancer ?",
  "emails.invite-requested.header.text": "Votre compte SkillsWave est prêt",
  "emails.invite-requested.subject": "Vous avez été invité à SkillsWave !",

  "emails.login-requested.button.text": "Ouvrir une session maintenant", // Text for the main button in the email, logs user in to Wave app when clicked
  "emails.login-requested.customContent.greeting": "Bonjour,", // Greeting to email recipient at top of email
  "emails.login-requested.customContent.loginRequest": "Votre demande d’ouverture de session à SkillsWave avec {requesterEmail} a été traitée!", // Sentence in body of email, stating that the user's request to log in to SkillsWave has been completed through the receipt of this email. Do not translate "SkillsWave".
  "emails.login-requested.header.text": "Votre compte SkillsWave est prêt", // Email main title header stating that login access/an account is ready. Do not translate "SkillsWave".
  "emails.login-requested.subject": "Votre compte SkillsWave est prêt", // Subject line of email stating that login access/an account is ready. Do not translate "SkillsWave".

  "emails.monthly-invoice.customContent.1": "Le total de vos transactions pour {month} {year} s’élève à {salesAmount}.", // Mentioning the total sales transaction that occurred during {month} {year}
  "emails.monthly-invoice.customContent.2": "<bold>Payable à {providerName}</bold>", // Payable to provider
  "emails.monthly-invoice.customContent.3": "Nous avons émis {salesInvoiceCount, plural, one {a} other {{salesInvoiceCount}}} {salesInvoiceCount, plural, one {facture} other {factures}} <boldUnderline>annulée(s)</boldUnderline> afin que vous puissiez consulter les détails de vos transactions. Vous avez 60 jours pour télécharger votre ou vos facture(s) :", // Notifying the provider to save their invoice
  "emails.monthly-invoice.customContent.4": "Veuillez enregistrer le rapport pour vos dossiers. N’EFFECTUEZ PAS LE PAIEMENT DE {salesInvoiceCount, plural, one {CETTE FACTURE} other {CES FACTURES}}. Veuillez plutôt émettre une facture de {salesAmount} à {d2lFinanceEmail}, qui comprend le code de référence « <bold>SKILLSWAVE {salesReferenceInvoiceNumber}</bold> » pour recevoir votre paiement.", // Notifying the provider to issue it to SkillsWave for a payout. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.5": "<bold>Payable à SkillsWave</bold>", // Payable to SkillsWave
  "emails.monthly-invoice.customContent.6": "Conformément au nombre total de transactions pour {month} {year}, nous avons émis {revShareInvoiceCount, plural, one {facture de revenus partagés} other {{revShareInvoiceCount} factures de revenus partagés}} d’un total de {revShareAmount}, qui seront disponibles à des fins de téléchargement pendant 60 jours :", // Notifying the provider to process the revenue share invoice for payment to SkillsWave.
  "emails.monthly-invoice.customContent.7": "Veuillez payer cette facture avant la date d’échéance en indiquant le numéro de référence « <bold>SKILLSWAVE {revShareReferenceInvoiceNumber}</bold> ».", // Notifying the provider to process the revenue share invoice for payment to SkillsWave. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.8": "<bold>Rappel : </bold> vous avez 5 jours ouvrables pour contester une transaction à {d2lFinanceEmail}.", // Reminding the provider to dispute any transactions to SkillsWave's finance team if any
  "emails.monthly-invoice.header.text": "Votre rapport mensuel des transactions est prêt à être examiné", // Email main title header
  "emails.monthly-invoice.negativeInvoiceInfo": "Si le montant de votre rapport de facturation est négatif, le crédit sera appliqué au rapport de facturation du mois suivant.", // Explains our use of negative invoice items to the tenant
  "emails.monthly-invoice.number": "Numéro de facture: {invoiceNumber}", // Text that goes before the invoice number in the email
  "emails.monthly-invoice.subject": "[Nouveau rapport de transactions SkillsWave] {month} {year} prêt à être examiné", // Email subject line. Do not translate "SkillsWave".

  "emails.register-learner.customContent.discountCode": "Veuillez fournir un code pour que cet apprenant puisse s’inscrire au programme.", // Request to academic admin to provide a discount/rebate code to the learner
  "emails.register-learner.customContent.learnerApproved": "{learnerName} a été approuvé par {employerName} pour {activityTitle} avec une couverture maximale de {approvedAmount}.", // Notifying the academic admin that a new learner has been approved by their employer, and is covered up to a certain currency value
  "emails.register-learner.header.text.codeRequested": "Code SkillsWave demandé", // Main header in the email, notifying the academic admin that a discount/rebate code has been requested. Do not translate "SkillsWave".
  "emails.register-learner.header.text.newLearner": "Nouvel apprenant SkillsWave", // Main header in the email, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".
  "emails.register-learner.subject": "Nouvel apprenant SkillsWave", // Email subject line, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".

  "emails.registration-request.customContent.registrationInstruction": "Utilisez le courriel ci-dessus pour trouver {learnerName} dans votre système d’inscription.", // Instruction to reader to use a user's email (written above this sentence) to find the user in their registration system
  "emails.registration-request.headerOptions.text": "Vous avez une nouvelle demande d’inscription", // Main header title of the email, indicating that the academic institution has a new request from a user to register for a program or course
  "emails.request-approved.account-connect.follow-steps": " Suivez les étapes ci-dessous pour créer votre compte {providerName} et soumettre votre inscription avant le début de votre {activityType}. Nous vous recommandons de le faire rapidement, car les places sont limitées dans certains cours de {activityType}.",
  "emails.request-approved.account-connect.step-1.body": "Créez un compte avec {providerName} pour qu’il puisse vous inscrire à {activityTitle}.",
  "emails.request-approved.account-connect.step-1.button": "Créer un compte",
  "emails.request-approved.account-connect.step-1.header": "Étape 1 : Créez votre compte étudiant",
  "emails.request-approved.account-connect.step-2.body": "Après avoir créé votre compte avec {providerName}, connectez votre compte à votre courriel et soumettez votre inscription.",
  "emails.request-approved.account-connect.step-2.button": "Soumettre l’inscription",
  "emails.request-approved.account-connect.step-2.header": "Étape 2 : Soumettez votre inscription",
  "emails.request-approved.button.text.reimbursement": "Soumettre l’nscription", // Text for the main button in the email, view request and register for course/program
  "emails.request-approved.customContent.approvalMessage": "Le validateur a également laissé un commentaire: {approvalReason}",
  "emails.request-approved.customContent.approvedCost": "{employerName} assumera {approvedAmount} pour votre inscription à {activityTitle} offert par {providerName}. Votre solde à payer est de {employeeCost}.", // Notifying the requester of the amount that has been approved and the remaining cost
  "emails.request-approved.customContent.approvedCost.anytime": "{employerName} paiera {approvedAmount} pour que vous puissiez suivre {activityTitle} auprès de {providerName}. Votre coût restant est de {employeeCost}, et vous pouvez commencer le cours à tout moment.", // Notifying the requester of the amount that has been approved and the remaining cost for a course/program that starts anytime
  "emails.request-approved.customContent.approvedCost.zeroApproved": "Votre demande d’inscription à {activityTitle} auprès de {providerName} a été approuvée. Votre prix est de {employeeCost}. Accédez à votre demande pour poursuivre le processus d’inscription. Nous vous recommandons de le faire rapidement, car les places sont limitées dans certains cours de {activityType}.",
  "emails.request-approved.customContent.approvedCost.zeroApproved.ZeroCost": "Votre demande d’inscription à {activityTitle} auprès de {providerName} a été approuvée. Accédez à votre demande pour poursuivre le processus d’inscription. Nous vous recommandons de le faire rapidement, car les places sont limitées dans certains cours de {activityType}",
  "emails.request-approved.customContent.cancellationNote": "Les cours et les programmes peuvent être annulés. En cas d’annulation, vous serez immédiatement avisé.", // Warning that some courses are subject to cancellation
  "emails.request-approved.customContent.multipleApprovalMessages": "Deux approbateurs ont laissé des commentaires, consultez votre demande pour les lire.",
  "emails.request-approved.customContent.registerSoon": "Ne manquez pas la date d’inscription. {activityTitle} commence bientôt.", // Reminder that that the course/program is starting soon
  "emails.request-approved.customContent.registerToday": "Inscrivez-vous dès aujourd’hui pour réserver votre place, car certains cours ont une capacité limitée.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.customContent.registrationDate": "{activityTitle} commence le {activityStartDate}.", // Reminder to register before the start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-approved.customContent.submitRegistration": "<bold>Soumettez votre inscription</bold> avant le début de {activityType}. Nous vous recommandons de le faire rapidement, car les places sont limitées dans certains cours de {activityType}.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.external.customContent.approvedCost": "{employerName} remboursera {approvedAmount} du {activityCost} demandé pour {activityTitle}. Pour demander votre subvention d’employé, payez les frais comme indiqué dans la <link>politique de votre employeur</link>.", // The applicant's company will reimburse them for the cost of the requested activity, they will then expense the approved amount
  "emails.request-approved.external.customContent.registrationDate": "{activityTitle} commence le {activityStartDate}.", // Reminder about the activity's start date. Start date takes the format of this example: June 30, 2030
  "emails.request-approved.header.above.noName": "Bonne nouvelle,", // Email main title header
  "emails.request-approved.header.above.withName": "Bonne nouvelle {name},", // Email main title header with name
  "emails.request-approved.header.text2": "Soumettons votre inscription", // The email recipient's request has been approved
  "emails.request-approved.subject": "Votre demande d’apprentissage a été acceptée", // The email recipient's request has been approved
  "emails.request-approved-activity-cost-changed.customContent.action": "Nous vous recommandons de demander l'approbation du nouveau prix. Pour cela, annulez cette demande et soumettez-en une nouvelle.", // Action a user can take
  "emails.request-approved-activity-cost-changed.customContent.priceChange": "Le prix de {activityType} que vous avez demandé, {activityTitle}, a {changeType} de {oldCost} à {newCost}.", // Price for an activty type/title has decreased/increased
  "emails.request-approved-activity-cost-changed.decreased": "diminué",
  "emails.request-approved-activity-cost-changed.header": "Le prix de {activityTitle} a été modifié", // Email header
  "emails.request-approved-activity-cost-changed.increased": "augmenté",
  "emails.request-approved-activity-cost-changed.subject": "Une mise à jour du prix de votre demande", // Email subject

  "emails.request-cancelled.button.text": "Afficher les détails", // Text for the main button in the email, navigates user to their request page
  "emails.request-cancelled.customContent.cancelReason": "{providerName} a annulé {activityTitle} le {cancellationDate}. La raison suivante a été fournie :", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled.header.above.apologyNoName": "Désolé,", // Email main header, if there is no learner name available
  "emails.request-cancelled.header.above.apologyWithName": "Désolé {learnerFirstName},", // Email main title header, if there is a learner name
  "emails.request-cancelled.header.text": "Le programme auquel vous vous êtes inscrit a été annulé", // Email main title header
  "emails.request-cancelled.subject": "Mise à jour SkillsWave : le programme auquel {learnerName} s’est inscrit a été annulé", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee.button.text": "Afficher la demande", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.apology": "Nous sommes désolés pour ces inconvénients. Découvrez d’autres offres prises en charge par votre employeur.", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.customContent.cancelReason": "Vous avez annulé votre demande pour {activityTitle}, à compter du {cancellationDate}. Vous avez fourni la raison suivante :", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.mistake": "S’il s’agit d’une erreur, <link>communiquez avec le service de soutien de SkillsWave</link>", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.header.text": "Votre demande a été annulée", // Email main header
  "emails.request-cancelled-by-employee.subject": "Mise à jour SkillsWave : vous avez annulé votre demande", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee-to-approver.customContent.cancelReason": "Nous vous informons que {learnerName} a annulé sa demande pour {activityTitle}, qui sera en vigueur à compter du {cancellationDate}. La raison suivante a été fournie :", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee-to-approver.customContent.noAction": "Aucune action n’est requise.", // The line of text informing the approver that they don't have to take any action for this email
  "emails.request-cancelled-by-employee-to-approver.subject": "Mise à jour SkillsWave : {learnerName} a annulé sa demande", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-failed.customContent.continuedSupport.employee": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre employeur</link>.", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.jobseeker": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre organisation.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.member": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre association</link>.", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by the reader's association. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.sorry": "Nous sommes vraiment désolés pour ces inconvénients.", // Sentence expressing sympathy for the learner because their program was unsuccessful
  "emails.request-failed.customContent.thankYou": "Merci d’utiliser SkillsWave. Nous espérons que vous avez trouvé {activityTitle} utile pour votre propre perfectionnement professionnel.", // Sentence expressing gratitude to the learner for using "Wave". Do not translate "SkillsWave".
  "emails.request-failed.header.above.greetingNoName": "Bonjour,", // Email main title header, greetings to learner, if there is  no first name available
  "emails.request-failed.header.above.greetingWithName": "Bonjour {learnerFirstName},", // Email main title header, greetings to learner, if there is a learner name
  "emails.request-failed.subject": "Mise à jour SkillsWave : votre statut de programme a été mis à jour et indique que vous avez échoué.", // Subject line of email that is sent to the applicant after their request has been marked as "failed". Do not translate "SkillsWave".

  "emails.request-paid.customContent.contact": "Personne ressource", // Label in the email table for learner's email
  "emails.request-paid.customContent.name": "Nom", // Label in the email table for learner's name
  "emails.request-paid.customContent.payment": "Paiement", // Label in the email table for activity payment status
  "emails.request-paid.customContent.payment.text": "Payé {cost}", //  Value format in the email table for activity payment status
  "emails.request-paid.headerOptions.below.text": "Une fois que l’inscription est ouverte, inscrivez cet apprenant dans les {registrationTime} jours ouvrables", // Main title subtext for email to university admin
  "emails.request-paid.headerOptions.text": "Vous avez une nouvelle demande", // Main title header for email to university admin
  "emails.request-paid.subject": "[Nouvel apprenant SkillsWave] Inscrire {name} à la formation {activityTitle}", // Subject line for email to university admin. Do not translate "SkillsWave".

  "emails.request-passed.button.text": "Afficher les détails", // Text for the main button in the email, navigates user to their request page
  "emails.request-passed.customContent.continuedSupport.employee": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre employeur</link>.", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.jobseeker": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre entreprise.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.member": "Nous aimerions continuer de vous soutenir dans votre cheminement éducatif. <link>Découvrez d’autres offres prises en charge par votre association</link>.", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by an association. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.skillsDeveloped": "Avec ce programme, vous avez acquis des compétences en {numberOfSkills, select, greaterThanThree {{0}, {1}, {2}, {3} et autres} two {{0} and {1}} three {{0}, {1}, and {2}} other {{0}}}.", // Sentence describing the skills that the learner developed by taking the program. A nested select is used to differentiate between the phrases when listing the skills in placeholders, as the number of skills can vary from 1 - 4+.
  "emails.request-passed.customContent.skillsDeveloped.list": "{numberOfSkills, select, greaterThanThree {{0}, {1}, {2}, {3} et autres} two {{0} et {1}} three {{0}, {1}, et {2}} other {{0}}}",
  "emails.request-passed.customContent.successful": "Vous avez réussi {activityTitle} offert par {providerName}.", // Sentence describing the successful completion of the program from the academic provider/institution
  "emails.request-passed.header.above.congratulationNoName": "Félicitations,", // Email main title header, congratulations to learner on completing their program, if there no first name available
  "emails.request-passed.header.above.congratulationWithName": "Félicitations {learnerFirstName},", // Email main title header, congratulations to learner on completing their program, if there is a learner name
  "emails.request-passed.header.text": "Vous avez terminé la formation {activityTitle}!", // Email title header, stating that the learner has successfully completed a program/course
  "emails.request-passed.subject": "Mise à jour SkillsWave : {learnerName} a réussi la formation {activityTitle}", // Subject line of email that is sent to the applicant after their request has been successfully completed. Do not translate "SkillsWave".

  "emails.request-received.customContent.1": "Votre demande d’inscription à {title} offert par {providerName} a été reçue.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.customContent.2": "Elle a été transmise à votre employeur aux fins d’examen.", // Second sentence in the email
  "emails.request-received.customContent.2.association": "Elle a été transmise à votre association aux fins d’examen.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.customContent.3.association": "Si votre demande est approuvée, nous vous indiquerons le montant que votre association couvrira pour {courseType}.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.external.customContent.1": "Votre demande concernant la formation {activityTitle} a été reçue.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.external.customContent.3": "Si votre demande est approuvée, nous vous indiquerons le montant que votre employeur couvrira.", // Part of last sentence of the email that gets sent to the applicant for an external request
  "emails.request-received.headerOptions.text": "Votre demande d’apprentissage a été reçue", // Email main title header
  "emails.request-received.subject": "Votre demande d’apprentissage a été reçue", // Subject line of email that is sent to the applicant after their request has been received

  "emails.request-registered.customContent.congratulations": "Félicitations! Votre inscription à {activityTitle} a été confirmée par {providerName}. Des informations sur {activityType} vous seront communiquées à cette adresse de courriel.", // Notifying the requester of the confirmed registration and the activity start date
  "emails.request-registered.customContent.startDate": "Votre {activityType} commence le {activityStartDate}.", // Reminder of the activity's start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-registered.customContent.startSoon": "Votre {activityType} commence bientôt.", // Reminder that that the course/program is starting soon
  "emails.request-registered.customContent.viewRequest": "Consultez votre demande pour en savoir plus.", // Sentence to encourage recipient to view their request
  "emails.request-registered.header.text": "{providerName} a confirmé votre inscription", // Email title header, stating that the provider has confirmed the recipient's registration
  "emails.request-registered.subject": "{providerName} vous a inscrit à {activityTitle}", // The email recipient's request has been registered

  "emails.request-rejected.customContent.declinedReason": "La demande a été refusée pour la raison suivante : {declinedReason}", // Reason why the approval request was declined
  "emails.request-rejected.customContent.toApprover": "La demande de {learnerName} de s’inscrire à {activityTitle} a été refusée par {declinerName}.", // Sentence in email that is sent to the approver
  "emails.request-rejected.customContent.toRequester": "Désolé {learnerFirstName}, votre demande d’inscription à {activityTitle} a été refusée.", // Sentence in email that is sent to the requester/learner
  "emails.request-rejected.headerOptions.text.toApprover": "La demande d’apprentissage de {learnerName} a été refusée", // Email main title header
  "emails.request-rejected.headerOptions.text.toRequester": "Votre demande d’apprentissage a été refusée", // Email main title header
  "emails.request-rejected.subject.toApprover": "La demande de {learnerName} a été refusée", // Subject line of email that is sent to the applicant after their request has been rejected
  "emails.request-rejected.subject.toRequester": "Votre demande d’apprentissage a été refusée", // Subject line of email that is sent to the applicant after their request has been rejected

  "emails.request-withdrawn.button.text": "Afficher les détails", // Text for the main button in the email, navigates user to their request page
  "emails.request-withdrawn.customContent.withdrawal": "{providerName} nous a indiqué qu’en date du {cancellationDate}, vous vous êtes retiré de {activityTitle}.", // Sentence describing the details of a learner's withdrawal from a course/program.
  "emails.request-withdrawn.header.above.apologyNoName": "Nous sommes désolés de vous voir partir,", // Email main header, if there is no learner name available. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.above.apologyWithName": "Nous sommes désolés de vous voir partir {learnerFirstName},", // Email main title header, if there is a learner name. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.text": "Vous vous êtes retiré du programme auquel vous vous étiez inscrit", // Email main title header
  "emails.request-withdrawn.subject": "Mise à jour SkillsWave : {learnerName} s’est retiré(e) d’un programme", // Subject line of email that is sent to the applicant after they have withdrawn from a course/program. Do not translate "SkillsWave".

  "emails.suggest.course.alternativeAction.text": "Vous ne pouvez pas accéder à ce cours ? Essayez ce lien", // text for the alternative action link
  "emails.suggest.course.buttons.text": "Afficher le cours", // text for the action button
  "emails.suggest.course.headerOptions.text": "vous a suggéré un cours", // Email main title header
  "emails.suggest.course.subject": "{firstName} vous a suggéré un cours", // Subject line of email that is sent to the recipient when a user suggests a course for them.
  "emails.suggest.program.alternativeAction.text": "Vous ne pouvez pas accéder à ce programme? Essayez ce lien", // text for the alternative action link
  "emails.suggest.program.buttons.text": "Afficher le programme", // text for the action button
  "emails.suggest.program.headerOptions.text": "vous a suggéré un programme", // Email main title header
  "emails.suggest.program.subject": "{firstName} vous a suggéré un programme", // Subject line of email that is sent to the recipient when a user suggests a program for them.

  "emails.zero-dollar-request-registered.button.text": "Afficher le programme",
  "emails.zero-dollar-request-registered.customContent.congratulations": "Vous êtes désormais officiellement inscrit au programme {program} avec {provider}. Pour commencer votre parcours d'apprentissage, rendez-vous sur la page du programme et consultez le plan du programme. Sélectionnez le cours qui vous intéresse et soumettez votre demande. Une fois approuvée, suivez les instructions pour vous inscrire au cours avec {provider}.",
  "emails.zero-dollar-request-registered.customContent.note": "<bold>Note:</bold> Vous devrez répéter ce processus pour chaque cours du programme. Pour chaque cours, visitez la page du programme, sélectionnez le cours, demandez l'approbation, puis inscrivez-vous en conséquence.",
  "emails.zero-dollar-request-registered.header.text": "Félicitations {learnerFirstName}!",
  "emails.zero-dollar-request-registered.header.text.noName": "Félicitations!",
  "emails.zero-dollar-request-registered.subject": "{providerName} t'a inscrit en {activityTitle}",

  "error.403": "Oups, vous avez besoin d'un accès!", // Error text when a client error 403 occurs (forbidden)
  "error.403.description": "Vous n'avez pas encore accès à cette page. Contactez votre responsable pour obtenir les bonnes autorisations.", // Description of the error text when a client error 403 occurs (forbidden)
  "error.404": "404 – Introuvable", // Error text when a client error 404 occurs (page not found)

  "error.LOGIN_001": "Votre compte est inactif. Veuillez contacter votre administrateur pour obtenir de l'aide.",
  "error.LOGIN_002": "Adresse e-mail invalide",
  "error.LOGIN_003": "Domaine non configuré pour l'authentification",
  "error.LOGIN_004": "Erreur lors de la vérification du lien de connexion",
  "error.LOGIN_005": "Le lien de connexion a déjà été utilisé. Veuillez en demander un nouveau.",
  "error.LOGIN_006": "Le lien de connexion a expiré. Veuillez en demander un nouveau.",
  "error.LOGIN_007": "Incohérence de cookie. Veuillez demander un nouveau lien de connexion.",
  "error.LOGIN_008": "Erreur de connexion",
  "error.LOGIN_009": "Erreur générale lors de la création du lien magique",

  "error.error-loading": "Une erreur s’est produite lors de la tentative de chargement de SkillsWave", // Error text when there was a problem loading Wave. Do not translate "SkillsWave".
  "error.invalid-attribute": "L’utilisateur ne répond pas aux exigences d’attribut pour {attributeName}",
  "error.maintenanceMode": "Oh non! Nous effectuons actuellement une maintenance. Notre équipe travaille dur pour améliorer votre expérience. Nous serons de retour bientôt — merci pour votre patience!", // Error text when the app is in maintenance mode

  "faqs.title": "Questions fréquemment posées", // Title for FAQ page

  "form.submit": "Soumettre", // Submit button for forms

  "general.addActivity": "Ajouter une activité", // Label for adding new activity button and page title.
  "general.admin": "Administrateur", // Navigation link to Wave administration experience (privileged user only), entitlement for university admins, heading for admin list.

  "general.button-text.apply": "Appliquer", // The label for apply buttons
  "general.button-text.cancel": "Annuler", // The label for cancel buttons
  "general.button-text.collapseAll": "Tout réduire", // Label for an action that collapses collapsible interactive elements
  "general.button-text.expandAll": "Tout développer", // Label for an action that expands all collapsible interactive elements
  "general.button-text.export": "Exporter", // The label for export buttons
  "general.button-text.finish": "Terminer",
  "general.button-text.next": "Prochain",
  "general.button-text.no": "Non", // The label for yes buttons
  "general.button-text.previous": "Retourner",
  "general.button-text.save": "Enregistrer", // The label for apply buttons
  "general.button-text.yes": "Oui", // The label for yes buttons

  "general.catalog-package.construction": "Construction", // ignore-untranslated
  "general.catalog-package.custom-health-sciences": "Personnalisé – Sciences de la santé",
  "general.catalog-package.custom-upskill-canada": "Personnalisé – Rehaussez vos compétences Canada",
  "general.catalog-package.general": "Général",
  "general.catalog-package.new-general": "Nouvelle généralité",
  "general.catalog-package.tech": "Développement",

  "general.costTo.companyName": "Coût pour {companyName}", // The label for the cost to the company
  "general.employee": "Employé(e)",
  "general.employer": "Employeur", // Text for employer filter, aria text and label for program employer filter dropdown, employer option for tenant type selection dropdown.
  "general.enabled": "Activé", // Text for things like toggles or labels that would indicate that a setting is enabled
  "general.error": "Un problème est survenu", // Error message that appears when something goes wrong
  "general.errorMessage.preAuthFailed": "Nous n'avons pas pu facturer votre employeur pour cette activité. Veuillez contacter votre administrateur pour résoudre ce problème.",
  "general.export.complete": "Exportation complète.",
  "general.export.error": "Quelque chose s'est mal passé. Veuillez réessayer.",
  "general.export.processing": "Traitement des exportations",
  "general.jobseeker": "Chercheur d’emploi",
  "general.language": "Langue", // Just the word 'language' for labeling things like language selectors or language settings
  "general.language.english": "Anglais",
  "general.language.french": "Français",
  "general.loading": "Téléchargement en cours...",
  "general.login": "Ouvrir une session",
  "general.member": "Membre",
  "general.provider": "Fournisseur", // Aria text and label for program provider filter dropdown, provider option for tenant type selection dropdown, label for activity provider dropdown, label for course provider, label for provider in submit own request overview, heading for the provider name column.
  "general.recommended": "Recommandé", // Text for recommended items
  "general.signup": "S’inscrire",
  "general.skills": "personnelles", // Label for Skills tab, header for skills section.

  "general.stream.DEIdiversityInclusionAccessibility": "IDEA : Inclusion, diversité, équité et accessibilité",
  "general.stream.accounting": "Comptabilité : finances, vérification et gestion du risque",
  "general.stream.advancedManufacturing": "Production avancée",
  "general.stream.agricultureTechnology": "Technologie agricole",
  "general.stream.appliedLearning": "Apprentissage appliqué : techniciens en pharmacie, opticiens et plus encore",
  "general.stream.biomanufacturing": "Bioproduction",
  "general.stream.businessOperations": "Activités commerciales : amélioration des processus, Agile et Scrum",
  "general.stream.businessStrategyInnovationAdministration": "Affaires : stratégie, innovation et administration des affaires",
  "general.stream.cleanTechnology": "Technologie verte",
  "general.stream.cloudComputingProgramming": "Infonuagique : essentiels, opérations et architecture",
  "general.stream.communicationConfidenceInfluenceResolution": "Communications : confiance professionnelle, influence et résolution de conflits",
  "general.stream.constructionManagement": "Gestion de la construction : contrats de construction, estimation et stratégie",
  "general.stream.cybersecurity": "Cybersécurité",
  "general.stream.dataAnalytics": "Analyse des données : PowerBI, Excel, analyse de données et visualisation",
  "general.stream.dataScience": "Science des données : modélisation des données, ingénierie des données et programmation des données",
  "general.stream.design": "Conception : conception Conception créative, graphique et conception UX/UI",
  "general.stream.digitalSkills": "Conception : conception Conception créative, graphique et conception UX/UI",
  "general.stream.digitalTechnology": "Technologie numérique",
  "general.stream.emergentTechnologies": "Technologies émergentes : chaîne de blocs, apprentissage automatique, IA et métavers",
  "general.stream.engineeringContinuingEducation": "Ingénierie : formation continue pour les ingénieurs professionnels",
  "general.stream.growthAccelerators": "Accélérateurs de croissance : ALS/FLS, communications écrites et mise à niveau universitaire",
  "general.stream.integratedTechnology": "Informatique : cybersécurité, sécurité de l’information et confidentialité des renseignements",
  "general.stream.leadership": "Leadership : essentiels du leader, encadrement et intelligence émotionnelle",
  "general.stream.lifestyle": "Mode de vie : nutrition, enrichissement personnel et loisirs",
  "general.stream.management101": "Gestion 101 : perfectionnement d’équipe, gestion du rendement et gestion du changement",
  "general.stream.marketing": "Marketing : marketing numérique, service à la clientèle et gestion des produits",
  "general.stream.myList": "Ma liste",
  "general.stream.onDemand": "À la demande",
  "general.stream.peopleCulture": "Personnes et culture : acquisition de talents, rémunération et relations avec les employés",
  "general.stream.preConstruction": "Préconstruction : B.I.M, habitat passif et conception environnementale",
  "general.stream.projectManagement": "Gestion de projet : préparation aux examens, formation continue et outils de gestion de projet",
  "general.stream.safetyFirst": "Sécurité avant tout : santé et sécurité au travail, SIMDUT et sécurité en milieu de travail",
  "general.stream.softwareDevelopment": "Développement de logiciels : bases de données, développement Web et général",
  "general.stream.supplyChainManagement": "Gestion de la chaîne logistique : logistique, commerce mondial et pratiques Lean Six Sigma",
  "general.stream.sustainability": "Durabilité : gestion durable, pratiques et responsabilité sociale d’entreprise",
  "general.stream.wellness": "Bien-être : bien-être numérique, bien-être émotionnel et conciliation travail-vie personnelle",
  "general.stream.writtenCommunication": "Communication écrite : communication d’affaires, rédaction technique et apprentissage de langues",

  "general.value": "Valeur", // Label for generic inputs that require a value
  "general.view": "Afficher", // Navigation link to view an activity, text for view activity button

  "home.academic.publicPortal.copy": "Acquérez de nouvelles compétences et propulsez votre carrière en suivant des cours et en obtenant des certificats professionnels et des diplômes délivrés par des établissements d’enseignement de classe mondiale", // Explains how academic partnerships help customers (found on public portal homepage)
  "home.academic.title": "Une formation de qualité offerte par des fournisseurs de confiance", // Header text for academic partnership section of homepage
  "home.brightspace.button": "Explorez {BrightspaceName}",
  "home.help.button": "Contactez-nous", // Navigates user to support page
  "home.help.text": "Nous sommes là pour répondre à vos questions. Laissez-nous vous soutenir dans votre parcours d’apprentissage.", // Text for contact support section of homepage
  "home.help.title": "Vous avez besoin d’aide?", // Title for contact support section of homepage
  "home.welcome.button": "Parcourir les programmes", // Homepage button that will navigate user to course catalog
  "home.welcome.copy": "Inscrivez-vous à des formations de qualité qui vous aideront à atteindre votre plein potentiel.", // Explains how Wave works to customer (found on homepage)
  "home.welcome.header": "Développez de nouvelles compétences et faites progresser votre carrière", // Welcome banner text on homepage
  "home.welcome.publicPortal.copy": "Trouvez des formations et des occasions de perfectionnement qui contribueront à faire progresser votre carrière, puis soumettez votre demande et inscrivez-vous", // Explains how Wave works to customer (found on public portal homepage)

  "image.alt.helpingHand": "Un coup de main", // Alt text for an image which is of a helping hand (used in 'contact us' area)

  "in-place-dropdown.buttonSave": "Enregistrer",

  "info-copy.action-button.aria-label": "Copier dans le presse-papier", // aria-label for copy button - clicking button copies associated data to user's clipboard

  "invite-user.success": "Création réussie de {numUsers} utilisateurs", // Message de succès lors de l'invitation réussie des utilisateurs

  "landing-stream.headingIntro": "Recommandé pour vos objectifs",

  "login.collectEmail.description": "Inscrivez-vous à des formations de qualité qui vous aideront à atteindre votre plein potentiel.", // Description of D2L Wave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.errorUpdatingUser": "Un problème est survenu lors de la mise à jour du compte d’utilisateur. Veuillez essayer à nouveau. Si le problème persiste, communiquez avec l’équipe de soutien.", // Error toast shown in case saving the email collection data fails
  "login.collectEmail.greeting.displayName": "Bonjour {displayName}!", // Greeting displayed to user of SkillsWave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.greeting.generic": "Bonjour!", // Greeting displayed to user on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.primaryButton": "Parcourir les programmes", // Label/text shown on button on email collection page which is shown before login completes, if user has no email yet

  "login.general.privacyPolicy": "Vos renseignements personnels pourraient être recueillis, utilisés et divulgués conformément à notre <link>politique de confidentialité</link>", // Label for privacy policy link. <link> tags are a placeholder for a hyperlink and should not be translated.

  "login.magiclink.documentTitle": "Chargement... – SkillsWave", // Displayed during sign-in process while generating a magic link for login redirection. Do not translate "SkillsWave".
  "login.magiclink.emailInput": "Courriel", // Email input label
  "login.magiclink.invalidEmail": "Adresse de courriel non valide", // Error message when the email address is invalid
  "login.magiclink.loading": "Chargement du lien magique", // Displayed during sign-in process while generating a magic link for login redirection
  "login.magiclink.loginHeader": "Ouvrir une session", // Login header
  "login.magiclink.sendLoginLink": "Envoyer le lien d’ouverture de session", // Text on button to submit login link form
  "login.magiclink.submitted.1": "Le courriel d’ouverture de session a été envoyé à l’adresse {email}.", // Text displayed during login redirect
  "login.magiclink.submitted.2": "Consultez votre boîte de réception pour récupérer votre lien d’ouverture de session.", // Text displayed during login redirect
  "login.marketing.companyName": "Nom de l’entreprise", // Label for company name input
  "login.marketing.form1.introText": "Entrez votre adresse de courriel pour commencer dès maintenant!", // The branded text on the first form
  "login.marketing.form1.introTextHeader": "Ohé! Place à l’amélioration des compétences.", // The header for the flavour text on the first form
  "login.marketing.form1.submit": "Commençons", // Label for first marketing form submit button
  "login.marketing.form1.title": "Ouvrir une session", // The title for the first form in the marketing page
  "login.marketing.form2.country": "Pays", // Label for the country input
  "login.marketing.form2.introText": "Parlez-nous un peu de vous.", // The the branded text on the first form
  "login.marketing.form2.introTextHeader": "Nous y sommes presque!", // The header for the flavour text on the first form
  "login.marketing.form2.state": "Province ou état", // Label for the state input
  "login.marketing.form2.submit": "Allons-y!", // Label for second marketing form submit button
  "login.marketing.form2.title": "S’inscrire", // The title for the second form in the marketing page
  "login.marketing.form3.introText.1": "Saisissez votre courriel pour voir comment vous pouvez habiliter vos employés à apprendre et à grandir en leur donnant accès à un catalogue de cours, de programmes et de microcertifications.", // The first <p> on the showcase landing page
  "login.marketing.form3.introText.2": "Vous n’êtes pas encore prêt? Pas de problème. Jetez un coup d’œil à <link>SkillsWave</link> et comment notre solution globale peut contribuer à façonner vos effectifs pour accélérer la croissance.", // The second <p> on the showcase with the video link. Do not translate "SkillsWave" or "SkillsWave".
  "login.marketing.form3.introTextHeader": "Bonjour! Bienvenue dans notre expérience de vitrine de produits.", // The header for the flavour text on the showcase landing page
  "login.marketing.form3.submit": "Explorer maintenant", // Label for first showcase landing page form submit button
  "login.marketing.form3.title": "Ouvrir une session", // The title for the first form in the showcase landing page
  "login.marketing.fullName": "Nom complet", // Label for full name input
  "login.marketing.marketingAgreement": "Oui, j’aimerais recevoir des communications de la famille des entreprises SkillsWave", // Label for marketing agreement checkbox
  "login.marketing.marketingAgreement.required": "Entente de marketing", // Label for marketing agreement required error
  "login.marketing.marketingAgreement.tooltip": "Pour essayer SkillsWave, vous devez accepter les communications marketing", // Tooltip to explain why continue button is disabled. Do not translate "SkillsWave".
  "login.marketing.title": "Titre de poste", // Label for job title input
  "login.marketing.workEmail": "Adresse courriel professionnel", // Label for work email input

  "login.multi.header": "Ouvrir une session", // The header for the multi login screen
  "login.multi.subHeader": "Choisissez votre organisation", // The sub header in the login box for the multi login screen

  "login.saml.redirect": "Vous êtes redirigé pour vous connecter", // Text displayed during login redirect

  "manage-activity-instructions.title": "Instructions d'activité",
  "manage-audit.title": "Audit", // ignore-untranslated

  "manage-budget-approvals.approvals.title.label": "Modèle d’approbation", // Title for Approval section on the page
  "manage-budget-approvals.budget.title.label": "Budget", // Title for Budget section on the page, ignore-untranslated
  "manage-budget-approvals.caseByCase": "Cas par cas", // Label for radio button for case-by-case budget setting
  "manage-budget-approvals.caseByCase.confirmation": "Le montant de la protection que les employés reçoivent sera déterminé par l’approbateur au cas par cas.", // Confirmation message shown when budget configuration is set as case-by-case
  "manage-budget-approvals.caseByCase.disabled.tooltipText": "Le budget cas par cas ne peut pas être activé lorsque le modèle d’approbation hybride est sélectionné.", // A tooltip description explaining why the radio button is disabled
  "manage-budget-approvals.corporatePolicy.employee": "Montant fixe (par employé)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.jobseeker": "Montant fixe (par chercheur d’emploi)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.member": "Montant fixe (par membre)", // Label for radio button for corporate policy
  "manage-budget-approvals.employee.annualCredit.label": "Crédit annuel aux employés", // Label for annual employee credit input text box
  "manage-budget-approvals.fiscalStartDate.label": "Date de début de l’exercice financier", // Label for fiscal year start date input
  "manage-budget-approvals.jobseeker.annualCredit.label": "Crédit annuel aux chercheurs d’emploi", // Label for annual jobseeker credit input text box
  "manage-budget-approvals.member.annualCredit.label": "Crédit annuel aux membres", // Label for annual member credit input text box
  "manage-budget-approvals.percentCoverage": "Pourcentage de couverture", // Label for radio button for percent coverage
  "manage-budget-approvals.percentCoverage.label": "Pourcentage de couverture par demande", // Label for percent coverage percentage input
  "manage-budget-approvals.saveButton": "Enregistrer", // Save button text
  "manage-budget-approvals.saveButton.saved": "Enregistré(e)", // Button text when budget settings has been saved
  "manage-budget-approvals.savedToast": "Informations budgétaires du locataire enregistrées", // Text for toast that confirms that budget settings have been saved
  "manage-budget-approvals.subtitle": "Peut être modifié plus tard, mais aura une incidence sur l’endroit où les fonds sont prélevés pour les demandes de formation", // Subtitle for budget tab explaining the impact of budget configuration
  "manage-budget-approvals.title": "Budget et approbations", // Title for budget & approval tab

  "manage-career-explorer.title": "Explorateur de carrière",

  "manage-custom-attributes.application.description": "Les valeurs de ces attributs personnalisés seront stockées dans les dossiers de l'application.", // Informational text about where attributes will be saved
  "manage-custom-attributes.application.heading": "Modifier les attributs personnalisés de l'application", // Heading for application custom attributes section of the page
  "manage-custom-attributes.application.label": "Attributs personnalisés de l'application", // Label for the editor section where users can edit custom attributes
  "manage-custom-attributes.defaultNameValue": "Nouvel attribut personnalisé", // Default name if user adds a new custom attribute
  "manage-custom-attributes.helpText": "La documentation détaillée sur les attributs personnalisés se trouve dans Confluence", // Text which is a link to some documentation
  "manage-custom-attributes.save.applicationAttributesInvalid.json": "JSON invalide dans les attributs personnalisés de l'application", // Message which appears on save if there is an issue with the JSON formatting for application attributes
  "manage-custom-attributes.save.applicationAttributesInvalid.schema": "Attributs personnalisés de l'application invalides", // Message which appears on save if there is an issue with the schema formatting for application attributes
  "manage-custom-attributes.save.bothAttributesInvalid.json": "JSON invalide dans les attributs personnalisés de l'utilisateur et de l'application", // Message if there is a problem with the JSON on both custom attribute types
  "manage-custom-attributes.save.bothAttributesInvalid.schema": "Attributs personnalisés de l'utilisateur et de l'application invalides", // Message if there is a problem with the schema on both custom attribute types
  "manage-custom-attributes.save.success": "Attributs personnalisés enregistrés", // Message which appears when attributes were saved successfully
  "manage-custom-attributes.save.userAttributesInvalid.json": "JSON invalide dans les attributs personnalisés de l'utilisateur", // Message which appears on save if there's an issue with the JSON formatting
  "manage-custom-attributes.save.userAttributesInvalid.schema": "Attributs personnalisés de l'utilisateur invalides", // Message which appears on save if there is an issue with the data matching the schema
  "manage-custom-attributes.title": "Attributs personnalisés", // Title for custom attributes tab in the admin section
  "manage-custom-attributes.user.description": "Les valeurs de ces attributs personnalisés seront stockées dans les dossiers de l'utilisateur.", // Informational text about where attributes will be saved
  "manage-custom-attributes.user.heading": "Modifier les attributs personnalisés de l'utilisateur", // Heading for user custom attributes section of the page
  "manage-custom-attributes.user.label": "Attributs personnalisés de l'utilisateur", // Label for the editor section where users can edit custom attributes

  "manage-custom-terms.title": "Conditions d'utilisation des employés",
  "manage-enabled-streams.title": "Flux activés",
  "manage-entitlements.title": "Droits",
  "manage-faq.title": "FAQ", // ignore-untranslated

  "manage-general.accountConnect.studentID": "Nécessite un identifiant d'étudiant",
  "manage-general.accountConnect.studentID.format": "Format de l'identifiant d'étudiant",
  "manage-general.adminRoles": "Activer le rôle basé sur les autorisations", // Tooltip for check if business is Canada based
  "manage-general.brightspace.name": "Nom Brightspace",
  "manage-general.brightspace.url": "URL Brightspace",
  "manage-general.buttonSave": "Enregistrer",
  "manage-general.isCanadianBusiness": "Il s’agit d’une entreprise canadienne", // Text for check if business is Canada based
  "manage-general.label.accentColour": "Couleur d’accent", // Text for accent colour label
  "manage-general.label.daysUntilInvoiceDue": "Jours d’ici la date d’échéance de la facture", // Text for accent colour label
  "manage-general.label.financeEmail": "Courriel du service des finances", // Text for finance email label
  "manage-general.label.programPolicy": "Politique du programme (y compris le schéma URL)", // Text for program policty label
  "manage-general.label.studentSupportEmail": "Courriel du service de soutien aux élèves", // Text for student support email label
  "manage-general.learnerTerminology": "Terminologie de l’apprenant", // Text for learner terminology options
  "manage-general.notes": "Notes d’admin", // Label for Admin notes
  "manage-general.revShare.fixed": "du montant fixe", // Text for revenue share option: fixed amount
  "manage-general.revShare.percent": "de la portion (en %) du coût de l’activité", // Text for revenue share option: portion of activity cost
  "manage-general.tags.allowEditableEmail": "Autoriser la modification du courriel de l’utilisateur",
  "manage-general.tags.automaticCatalogOptOut": "Se désabonner des mises à jour automatiques du catalogue pour les nouvelles activités",
  "manage-general.tags.cacheAnalyticsReports": "Rapports d’analyse du cache", // Text label for a checkbox that will cache analytics reports
  "manage-general.tags.collectEmailOnLogin": "Recueillez les courriels à l’ouverture de la session si l’utilisateur n’en a pas", // Text displayed beside checkbox option to determine if we collect emails on login or not
  "manage-general.tags.enableLinkToBrightspace": "Activer « Lien vers Brightspace »",
  "manage-general.tags.hideFastToCompleteStream": "Masquer la piste « {streamName} » de la page des programmes", // Text label for a checkbox that will hide the 'fastToComplete' dynamic carousel of courses
  "manage-general.tags.ownRequestSubmit": "Activer « Soumettre votre propre demande »", // Text for own submit request option
  "manage-general.tags.ownRequestSubmitTooltip": "Ce paramètre ne doit être activé que pour les employeurs qui ont acheté la fonction « Soumettre votre propre demande »", // Tooltip text for own submit request option
  "manage-general.tags.paymentHandledByProvider": "Paiement et inscription gérés par le fournisseur", // Text label for a checkbox handled by provider
  "manage-general.tags.plgTenant": "Définir comme locataire PLG", // Text label for a checkbox to set the tenant as a PLG tenant
  "manage-general.title": "Général",
  "manage-general.userDataSystem": "Système d’origine des données de l’utilisateur", // Text for User Data System
  "manage-how-it-works.title": "Contenu Comment ça fonctionne",

  "manage-login.title": "Connexion",
  "manage-provider-finance.title": "Finance", // Title for finance tab, ignore-untranslated

  "manage-role.field.roleDescription.description": "Utilisez cette section pour décrire à quelles autorisations ce rôle est associé.",
  "manage-role.field.roleDescription.label": "Description du Rôle",
  "manage-role.field.roleName.label": "Nom",
  "manage-role.heading.permissions": "Permissions", // ignore-untranslated
  "manage-role.submit": "Soumettre",

  "manage-user-permissions.documentTitle": "Gérer les permissions des utilisateurs – SkillsWave", // Document title for user permissions page
  "manage-user-permissions.emailInputLabel": "Email", // Label for the user email input, ignore-untranslated
  "manage-user-permissions.failToast": "Les rôles de l'utilisateur n'ont pas pu être mis à jour. Veuillez réessayer.", // Text for the failed toast message
  "manage-user-permissions.heading": "Mettre à jour les permissions des utilisateurs", // Heading for the manage user permissions page
  "manage-user-permissions.noRolesAlert": "Cet utilisateur n'a aucun rôle attribué.", // Alert message when no roles are selected
  "manage-user-permissions.pageDescription": "Ajustez les rôles attribués à cet utilisateur pour vous assurer qu'ils ont accès aux sections nécessaires des paramètres administratifs de SkillsWave.", // Description text for the manage user permissions page
  "manage-user-permissions.rolesHeading": "Rôles", // Header text for the Roles heading
  "manage-user-permissions.statusIndicatorActive": "Actif", // Text for the active status indicator
  "manage-user-permissions.statusIndicatorInactive": "Inactif", // Text for the inactive status indicator
  "manage-user-permissions.successToast": "Les rôles de l'utilisateur ont été mis à jour avec succès.", // Text for the success toast message
  "manage-user-permissions.titleInputLabel": "Titre", // Label for the user title input
  "manage-user-permissions.updateUserButton": "Mettre à jour l'utilisateur", // Label for the update user
  "manage-user-permissions.userActiveSwitchLabel": "Utilisateur Actif", // Label for the user active switch
  "manage-user-permissions.userNameInputLabel": "Nom d'utilisateur", // Label for the user name input

  "manage-visibility.title": "Visibilité",

  "no-results.message": "Aucun résultat pour les filtres actuellement sélectionnés", // Text displayed when there are no activities that fit the current filters

  "nova-carousel.viewAll": "Voir tout", // Text for the view all button in the carousel
  "nova-carousel.viewAll.ariaLabel": "Voir tout", // Aria label for the view all button in the carousel

  "nova-json-input.addElement.buttonText": "Ajouter un élément", // text appearing on button that lets user add a new element to an array in the JSON object editor
  "nova-json-input.addField.buttonText": "Ajouter un champ", // text appearing on button that lets user add a new JSON key value pair in the JSON object editor
  "nova-json-input.addField.menuLabel": "Type de valeur", // label for "add field" sub-menu, visible to screenreaders
  "nova-json-input.arrayIndex": "index [{index}]", // counting indices of arrays for the label beside array values, ignore-untranslated
  "nova-json-input.deleteField.buttonText": "Supprimer cette paire de valeur-clé", // text for the delete field button, visible to screenreaders only
  "nova-json-input.headers.key": "Clé", // Table heading for JSON Key inputs which will appear in this column
  "nova-json-input.headers.remove": "Supprimer", // Aria label for table heading which has no visible text, this is for screenreaders. The column has an action button in it, e.g. delete (maybe more in future)
  "nova-json-input.headers.type": "Type", // Table heading for JSON Type values which will appear in this column, ignore-untranslated
  "nova-json-input.headers.value": "Valeur", // Table heading for JSON Value inputs which will appear in this column
  "nova-json-input.mainEditor.label": "Vue en formulaire", // label on the main editing tab of a JSON editor component
  "nova-json-input.rawJsonPreview.invalid": "L'éditeur ne peut pas être rendu parce que le JSON est invalide. Vous pouvez le modifier dans la vue JSON ou actualiser la page pour revenir à l'état initial.", // Message displayed when the JSON is made invalid
  "nova-json-input.rawJsonPreview.label": "Vue JSON", // label on the raw json preview tab of a JSON editor component
  "nova-json-input.value.label": "Valeur de la touche {key}", // hidden label of value field, visible to screenreaders

  "nova-json-input-array.addObject": "Ajouter un objet", // Text for button that adds a JSON object to the array
  "nova-json-input-array.contextMenu.deleteObject": "Supprimer l'objet", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.duplicateObject": "Dupliquer l'objet", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.label": "Actions", // Label for a context menu that enables some action on a form input, ignore-untranslated
  "nova-json-input-array.empty": "Il n'y a pas d'objet dans cette liste. Utilisez le bouton « Ajouter un objet » pour en ajouter un.", // If the array is empty, tell the user they can add an object use the add object button
  "nova-json-input-array.objectTitle": "Objet {index}", // Title of an object section, the index will be a positive integer

  "onboarding-personalization-flow.skillSelection.backButton": "Revenir en arrière",
  "onboarding-personalization-flow.skillSelection.backButtonMobile": "Retour",
  "onboarding-personalization-flow.skillSelection.header": "Ensembles de compétences suggérés pour {title}",
  "onboarding-personalization-flow.skillSelection.nextButton": "Fixez Vos Objectifs Professionnels",
  "onboarding-personalization-flow.skillSelection.search": "Rechercher des ensembles de compétences",
  "onboarding-personalization-flow.skillSelection.skipButton": "Ignorer Pour L'instant",
  "onboarding-personalization-flow.skillSelection.skipButtonMobile": "Passer",
  "onboarding-personalization-flow.skillSelection.subheader": "Pour obtenir les meilleurs résultats, choisissez au moins trois ensembles de compétences.",
  "onboarding-personalization-flow.skillSelection.subtitle": "Choisissez les compétences que vous souhaitez développer et nous trouverons des cours pour vous. Vous pourrez modifier cela plus tard.",
  "onboarding-personalization-flow.skillSelection.title": "Perfectionnez vos compétences",

  "onboarding-personalization-flow.titleSelection.empty": "Pour commencer, recherchez une carrière",
  "onboarding-personalization-flow.titleSelection.growthOpportunities": "Occasions de croissance",
  "onboarding-personalization-flow.titleSelection.loadMore": "Charger plus",
  "onboarding-personalization-flow.titleSelection.meterText": "Correspondance de compétences",
  "onboarding-personalization-flow.titleSelection.nextButton": "Obtenez des Recommandations",
  "onboarding-personalization-flow.titleSelection.noResults": "Aucun résultat trouvé pour : {search}",
  "onboarding-personalization-flow.titleSelection.relatedCareers": "Carrières connexes",
  "onboarding-personalization-flow.titleSelection.relatedCareersToJobTitle": "Carrières liées à {jobTitle}",
  "onboarding-personalization-flow.titleSelection.relevantSkills": "Compétences Pertinentes",
  "onboarding-personalization-flow.titleSelection.search": "Rechercher des carrières",
  "onboarding-personalization-flow.titleSelection.subtitle": "Choisissez les carrières qui vous intéressent pour obtenir davantage de recommandations de cours. Vous pouvez modifier cela plus tard.",
  "onboarding-personalization-flow.titleSelection.title": "Définissez des objectifs de carrière",
  "onboarding-personalization-flow.titleSelection.transferrableSkills": "Compétences transférables",

  "permissions.category.activity.description": "Créer, modifier et apporter des modifications aux activités (cours et programmes) dans le catalogue",
  "permissions.category.activity.label": "Paramètres d'Activité",
  "permissions.category.admin.description": "Modifier les onglets dans les paramètres du locataire administrateur",
  "permissions.category.admin.label": "Paramètres Administrateur",
  "permissions.category.admin:update.description": "Modifier les onglets dans les paramètres du locataire administrateur",
  "permissions.category.admin:update.label": "Modifier Administrateur",
  "permissions.category.admin:view.description": "Voir les onglets dans les paramètres du locataire administrateur",
  "permissions.category.admin:view.label": "Voir Administrateur",
  "permissions.category.analytics.description": "Analyses des activités et des employeurs",
  "permissions.category.analytics.label": "Onglet d'Analytique",
  "permissions.category.employer.description": "Créer des locataires d'employeurs pour nos clients d'entreprise et apporter des modifications aux paramètres des locataires actuels",
  "permissions.category.employer.label": "Paramètres d'Employeur",
  "permissions.category.employer:update.description": "Modifier les onglets dans les paramètres des locataires d'employeurs",
  "permissions.category.employer:update.label": "Modifier l'Employeur",
  "permissions.category.employer:view.description": "Voir les onglets dans les paramètres des locataires d'employeurs",
  "permissions.category.employer:view.label": "Voir l'Employeur",
  "permissions.category.features.description": "Activer ou désactiver les fonctionnalités pour les locataires des fournisseurs ou des employeurs",
  "permissions.category.features.label": "Onglet des Fonctionnalités",
  "permissions.category.permissions.description": "Créer des locataires de fournisseurs pour les partenaires éducatifs et apporter des modifications aux paramètres des locataires actuels",
  "permissions.category.permissions.label": "Onglet des Permissions",
  "permissions.category.provider.description": "Paramètres pour les locataires de fournisseurs",
  "permissions.category.provider.label": "Paramètres du Fournisseur",
  "permissions.category.provider:update.description": "Modifier les onglets dans les paramètres des locataires de fournisseurs",
  "permissions.category.provider:update.label": "Modifier le Fournisseur",
  "permissions.category.provider:view.description": "Voir les onglets dans les paramètres des locataires de fournisseurs",
  "permissions.category.provider:view.label": "Voir le Fournisseur",
  "permissions.category.requests.description": "Requêtes pour les activités de tous les clients",
  "permissions.category.requests.label": "Onglet des Requêtes",
  "permissions.permission.activity:archive.description": "Archiver une activité pour qu'elle ne soit pas visible dans le catalogue des fournisseurs d'éducation",
  "permissions.permission.activity:archive.label": "Archiver l'Activité",
  "permissions.permission.activity:create.description": "Ajouter de nouvelles activités au catalogue.",
  "permissions.permission.activity:create.label": "Ajouter une Activité",
  "permissions.permission.activity:create-update.description": "Ajouter de nouvelles activités ou modifier des activités existantes dans le catalogue.",
  "permissions.permission.activity:create-update.label": "Ajouter ou Mettre à Jour une Activité",
  "permissions.permission.activity:export.description": "Exporter un fichier CSV des activités avec des colonnes personnalisées.",
  "permissions.permission.activity:export.label": "Exporter les Activités",
  "permissions.permission.activity:status:update.description": "Activer ou désactiver les activités dans le catalogue.",
  "permissions.permission.activity:status:update.label": "Activation des Activités",
  "permissions.permission.activity:streams:import.description": "Importer des activités pour reclasser ce qui appartient à un flux.",
  "permissions.permission.activity:streams:import.label": "Importer des Activités dans les Flux",
  "permissions.permission.activity:update.description": "Apporter des modifications à toutes les activités existantes dans le catalogue.",
  "permissions.permission.activity:update.label": "Modifier l'Activité",
  "permissions.permission.activity:view.description": "Voir la liste des activités dans le catalogue et consulter les paramètres des activités.",
  "permissions.permission.activity:view.label": "Voir l'Activité",

  "permissions.permission.admin:audit:update.label": "Onglet Audit",
  "permissions.permission.admin:audit:view.label": "Onglet Audit",
  "permissions.permission.admin:create.description": "Ajouter un locataire administrateur",
  "permissions.permission.admin:create.label": "Ajouter Administrateur",
  "permissions.permission.admin:general:update.label": "Onglet Général",
  "permissions.permission.admin:general:view.label": "Onglet Général",
  "permissions.permission.admin:login:update.label": "Onglet Connexion",
  "permissions.permission.admin:login:view.label": "Onglet Connexion",
  "permissions.permission.admin:roles:update.label": "Onglet Rôles",
  "permissions.permission.admin:roles:view.label": "Onglet Rôles",
  "permissions.permission.admin:userpermissions:update.label": "Onglet Permissions Utilisateurs",
  "permissions.permission.admin:userpermissions:view.label": "Onglet Permissions Utilisateurs",

  "permissions.permission.analytics:view.description": "Voir et filtrer les analyses pour tous les locataires.",
  "permissions.permission.analytics:view.label": "Voir les Analyses",

  "permissions.permission.employer:audit:update.label": "Onglet d'Audit",
  "permissions.permission.employer:audit:view.label": "Onglet d'Audit",
  "permissions.permission.employer:careerexplorer:update.label": "Onglet Explorateur de Carrière",
  "permissions.permission.employer:careerexplorer:view.label": "Onglet Explorateur de Carrière",
  "permissions.permission.employer:create.description": "Ajouter un locataire employeur",
  "permissions.permission.employer:create.label": "Ajouter un Employeur",
  "permissions.permission.employer:customattributes:update.label": "Onglet Attributs Personnalisés",
  "permissions.permission.employer:customattributes:view.label": "Onglet Attributs Personnalisés",
  "permissions.permission.employer:enabledstreams:update.label": "Onglet Flux Activés",
  "permissions.permission.employer:enabledstreams:view.label": "Onglet Flux Activés",
  "permissions.permission.employer:entitlements:update.label": "Onglet Droits",
  "permissions.permission.employer:entitlements:view.label": "Onglet Droits",
  "permissions.permission.employer:faq:update.label": "Onglet FAQ",
  "permissions.permission.employer:faq:view.label": "Onglet FAQ",
  "permissions.permission.employer:finance:update.label": "Onglet Budgets & Approbation",
  "permissions.permission.employer:finance:view.label": "Onglet Budgets & Approbation",
  "permissions.permission.employer:general:update.label": "Onglet Général",
  "permissions.permission.employer:general:view.label": "Onglet Général",
  "permissions.permission.employer:howitworks:update.label": "Onglet Fonctionnement",
  "permissions.permission.employer:howitworks:view.label": "Onglet Fonctionnement",
  "permissions.permission.employer:login:update.label": "Onglet Connexion",
  "permissions.permission.employer:login:view.label": "Onglet Connexion",
  "permissions.permission.employer:termsofuse:update.label": "Onglet Conditions d'Utilisation de l'Employeur",
  "permissions.permission.employer:termsofuse:view.label": "Onglet Conditions d'Utilisation de l'Employeur",
  "permissions.permission.employer:visibility:update.label": "Onglet Visibilité",
  "permissions.permission.employer:visibility:view.label": "Onglet Visibilité",

  "permissions.permission.features:update.description": "Apporter des modifications aux fonctionnalités (activer ou désactiver) pour les locataires.",
  "permissions.permission.features:update.label": "Modifier les Fonctionnalités",
  "permissions.permission.features:view.description": "Voir la liste des fonctionnalités disponibles pour les locataires.",
  "permissions.permission.features:view.label": "Voir les Fonctionnalités",

  "permissions.permission.permissions.description": "Voir et apporter des modifications à toutes les sections de l'onglet des permissions.",
  "permissions.permission.permissions.label": "Voir & Modifier les Permissions",

  "permissions.permission.provider:activityinstructions:update.label": "Onglet Instructions d'Activité",
  "permissions.permission.provider:activityinstructions:view.label": "Onglet Instructions d'Activité",
  "permissions.permission.provider:audit:update.label": "Onglet Audit",
  "permissions.permission.provider:audit:view.label": "Onglet Audit",
  "permissions.permission.provider:careerexplorer:update.label": "Onglet Explorateur de Carrière",
  "permissions.permission.provider:careerexplorer:view.label": "Onglet Explorateur de Carrière",
  "permissions.permission.provider:create.description": "Ajouter un locataire fournisseur",
  "permissions.permission.provider:create.label": "Ajouter un Fournisseur",
  "permissions.permission.provider:customattributes:update.label": "Onglet Attributs Personnalisés",
  "permissions.permission.provider:customattributes:view.label": "Onglet Attributs Personnalisés",
  "permissions.permission.provider:entitlements:update.label": "Onglet Droits",
  "permissions.permission.provider:entitlements:view.label": "Onglet Droits",
  "permissions.permission.provider:faq:update.label": "Onglet FAQ",
  "permissions.permission.provider:faq:view.label": "Onglet FAQ",
  "permissions.permission.provider:finance:update.label": "Onglet Finances",
  "permissions.permission.provider:finance:view.label": "Onglet Finances",
  "permissions.permission.provider:general:update.label": "Onglet Général",
  "permissions.permission.provider:general:view.label": "Onglet Général",
  "permissions.permission.provider:howitworks:update.label": "Onglet Fonctionnement",
  "permissions.permission.provider:howitworks:view.label": "Onglet Fonctionnement",
  "permissions.permission.provider:login:update.label": "Onglet Connexion",
  "permissions.permission.provider:login:view.label": "Onglet Connexion",
  "permissions.permission.requests:update.description": "Apporter des modifications aux statuts des demandes, avant l'inscription",
  "permissions.permission.requests:update.label": "Modifier les Demandes",
  "permissions.permission.requests:view.description": "Voir et filtrer la liste des demandes pour tous les utilisateurs finaux de SkillsWave",
  "permissions.permission.requests:view.label": "Voir les Demandes",

  "readonly-dialog.marketingMode.message": "Activez le marketing pour une expérience différente", // Text displayed notifying user is in marketing mode
  "readonly-dialog.readOnlyMode.message": "Ce locataire est en mode lecture seule. Aucun document ne peut être soumis en mode lecture seule", // Text displayed notifying user is in read only mode

  "redirect.skillswave.documentTitle": "Redirection en cours...",
  "redirect.skillswave.linkText": "allez sur SkillsWave maintenant",
  "redirect.skillswave.message": "Cela ne prendra qu'un moment, ou ",
  "redirect.skillswave.title": "Vous redirige vers",

  "registration-call-to-action.alertButtonText": "Afficher", // The label that goes on the registration call to action button
  "registration-call-to-action.viewApplication": "<strong>Action requise :</strong> Terminez votre inscription à <strong>{activityTitle}</strong>",
  "registration-call-to-action.viewApplications": "<strong>Action requise :</strong> Terminez vos inscriptions",

  "report.adoption.approved": "Approuvées", // The label for the approved requests
  "report.adoption.cancelledBy.employee": "Annulé par l’employé", // The label for the cancelled by employee requests
  "report.adoption.cancelledBy.jobseeker": "Annulé par le chercheur d’emploi", // The label for the cancelled by jobseeker requests
  "report.adoption.cancelledBy.member": "Annulé par le membre", // The label for the cancelled by member requests
  "report.adoption.cancelledByLearner": "Annulé par l’apprenant", // The label for the cancelled by learner requests
  "report.adoption.declined": "Refusées", // The label for the declined requests
  "report.adoption.header": "Demandes", // The header for the adoption report widget
  "report.adoption.pending": "En attente", // The label for the pending requests
  "report.adoption.title": "Adoption", // The title for the adoption report widget, ignore-untranslated
  "report.awareness.dailyAverage": "Moyenne quotidienne", // The label for the daily average logins
  "report.awareness.header": "Ouvertures de session uniques", // The header for the awareness report widget
  "report.awareness.title": "Notoriété de la marque", // The header for the awareness report widget
  "report.awareness.totalLogins": "Total d’ouvertures de session", // The label for the total logins
  "report.emptyStateMsg": "Aucune donnée n’est disponible en fonction des filtres que vous avez sélectionnés", // The message displayed when a report has no data without filters
  "report.investment.costToCompany": "Coût pour l’entreprise", // The label for the cost to the company
  "report.investment.costToEmployees": "Coût pour les employés", // The label for the cost to employees
  "report.investment.employee.costs": "Coût pour les employés", // The label for the cost to employees
  "report.investment.header": "Total approuvé", // The header for the investment report widget
  "report.investment.jobseeker.costs": "Coût pour les chercheurs d’emploi", // The label for the cost to jobseeker
  "report.investment.member.costs": "Coût pour les membres", // The label for the cost to members
  "report.investment.pending": "En attente", // The label for the pending costs
  "report.investment.title": "Investissement", // The header for the investment report widget
  "report.progress.completed": "Terminé", // The label for the number of completed requests
  "report.progress.header": "Total approuvé", // The header for the investment report widget
  "report.progress.other": "Non réussi/autre", // The label for the number of unsuccessful requests or requests with another state
  "report.progress.registered": "Inscrits", // The label for the number of registered requests
  "report.progress.registrationStarted": "Processus d'inscription commencé", // The label for the number of registration started requests
  "report.progress.title": "Progrès", // The header for the investment report widget
  "report.skillsDemand.emptyStateMsg": "Les compétences les plus en demande apparaîtront ici", // The message displayed when a skillsDemand report has no data
  "report.skillsDemand.heading1": "Compétence", // The heading for the skill name column
  "report.skillsDemand.heading2": "Demandes", // The heading for the number of requests column
  "report.skillsDemand.title": "Demande de compétences", // header for the top departments report widget
  "report.topActivities.emptyStateMsg": "Les programmes les plus en demande apparaîtront ici", // The message displayed when a topActivities report has no data
  "report.topActivities.numRequest": "{numRequests} requête", // The number of requests in the top activities report (Case 1: Exactly 1 request)
  "report.topActivities.numRequests": "{numRequests} requêtes", // The number of requests in the top activities report (Case 2: More than 1 request)
  "report.topActivities.title": "Programmes les plus populaires", // Title for the topActivities report widget
  "report.topDepartments.emptyStateMsg": "Les départements les plus en demande apparaîtront ici", // The message displayed when a topDepartments report has no data
  "report.topDepartments.heading1": "Département", // The heading for the department name column
  "report.topDepartments.heading2": "Demandes", // The heading for the number of requests column
  "report.topDepartments.title": "Départements les plus populaires", // header for the top departments report widget
  "report.topProviders.emptyStateMsg": "Les fournisseurs de programmes les plus en demande apparaîtront ici", // The message displayed when a topProviders report has no data
  "report.topProviders.heading2": "Demandes", // The heading for the number of requests column
  "report.topProviders.title": "Les fournisseurs les plus populaires", // header for the top departments report widget

  "review-application.back.button": "Revenir", // Form button to go back to previous page
  "review-application.documentTitle": "Passer en revue ma demande – SkillsWave", // Document title when viewing the second page of the application process (review application). Do not translate "SkillsWave".
  "review-application.overview.approverNames": "Nom(s) de l’approbateur", // Label for approver names in review application overview
  "review-application.title": "Passer en revue ma demande", // Title for draft application review page

  "settings-finance.add": "Ajouter",
  "settings-finance.card-ending": "Carte se terminant par {lastFour}",
  "settings-finance.card-expiry": "Expire le {expiry_year}/{expiry_month}",
  "settings-finance.delete": "Supprimer",
  "settings-finance.delete-dialog.message": "Etes-vous sûr de vouloir supprimer la carte se terminant par {lastFour}?",
  "settings-finance.delete-dialog.title": "Supprimer moyens de paiement",
  "settings-finance.edit": "Modifier",
  "settings-finance.edit-dialog.cardholder-name": "Nom du titulaire de la carte",
  "settings-finance.edit-dialog.city": "Ville",
  "settings-finance.edit-dialog.country": "Pays",
  "settings-finance.edit-dialog.expiry-month": "Mois d'expiration",
  "settings-finance.edit-dialog.expiry-year": "Année d'expiration",
  "settings-finance.edit-dialog.postal": "Code postal",
  "settings-finance.edit-dialog.province": "Province",
  "settings-finance.edit-dialog.street": "Rue",
  "settings-finance.edit-dialog.street2": "Rue (ligne 2)",
  "settings-finance.edit-dialog.title": "Modifier moyens de paiement",
  "settings-finance.payment-methods": "Moyens de paiement enregistrés",

  "skill-chip.remove-button.text": "Supprimer la compétence", // accessibility text for skill chip removal button, shown to admin users
  "skill-chip-list.numSkills": "{number, plural, one {# compétence} other {# compétences}}",

  "sso.attribute-requirement.description": "Lorsqu'un utilisateur ouvre une session dans Wave, les attributs de son objet utilisateur doivent réussir TOUTES ces vérifications regex avant d'obtenir l'accès.", // Text describing how attribute requirements are used
  "sso.attribute-requirement.save": "Enregistrer les exigences", // Text for attribute requirements save button
  "sso.attribute-requirement.save.fail": "Erreur d'enregistrement des exigences d'attribut", // Text for attribute requirements save failure toast
  "sso.attribute-requirement.save.success": "Exigences d'attribut enregistrées", // Text for attribute requirements save success toast
  "sso.attribute-requirement.title": "Exigences d'attributs", // Title text for attribute requirements
  "sso.checkDomain": "Vérifier le domaine", // The check domain flag test
  "sso.checkDomain.help": "Lorsque cette option est désactivée, ce domaine d’ouverture de session lien magique permet de se connecter avec N’IMPORTE QUELLE adresse électronique", // The tooltip message describing what the checkDomain flag is used for
  "sso.configuration.title": "Configuration de l'authentification unique", // Title for the attribute configuration
  "sso.copy.url": "Copier l’adresse URL", // Alt text for copy url button
  "sso.copy.url.toast": "Copie de l’URL de rappel {callbackUrl} dans le presse-papiers", // Toast message for when url is copied
  "sso.create": "Créer", // Alt text for create button
  "sso.default": "Domaine par défaut", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.default.help": "Ce domaine sera utilisé lorsqu’un domaine d’utilisateur ne peut être déterminé. REMARQUE : Ce champ sera désactivé si un autre domaine est indiqué par défaut", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.delete": "Supprimer", // Alt text for delete button
  "sso.delete.error": "Erreur lors de la suppression de l’objet SSO {name}. Erreur : {message}", // Error toast for when it fails to delete the SSO object
  "sso.delete.success": "Suppression de l’objet SSO {name} réussie", // Success toast for when it deletes the SSO object
  "sso.dialog.create": "Créer une ouverture de session", // Title for create login dialog
  "sso.dialog.edit": "Modifier une ouverture de session", // Title for edit login dialog
  "sso.domain": "Domaine", // The domain attribute display name
  "sso.domain.help": "Voici le domaine auquel l’utilisateur doit accéder pour ouvrir une session dans SkillsWave. Il ne peut pas être modifié après sa création", // The tooltip message describing what the domain is used for. Do not translate "SkillsWave"
  "sso.edit": "Modifier l’information SSO", // Alt text for edit SSO object button
  "sso.imageUrl": "URL de l’image", // The image url display name
  "sso.loginType": "Type d’ouverture de session", // The loginType attribute display name
  "sso.ml.emailDomain": "Domaine de courriel", // The emailDomain attribute display name
  "sso.name": "Nom", // The name attribute display name
  "sso.noDefaultDomainSet": "Définissez un domaine par défaut pour garantir une livraison d'e-mails cohérente.", // The warning message when there is no default domain set
  "sso.not-configured": "L’authentification unique n’est pas configurée pour ce locataire. L’ouverture de session n’est pas possible lorsque l’authentification unique n’est pas configurée.", // Warning message for when sso is not configured
  "sso.saml.cert": "Cert", // The SAML cert attribute display name
  "sso.saml.enablePublicPortal": "Activer le portail public", // The SAML enable public portal display name
  "sso.saml.entryPoint": "Point d’entrée", // The saml entry point display name
  "sso.saml.identifierFormat": "Format d’identifiant", // The format of the name provided by the identity provider
  "sso.saml.issuer": "Émetteur", // The saml issuer attribute display name
  "sso.saml.logoutUrl": "URL de déconnexion", // URL that is redirected to for SSO logout
  "sso.saml.mapping": "Mise en correspondance", // The attribute mapping display name
  "sso.save.error": "Erreur lors de l’enregistrement de l’objet SSO {name}. Erreur : {message}", // The error toast when it fails to save an SSO object
  "sso.save.success": "L’objet SSO {name} a été enregistré avec succès", // The success toast when it successfully saves an SSO object

  "streams.custom.walmart.credentials": "Crédits pour compétences futures", // Title for custom Credentials stream for Walmart
  "streams.custom.walmart.degreePrograms": "Programmes de diplôme", // Title for custom Degree Programs stream for Walmart
  "streams.ordered.recentlyAdded": "Récemment ajoutés", // Title for group of activites that have been recently added
  "streams.ordered.startingSoon": "Offerts prochainement", // Title for group of activites that have a start date in the near future
  "streams.property.fastToComplete": "Terminer en 8 heures ou moins", // Title for stream of activities that a user can complete in a short amount of time.
  "streams.property.trending": "Tendance à {tenantName}", // Title for stream of activities that are trending at the users company.  {tenantName} is a proper noun.

  "submit-own-request.breadcrumb": "Retour aux demandes", // breadcrumb label for submit my own request page
  "submit-own-request.documentTitle": "Soumettre ma propre demande – SkillsWave", // Document title when viewing the smunit-own-request page. Do not translate "SkillsWave".
  "submit-own-request.form.course.cost": "Coût", // label for course cost
  "submit-own-request.form.course.cost.tooltip": "Assurez-vous d’inclure toutes les taxes et tous les frais supplémentaires", // tooltip text for course cost input box
  "submit-own-request.form.course.date.enddate": "Date de fin", // label for course end date
  "submit-own-request.form.course.date.startdate": "Date de début", // label for course start date
  "submit-own-request.form.course.description": "Description", // label for course description, ignore-untranslated
  "submit-own-request.form.course.title": "Titre", // label for course title
  "submit-own-request.form.course.type": "De quel type de formation s’agit-il?", // label for course type dropdown
  "submit-own-request.form.course.type.option.conference": "Conférence", // label for type conference
  "submit-own-request.form.course.type.option.course": "Cours", // label for type course
  "submit-own-request.form.course.type.option.other": "Autre", // label for type other
  "submit-own-request.form.course.type.option.program": "Programme", // label for type program
  "submit-own-request.form.course.type.option.textbook": "Manuel", // label for type textbook
  "submit-own-request.form.course.type.option.webinar": "Webinaire", // label for type webinar
  "submit-own-request.form.course.type.option.workshop": "Atelier", // label for type workshop
  "submit-own-request.form.website.label": "URL du site Web", // label for Website input textbox
  "submit-own-request.introduction.double": "Vous pouvez demander l’approbation d’un perfectionnement professionnel à l’extérieur de SkillsWave.<linebreak></linebreak> Cette demande sera approuvée par : {approverOne} et {approverTwo}.", // Text to inform user of both approvers for their application
  "submit-own-request.introduction.single": "Vous pouvez demander l’approbation d’un perfectionnement professionnel à l’extérieur de SkillsWave.<linebreak></linebreak> Cette demande sera approuvée par : {approver}.", // Text to inform user of approver for their application
  "submit-own-request.loading": "Téléchargement en cours...",
  "submit-own-request.request-overview.date": "Date(s)", // label for date range in submit own request overview, ignore-untranslated
  "submit-own-request.request-overview.description": "Description", // label for description in submit own request overview, ignore-untranslated
  "submit-own-request.request-overview.title": "Titre", // label for title in submit own request overview
  "submit-own-request.request-overview.type": "Type", // label for course type in overview, ignore-untranslated
  "submit-own-request.request-overview.website": "URL du site Web :", // label for website url in submit own request overview
  "submit-own-request.select.title": "Ce que je veux apprendre",
  "submit-own-request.title": "Soumettre ma propre demande", // Submit my own request page title

  "suggest-panel.copyUrl.course.label": "Copier le lien vers le cours",
  "suggest-panel.copyUrl.program.label": "Copier le lien vers le programme",
  "suggest-panel.copyUrl.success.course": "Lien du cours copié 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.copyUrl.success.program": "Lien du programme copié 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.form.button.course": "Suggérer un cours", // Label for button if activity is a course
  "suggest-panel.form.button.program": "Suggérer un programme", // Label for button if activity is a program
  "suggest-panel.form.date.nextSession": "Prochaine séance", // Label if the course/program doesn't have a start date
  "suggest-panel.form.date.starts": "Commencement", // Label if the course/program has a start date
  "suggest-panel.form.error.invalid-email": "Veuillez saisir une adresse courriel valide",
  "suggest-panel.form.error.required": "Saisissez au moins une adresse courriel valide",
  "suggest-panel.form.label.email": "Ajouter adresse courriel", // Label for email form field
  "suggest-panel.form.label.textarea": "Message personnel", // Label for personal message form field
  "suggest-panel.form.message.found.course": "J’ai trouvé un excellent cours. Jetez-y un coup d’œil!", // Populated string for personal message if activity is a course on request page, activity page or default
  "suggest-panel.form.message.found.program": "J’ai trouvé un excellent programme. Jetez-y un coup d’œil!", // Populated string for personal message if activity is a program on request page, activity page or default
  "suggest-panel.form.message.taking.course": "Je suis ce cours qui pourrait vous intéresser. Jetez-y un coup d’œil!", // Populated string for personal message if activity is a course on all set page
  "suggest-panel.form.message.taking.program": "Je suis ce programme qui pourrait vous intéresser. Jetez-y un coup d’œil!", // Populated string for personal message if activity is a program on all set page
  "suggest-panel.non-request-page.description.course": "Suggérez ce cours à vos collègues", // Suggest panel title if activity is a course on a non-request-page
  "suggest-panel.non-request-page.description.program": "Suggérez ce programme à vos collègues", // Suggest panel title if activity is a program on a non-request-page
  "suggest-panel.request-page.description.course": "Ce cours convient-il à votre équipe? Suggérez-le à d’autres personnes.", // Suggest panel title if activity is a course on request page
  "suggest-panel.request-page.description.program": "Ce programme convient-il à votre équipe? Suggérez-le à d’autres personnes.", // Suggest panel title if activity is a program on request page
  "suggest-panel.sent.popup.header": "Suggestion envoyée!", // the text to slide up when the suggestion emails are triggered
  "suggest-panel.sent.popup.subText.course": "Merci d’avoir suggéré ce cours", // the text to slide up when the suggestion emails are triggered on a course activity
  "suggest-panel.sent.popup.subText.program": "Merci d’avoir suggéré ce programme", // the text to slide up when the suggestion emails are triggered on a program activity
  "suggest-panel.title.course": "Suggérer ce cours", // Suggest panel title if activity is a course
  "suggest-panel.title.program": "Suggérer ce programme", // Suggest panel title if activity is a program

  "take-action-today-carousel.continueYourProgramTab": "Poursuivez votre programme", // The tab for the continue your program section of the take action today carousel
  "take-action-today-carousel.heading": "Agissez dès aujourd'hui", // The header for the take action today carousel
  "take-action-today-carousel.myListTab": "Ma liste", // The tab for the my list section of the take action today carousel
  "take-action-today-carousel.onDemandTab": "À la demande", // The tab for the upcoming section of the take action today carousel
  "take-action-today-carousel.startingSoonTab": "Commence bientôt", // The tab for the starting soon section of the take action today carousel

  "tenant.approvalModel.centralized": "Centralisé", // The display name for the centralized approval model
  "tenant.approvalModel.distributed": "Distribué", // The display name for the distributed approval model
  "tenant.approvalModel.hybrid": "Hybride", // The display name for the hybrid approval model
  "tenant.entitlement.sponsor": "Commanditaire", // Sponsor entitlement for employer tenants
  "tenant.entitlement.watcher": "Observateur", // Watcher entitlement for employer tenants

  "tenantSSO.loginType.magic-link": "Lien magique", // The display name for the magic link login type
  "tenantSSO.loginType.saml": "SAML", // The display name for the magic link login type, ignore-untranslated

  "thankyou-page.documentTitle": "Merci - SkillsWave", // Document title when viewing the thank you page. Do not translate "SkillsWave".

  "thankyou-signup.body": "Nous avons envoyé un courriel de vérification à l’adresse {userEmail}.<linebreak></linebreak> Veuillez cliquer sur le lien qu’il contient pour activer votre compte.",
  "thankyou-signup.emailNotRecieved": "Vous n'avez pas reçu de courriel? <resendLink>Renvoyer le lien</resendLink> ou <contactLink>communiquer avec nous</contactLink>",
  "thankyou-signup.title": "Merci pour votre inscription!",
  "thankyou-signup-email-resent": "Un courriel de vérification a été envoyé à l’adresse {email}.",

  "transcript.international": "International", // ignore-untranslated

  "user-summary.approvedAmount.cancelled": "Annulé", // The value to display for the approved amount when the request is cancelled
  "user-summary.approvedAmount.declined": "Refusée", // The value to display for the approved amount when the request has been declined
  "user-summary.approvedAmount.pending": "En attente", // The value to display for the approved amount when the request is still pending
  "user-summary.approvedAmount.withdrawn": "A abandonné", // The value to display for the approved amount when the learner has withdrawn from the class
  "user-summary.currentFiscalYear.header": "Exercice financier en cours", // The header for the user summary card
  "user-summary.currentFiscalYear.noRequests": "{userDisplayName} n’a fait aucune autre demande durant l’exercice financier en cours", // The header for the user summary card
  "user-summary.header": "Autres demandes", // The header for the user summary card
  "user-summary.noRequests": "{userDisplayName} n’a fait aucune autre demande", // The header for the user summary card
  "user-summary.previousFiscalYears.header": "Exercices financiers antérieurs", // The header for the user summary card
  "user-summary.previousFiscalYears.noRequests": "{userDisplayName} n’a fait aucune autre demande durant les exercices financiers antérieurs", // The header for the user summary card

  "users-table.cancel-invite.menu": "Annuler l'invitation et supprimer l'utilisateur",
  "users-table.cancel-invite.success": "Invitation annulée avec succès",
  "users-table.column.actions": "Actions", // ignore-untranslated
  "users-table.invited": "Invité",
  "users-table.reinvite.menu": "Renvoyer l'invitation",
  "users-table.reinvite.success": "Invitation renvoyée avec succès",
  "users-table.userActiveSwitchLabel": "Utilisateur Actif",

  "view-activities.documentTitle": "Programmes – SkillsWave", // Browser page title for the activities page. Do not translate "SkillsWave".
  "view-activities.documentTitle.search": "{searchCriteria} – Programmes – SkillsWave", // Browser page title for the activities page when you search. Do not translate "SkillsWave".
  "view-activities.search.placeholder": "Trouver une compétence, un cours ou un programme", // Placeholder text for activities search bar

  "view-activity.activityMetadata.footer.nextSession": "Prochaine séance", // The request approval button label
  "view-activity.activityMetadata.footer.notRequestableAlert": "Vous devez vous inscrire au programme pour suivre ce cours", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.notRequestableComingSoonAlert": "L’inscription s’ouvrira bientôt. Ajoutez ce {activityType} à votre liste et nous vous enverrons un courriel lorsque l’inscription s’ouvrira.", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.requestApproval": "Commencer à remplir la demande", // The request approval button label
  "view-activity.activityMetadata.footer.startDateType": "Type de date de début", // Label Start date type dropdown
  "view-activity.activityMetadata.footer.unscheduledAlert": "Ce cours sera disponible lorsque la prochaine séance sera planifiée", // Informs the user that the program/course does not have a scheduled start date yet
  "view-activity.activityOverview.activityLink": "Pour connaître les dates de début, les dates limites d’inscription, la politique de remboursement et d’autres détails, consultez <link>{activityTitle}</link> chez {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-activity.activityOverview.header": "Vue d’ensemble", // Overview section header
  "view-activity.activityOverview.skillsDescription": "Compétences associées à cette activité", // description for element that contains list of skills
  "view-activity.addToMyList": "Ajouter à ma liste", // Label for Add to My List button icon
  "view-activity.admissionRequirements.header": "Conditions d’admission", // title for admission requirements section
  "view-activity.backToTop": "Retourner en haut", // Anchor displayed at the end of seeing all avaliable programs.
  "view-activity.backToTop.ariaLabel": "Retourner en haut. Vous serez redirigé vers le haut de la page en cours.",
  "view-activity.documentTitle.afterLoading": "{activityTitle} – SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page after activity data has loaded. Do not translate "SkillsWave". ignore-untranslated
  "view-activity.documentTitle.whileLoading": "Chargement... – SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page but before the activity title is known. Do not translate "SkillsWave".
  "view-activity.editSkills.button": "Modifier les compétences", // button text allowing users to edit skills on an activity
  "view-activity.editSkills.save": "Les modifications apportées aux compétences ont été enregistrées", // message that shows when skill shave been saved on the activity
  "view-activity.editStartDate.button": "Modifier la date de début", // button text allowing users to edit start date on an activity
  "view-activity.editStartDate.date.list": "Ajouter des dates pour la séance :",
  "view-activity.inactive": "Inactif", // Status indicator for inactive programs
  "view-activity.myListError": "La mise à jour de la liste n’a pu être faite", // Error when attempting to add or remove activity to My List
  "view-activity.noResults.prompt.1": "Aucun résultat pour :", // Part 1 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.noResults.prompt.2": "{searchCriteria} et les filtres actuellement sélectionnés", // Part 2 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.removeFromMyList": "Supprimer de ma liste", // Label for Remove from My List button icon
  "view-activity.requirements.header": "Exigences", // Requirements section header
  "view-activity.startDate": "Choisir la date", // The course/programs start date input label
  "view-activity.startDateType.anytime": "En tout temps", // The course/program starts anytime
  "view-activity.startDateType.comingSoon": "Disponible bientôt", // The course/program start date is coming soon
  "view-activity.startDateType.date": "Date", // The course/program starts on a specific date, ignore-untranslated
  "view-activity.startDateType.unknown": "Inconnu", // The course/program start date is unknown
  "view-activity.title": "Quelles sont les prochaines compétences que vous allez acquérir?", // Title for activities view page
  "view-activity.toast.added": "Ajouté à ma liste", // Toast text when adding activity to My List
  "view-activity.toast.removed": "Supprimé de ma liste", // Toast text when removing activity from My List

  "view-activity-category.career-skills.company-intro": "Compétences correspondant à celles généralement requises pour mener cette carrière chez {companyName}", // Skill intro text when viewing all activities for a career while viewing a company tenant
  "view-activity-category.career-skills.non-company-intro": "Compétences requises pour réussir dans cette carrière", // Skill intro text when viewing all activities for a career while viewing a NON-company tenant, e.g. an association
  "view-activity-category.courses": "Cours", // Activities of the type course
  "view-activity-category.documentTitle.afterLoading": "{categoryName} – Programmes – SkillsWave", // Title of web page when viewing a specific category's activities, while it is loading. Do not translate "SkillsWave".
  "view-activity-category.documentTitle.whileLoading": "Chargement... – Programmes – SkillsWave", // Title of web page when viewing a specific category's activities, after it has loaded. Do not translate "SkillsWave".
  "view-activity-category.programs": "Programmes", // Activities of the type program

  "view-admin.activities": "Activités", // Label for the Activities tab.
  "view-admin.addButton.employer": "Ajouter un employeur",
  "view-admin.addButton.provider": "Ajouter un fournisseur",
  "view-admin.admin.heading1": "Administrateur",
  "view-admin.documentTitle": "Administrateur – SkillsWave", // Do not translate "SkillsWave".
  "view-admin.documentTitle.auditPermissions": "Permissions - audit", // title for permissions audit page. ignore-untranslated
  "view-admin.employer.heading1": "Employeurs", // Heading for the employers list.
  "view-admin.features": "Fonctions", // Label for the Features tab.
  "view-admin.fetchSkills": "Rechercher des compétences", // Label for the button that fetches skills.
  "view-admin.filterSkills.label": "Filtrer les compétences", // Label for the filter skills textbox.
  "view-admin.filterSkills.table.activityId": "ID de l’activité", // Heading for the activity ID column.
  "view-admin.filterSkills.table.activityName": "Nom de l’activité", // Heading for the activity name column.
  "view-admin.filterSkills.table.activityType": "Type d’activité", // Heading for the activity type column.
  "view-admin.filterSkills.table.skillId": "ID de compétence", // Heading for the skill ID column.
  "view-admin.filterSkills.table.skillName": "Nom de la compétence", // Heading for the skill name column.
  "view-admin.loading": "Chargement admin", // Placeholder loading admin page.
  "view-admin.provider.heading1": "Fournisseurs", // Heading for the providers list.
  "view-admin.tenantTypeSelect.label": "Type de locataire", // Label for tenant type selection dropdown.
  "view-admin.tenantTypeSelect.none": "-- Sélectionner le type de locataire --", // Default option for tenant type selection dropdown.
  "view-admin.tenants": "Locataires", // Label for the Tenants tab.

  "view-application.activity-details.heading.details": "{activityType} détails", // Heading for activity info
  "view-application.activity-details.submit-own-request.message": "Cette occasion d’apprentissage ne fait pas partie du catalogue SkillsWave", // message for courses outside of wave catalog
  "view-application.activityMetadata.footer.nextSession": "Prochaine séance", // The request approval button label
  "view-application.activityOverview.activityLink": "Pour connaître les dates de début, les dates limites d’inscription, la politique de remboursement et d’autres détails, consultez <link>{activityTitle}</link> chez {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-application.activityOverview.header": "Vue d’ensemble", // Overview section header
  "view-application.admissionRequirements.description": "Ce programme a des exigences d’admission et des prérequis.",
  "view-application.backToResults.link.label": "Retour aux résultats", // Label for back to results link
  "view-application.documentTitle": "Afficher la demande – SkillsWave", // Heading for activity info. Do not translate "SkillsWave".
  "view-application.paymentHandledByProvider.registrationDialog.cancel": "Annuler",
  "view-application.paymentHandledByProvider.registrationDialog.confirm": "Continuer",
  "view-application.paymentHandledByProvider.registrationDialog.content": "Vous êtes redirigé afin de terminer votre processus d’inscription auprès de <b>{providerName}.</b>",
  "view-application.paymentHandledByProvider.registrationDialog.title": "Vous quittez {tenantName}",
  "view-application.programCompletionPanel.comments": "Commentaires", // Title for the comment box in the program completion panel
  "view-application.programCompletionPanel.completionFormCompleteOption": "{learnerName} a terminé l'intégralité du programme.", // First radio option in the completion form in the program completion panel
  "view-application.programCompletionPanel.completionFormIncompleteOption": "Le programme n'a pas été complété", // Second radio option in the completion form in the program completion panel
  "view-application.programCompletionPanel.completionFormTitle": "Veuillez vérifier ci-dessous l'achèvement du programme.", // Title for the completion form in the program completion panel
  "view-application.programCompletionPanel.completionSummaryEnd": " dans le programme ''<link>{programName}</link>''.", // End of completion summary for the program completion panel when opened. <link> should NOT be translated
  "view-application.programCompletionPanel.completionSummaryMiddle": "{coursesCompleted} sur {coursesRequired} cours", // Middle of completion summary for the program completion panel when opened
  "view-application.programCompletionPanel.completionSummaryStart": "{learnerName} a terminé ", // Start of completion summary for the program completion panel when opened
  "view-application.programCompletionPanel.header": "Achèvement du Programme", // Header for the program completion panel
  "view-application.programCompletionPanel.saveButton": "Enregistrer", // Save button for the program completion panel
  "view-application.programCompletionPanel.summary": "{coursesCompleted} sur {coursesRequired} cours terminés", // Summary for the program completion panel
  "view-application.programCompletionPanel.summaryTooltip": "{coreCoursesCompleted} sur {coreCoursesRequired} cours obligatoires et {electiveCoursesCompleted} sur {electiveCoursesRequired} cours facultatifs terminés", // Tooltip for the program completion panel summary
  "view-application.programCompletionPanel.summaryTooltip.coreOnly": "{coreCoursesCompleted}/{coreCoursesRequired} cours obligatoires terminés", // Tooltip for the program completion panel summary if there are only core courses
  "view-application.programCompletionPanel.summaryTooltip.electivesOnly": "{electiveCoursesCompleted}/{electiveCoursesRequired} cours facultatifs terminés", // Tooltip for the program completion panel summary if there are only elective courses
  "view-application.programCompletionPanel.toastCommentError": "Un commentaire est requis lorsque le programme est marqué comme incomplet", // Toast message for validation error in program completion panel comment
  "view-application.programCompletionPanel.toastSuccess": "Achèvement du programme enregistré", // Toast message for successful save of the program completion panel
  "view-application.programCompletionPanel.verificationSummary": "L'achèvement du programme a été vérifié", // Summary for the program completion panel before verification
  "view-application.programCompletionPanel.verificationSummary.complete": "Vous avez confirmé que {learnerName} a terminé tous les cours dans ''<link>{programName}</link>''.", // Toast message for verification of unsuccessful program completion in panel
  "view-application.programCompletionPanel.verificationSummary.incomplete": "Vous avez confirmé que {learnerName} n'a pas terminé tous les cours dans ''<link>{programName}</link>''.", // Toast message for verification of successful program completion in panel
  "view-application.registrationDetails.cancelled": "{activityType, select, course {Ce cours a été annulé} program {Ce programme a été annulé} other {Cette activité a été annulée}}", // toast banner to appear if the course has been cancelled
  "view-application.registrationDetails.connectionbanner": "Courriel de connexion au compte introuvable pour {displayName}.", // error banner when account connection record doesn't exist for user
  "view-application.registrationDetails.copied": "Courriel copié dans votre presse-papiers", // toast banner to confirm that the connection email has been copied to the users clipboard
  "view-application.registrationDetails.copy": "Copier le courriel", // text for copy button in registation details
  "view-application.registrationDetails.failed": "{displayName} a échoué {activityType, select, course {le cours} program {le programme} other {l’activité}}", // banner to appear when user has academically failed the course
  "view-application.registrationDetails.header": "S’inscrire et mettre à jour", // Header for the stripe provider request view
  "view-application.registrationDetails.helpMessage": "Utilisez le courriel ci-dessous pour trouver {displayName} dans votre système d’inscription. Si vous ne trouvez pas cet apprenant, communiquez avec le soutien de SkillsWave.", // body text to direct providers to action on the copy email below. Do not translate "SkillsWave".
  "view-application.registrationDetails.nextAction": "Une fois qu’il est inscrit, mettez à jour les renseignements d’inscription et d’achèvement de cet apprenant", // body text to direct providers to fill out registration form details
  "view-application.registrationDetails.withdrawn": "{displayName} s’est retiré(e) {activityType, select, course {du cours} program {du programme} other {de l’activité}}", // toast banner to appear when user has withdrawn from course
  "view-application.requestInformation.header": "Passer en revue les renseignements de la demande", // Header for the stripe provider request view
  "view-application.requirements.header": "Exigences", // Requirements section header

  "view-application-confirmation.backButton": "Retour à la demande", // Button text for navigating back to request page
  "view-application-confirmation.info": "{institution} prendra généralement {registrationTime} jours pour vous inscrire. Vous recevrez les détails par courriel à <emailSpan>{email}</emailSpan>.", // Info on request confirmation page
  "view-application-confirmation.info.admissionBased": "{institution} vous enverra un courriel à l’adresse : <emailSpan>{email}</emailSpan> au sujet de l’admission et des détails de l’inscription, dans les {registrationTime} jours ouvrables.", // Info on request confirmation page for admission based course
  "view-application-confirmation.subtitle": "{institution} vous inscrira à {activityTitle} et vous enverra un courriel.", // Request confirmation page subtitle
  "view-application-confirmation.subtitle.admissionBased": "{institution} vous enverra un courriel", // Request confirmation page subtitle for admission based course
  "view-application-confirmation.title": "Vous avez terminé!<linebreak> </linebreak> Maintenant, attendez d’être inscrit.", // Request confirmation page title for desktop
  "view-application-confirmation.title.mobile": "Vous avez terminé! Maintenant, attendez d’être inscrit.", // Request confirmation page title for mobile
  "view-application-confirmation.wait.info": "Si votre {activityType} ne démarre pas pendant un certain temps, la fenêtre d’inscription pourrait ne pas être ouverte pour le moment. Ne vous inquiétez pas, {institution} vous inscrira dans les {registrationTime} jours suivant l’ouverture de l’inscription.", // Info about registration window on request confirmation page
  "view-application-confirmation.wait.info.admissionBased": "Si votre {activityType} ne démarre pas pendant un certain temps, la fenêtre d’inscription pourrait ne pas être ouverte pour le moment. Ne vous inquiétez pas, {institution} vous contactera dans les {registrationTime} jours suivants l’ouverture de l’inscription.", // Info about registration window on request confirmation page for admission based course

  "view-applications.documentTitle": "Demandes – SkillsWave", // Web page title for requests page. Do not translate "SkillsWave".
  "view-applications.title": "Demandes", // Requests page title

  "view-bookmarks.noBookmarks": "Cliquez sur l’icône de signet à partir de n’importe quelle page Détails de programme pour ajouter ou supprimer des éléments de cette liste.", // Message displayed when there are no bookmarked activities
  "view-bookmarks.title": "Signets", // Bookmarks page title

  "view-client-onboarding.documentTitle": "Intégration",
  "view-client-onboarding.welcomeMessage": "Vous commencez ici au début, répondez à quelques questions sur votre entreprise et votre équipe, et nous organiserons notre catalogue pour vous montrer des cours qui vous aideront à améliorer les compétences de votre équipe. Nous suivrons vos cours pour vous et vous faciliterons la tâche pour voir le développement de votre équipe.",

  "view-error-loading.documentTitle": "Erreur – SkillsWave", // Document title for when there is an error loading wave. Do not translate "SkillsWave".

  "view-home.carousel.next": "Logos des fournisseurs suivants",
  "view-home.carousel.prev": "Logos des fournisseurs précédents",

  "view-how-it-works.documentTitle": "Fonctionnement",

  "view-landing-page.carousel.error.action": "Rafraîchir",
  "view-landing-page.carousel.error.description": "Une erreur est survenue lors du chargement des recommandations. Rafraîchissez votre navigateur pour réessayer.",
  "view-landing-page.carousel.interestedCareer.heading": "Recommandé pour votre objectif de carrière:",
  "view-landing-page.carousel.interestedSkillSet.heading": "Recommandé pour vos objectifs:",
  "view-landing-page.carousel.interestedSkillSet.menuLabel": "Compétences",
  "view-landing-page.carousel.tabTitle.bestResults": "Meilleurs résultats",
  "view-landing-page.carousel.tabTitle.courses": "Cours",
  "view-landing-page.carousel.tabTitle.degrees": "Diplômes",
  "view-landing-page.carousel.tabTitle.microlearning": "Microapprentissage",
  "view-landing-page.carousel.tabTitle.shortCredentials": "Certificats courts",
  "view-landing-page.carousel.userRole.heading": "Recommandé pour votre rôle:",
  "view-landing-page.documentTitle": "Recommandations", // ignore-untranslated
  "view-landing-page.profile.credit.corporatePolicy": "{remainingBalance} sur {totalBalance} disponible de la part de {employerTenant}",
  "view-landing-page.profile.credit.zeroInvestment": "{amount} investi en vous jusqu’à maintenant; faites votre demande dès aujourd’hui pour commencer votre apprentissage",
  "view-landing-page.profile.description.interests": "Vous êtes intéressé par:", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.interestsPlaceholder": "Choisissez 3 compétences ou plus.", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.jobTitle": "Votre rôle est: {jobTitle}", // For the profile card, is followed by view-landing-page.profile.description.interests if there are any interests
  "view-landing-page.profile.editButton": "Modifier les objectifs",
  "view-landing-page.profile.header": "Bienvenue, {firstName}!",
  "view-landing-page.profile.recommendSettingGoals": "Définissez vos objectifs pour obtenir de meilleures recommandations",
  "view-landing-page.profile.showLessButton": "afficher moins",
  "view-landing-page.profile.showMoreButton": "afficher plus",
  "view-landing-page.profile.thisFiscalYear": "pour cet exercice financier",
  "view-landing-page.profile.usedCredit": "{amount} investi en vous par {employerTenant}",

  "view-login.documentTitle": "Connexion - SkillsWave", // Document title for the login page. Do not translate "SkillsWave".

  "view-not-found.documentTitle": "Page introuvable – SkillsWave", // Document title for when user navigates to an invalid page and it's not found. Do not translate "SkillsWave".

  "view-onboarding.area-of-interest.description": "Vous êtes un(e) {jobTitle} intéressé(e) par:",
  "view-onboarding.documentTitle": "Intégration", // title for document of onboarding page
  "view-onboarding.generatingResults.subtext.AddingMagic": "Ajout de magie", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.Enjoy": "Profitez bien!", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.FingingCourses": "Recherche de cours auprès des meilleurs fournisseurs", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.GatherSkills": "Rassemblement de vos compétences et objectifs de carrière", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.text": "Recommandation de cours pour vous", // text to display beneath the animation
  "view-onboarding.getStarted.closestMatchLabel": "Votre rôle correspond à ce {industryTitle}", // Label for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.closestMatchLabel.tooltip.header": "titre de l'industrie:", // Tooltip header for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.closestMatchTooltip": "Nous avons associé votre rôle à ce titre d'industrie pour améliorer encore vos recommandations. Si cela ne semble pas correct, vous pouvez l'éditer", // Tooltip for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.currentRoleLabel": "Entrez votre rôle actuel", // Offscreen label for input where user inputs their job title
  "view-onboarding.getStarted.currentRolePlaceholder": "Votre rôle actuel", // Placeholder text for input where user inputs their job title
  "view-onboarding.getStarted.editSOC.button": "Modifier", // Button text for editing job title match with third party platform job titles
  "view-onboarding.getStarted.existingUserAlert": "Avant de continuer avec votre compte, définissez vos objectifs pour améliorer les recommandations d'apprentissage", // alert message for exisitng users
  "view-onboarding.getStarted.introduction.subheader": "Salut {name}, votre rôle est:", // Introduction text saying hello to user and introducing their job title/role in our system
  "view-onboarding.getStarted.marquee.header": "Formez-vous maintenant, remerciez-vous plus tard", // Marquee header for onboarding process to upskilling platform
  "view-onboarding.getStarted.marquee.subheader": "Trouvez des opportunités d'éducation de qualité pour vous aider à atteindre votre plein potentiel", // Subtext for marquee header that explains the websites purpose
  "view-onboarding.getStarted.skillpopper.label": "Compétences clés pour votre rôle:", // Supplemental text explaining that the system is currently loading skills related to the users selected profession
  "view-onboarding.getStarted.submit.button": "Définissez Vos Objectifs", // Button text for submitting page and moving forward with onboarding process

  "view-report-dashboard.documentTitle": "Analyses – SkillsWave", // Document title for the Analytics page. Do not translate "SkillsWave".
  "view-report-dashboard.employerSelect.all": "TOUS LES EMPLOYEURS", // All employers option for employer selection dropdown
  "view-report-dashboard.employerSelect.label": "Sélectionner un employeur", // Label for employer selection dropdown
  "view-report-dashboard.employerSelect.none": "-- SÉLECTIONNEZ UN EMPLOYEUR --", // Default option for employer selection dropdown
  "view-report-dashboard.exportButton": "Statuts de finalisation des exportations", // Reports dashboard export button
  "view-report-dashboard.filterBy": "Filtrer par", // Label to proceed filter bar interactions
  "view-report-dashboard.filterRemove": "Cliquer pour supprimer la valeur", // Button to remove filter options
  "view-report-dashboard.mobile.otherFilters": "Autres filtres", // The other filters button label
  "view-report-dashboard.providerSelect.all": "TOUS LES FOURNISSEURS", // All providers option for provider selection dropdown
  "view-report-dashboard.providerSelect.label": "Sélectionner un fournisseur", // Label for provider selection dropdown
  "view-report-dashboard.providerSelect.none": "-- SÉLECTIONNEZ UN FOURNISSEUR --", // Default option for provider selection dropdown
  "view-report-dashboard.timeframeFilter.appliedFilter": "Filtres appliqués", // Label for applied filter
  "view-report-dashboard.timeframeFilter.applyButton": "Appliquer", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.clearButton": "Effacer", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.custom": "Personnalisés", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.endDate": "Date de fin", // Timeframe end date input label
  "view-report-dashboard.timeframeFilter.from": "à", // Time frame 'From' word to use it like part of 'From dd\mm\yyyy ...'
  "view-report-dashboard.timeframeFilter.invalidDateRange": "La date du début doit être antérieure à la date de fin", // Invalid date range message
  "view-report-dashboard.timeframeFilter.last": "Dernier", // Time frame 'Last' word to use it like part of 'Last 30 days'
  "view-report-dashboard.timeframeFilter.lastMonth": "30 jours", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastThreeMonths": "90 jours", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastWeek": "Les 7 derniers jours", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastYear": "À ce jour", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.requiredFieldMessage": "Champ obligatoire", // Required field message
  "view-report-dashboard.timeframeFilter.sinceFiscalYear": "Exercice financier à ce jour", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.startDate": "Date de début", // Timeframe start date input label
  "view-report-dashboard.timeframeFilter.timeframeFilterButton": "Durée", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.to": "à", // Time frame 'From' word to use it like part of '... to dd\mm\yyyy'
  "view-report-dashboard.title": "Analytique", // Reports dashboard page title

  "view-settings.catalog-preferences": "Préférences du catalogue",
  "view-settings.details": "Détails de l'entreprise",
  "view-settings.documentTitle": "Paramètres",
  "view-settings.finance": "Finance", // ignore-untranslated
  "view-settings.user-management": "Gestion des utilisateurs",
};
