// This component is used in the NovaSidePanel component to represent an item in the side panel. They don't render on their own so
// Axe/unit tests are done at the NovaSidePanel level.
// eslint-disable-next-line nova/component-has-axe-test,nova/component-has-unit-test,nova/component-has-vdiff-test, nova/component-has-demo
import { css, html, LitElement, nothing } from 'lit';
import { NovaPermissionMixin } from '../../../mixins/nova-permission-mixin/nova-permission-mixin.js';

export class NovaSidePanelItem extends NovaPermissionMixin(LitElement) {
  static styles = css`
    :host {
      display: block;
    }
    .hidden {
      display: none;
    }
`;

  static properties = {
    panel: { type: String },
    selected: { type: Boolean },
    title: { type: String },
    label: { type: String },
    icon: { type: String },
  };

  constructor() {
    super();
    this.selected = false;
    this.title = '';
    this.panel = '';
    this.label = '';
    this.icon = '';
  }

  allowedRender() {
    if (!this.selected) return nothing;
    return html`
      <slot></slot>
    `;
  }
}
customElements.define('nova-side-panel-item', NovaSidePanelItem);
