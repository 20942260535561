import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/button/button.js';

import { css, html, LitElement, nothing } from 'lit';

import { bodySmallStyles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';

import '../../../main/components/general/suggest-activity/email-list-input/email-list-input.js';

class SettingsInviteUser extends LocalizeNova(RequesterMixin(SkeletonMixin(NovaNavMixin(LitElement)))) {
  static get properties() {
    return {
      emailList: { type: Array },
      results: { type: Object },
    };
  }

  static get styles() {
    return [
      heading2Styles,
      inputLabelStyles,
      bodySmallStyles,
      css`
        .email-list {
          display: block;
          width: 100%;

        }
        .email-description {
          font-weight: bold;
        }
        .desc-2 {
          padding-bottom: 200px;
        }

    `];
  }

  constructor() {
    super();
    this.emailList = [];
    this.results = {
      successful: [],
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

  }

  get buttonText() {
    if (this.results.successful.length === 0) {
      return 'Add users';
    }
    return `Add ${this.results.successful.length} users`;
  }

  async _emailListChanged(e) {
    this.emailList = e.detail.emails;
    this.results = await this.client.inviteUsers(this.session.tenant.id, this.emailList, false);
  }

  async submitUsers() {
    try {
      await this.client.inviteUsers(this.session.tenant.id, this.emailList, true);
      this.navigate('/settings/user-management');
      this.session.toast({ type: 'success', message: this.localize('invite-user.success', { numUsers: this.results.length }) });
    } catch (err) {
      this.session.toast({ type: 'error', message: this.localize('invite-user.error') });
    }

  }

  cancel() {
    this.navigate('/settings/user-management');
  }

  render() {
    return html`
    <div>
      <h2 class="d2l-heading-2">Add users</h2>
      <label class="d2l-input-label-required" for="email-list">Email addresses</label>
      <p class="d2l-body-small email-description">Separate emails by a comma</p>
      <email-list-input
        hide-label
        label="Email addresses"
        class="email-list"
        .emails=${this.emailList}
        @emails-changed="${this._emailListChanged}">
      </email-list-input>

      <!-- Render the results in monospaced font -->
      ${this.results
    ? html`
          <pre style="background-color: #f9f9f9; padding: 10px; border: 1px solid #ddd; border-radius: 4px; font-family: monospace; white-space: pre-wrap;">
            ${JSON.stringify(this.results, null, 2)}
          </pre>
        `
    : nothing
}

      <p class="d2l-body-small desc-2">All users added this way will have access to SkillsWave as a “user/learner”. You can add roles from users after they are invited into the system.</p>
      <d2l-button ?disabled="${this.results.successful.length === 0}" @click="${this.submitUsers}">${this.buttonText}</d2l-button>
      <d2l-button @click="${this.cancel}">Cancel</d2l-button>
    </div>
  `;
  }

}

window.customElements.define('settings-invite-user', SettingsInviteUser);

