import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { isUuid } from '../../../../shared/helpers/util.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../shared/mixins/nova-nav/nova-nav.js';

import '../../../admin/views/edit-tenant/admin-permissions/manage-user-permissions/manage-user-permissions.js';

class SettingsEditUser extends LocalizeNova(RequesterMixin(SkeletonMixin(NovaNavMixin(LitElement)))) {
  static get properties() {
    return {
      guid: { type: String },
      user: { type: Object },
      allRoles: { type: Array },
    };
  }

  static get styles() {
    return css`
      /* Add styles here */
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async updated(changedProperties) {
    if (changedProperties.has('guid') && isUuid(this.guid)) {
      this.user = await this.client.getUserById(this.guid);
    }
  }

  async _handleStateChange() {
    this.navigate('/settings/user-management');
  }

  render() {
    return html`
      <manage-user-permissions @nova-admin-state-change="${this._handleStateChange}" .user=${this.user} .allRoles=${this.allRoles}></manage-user-permissions>
    `;
  }
}

window.customElements.define('settings-edit-user', SettingsEditUser);

